import Navbar from "../../Navbar/NavbarComponent";
import { LABELS } from "../../../shared/constants";
import { useDashboardCountsQuery } from "../../../Features/dashboard/dashboard-slice";
import { useEffect, useState } from "react";
import { DASHBOARD_STATISTICS } from "../../../shared/constants/resource";

const Dashboard = (props: any) => {
  const {} = props;

  const {
    data,
    isSuccess,
    error,
    refetch: dashboardCountsRefetch,
    isFetching: dashboardCountsFetching,
  } = useDashboardCountsQuery(
    {},
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const [count, setCount]: any = useState();

  useEffect(() => {
    if (!dashboardCountsFetching && isSuccess) {
      setCount(data.item);
    }
  }, [data, isSuccess, dashboardCountsFetching]);

  const dashboardCountBlock = (title: any, value: any, icon: any) => {
    return (
      <div className="col-md-4">
        <div className="dash-card mb-3">
          <p className="title text-capitalize">{title}</p>
          <p className="count mb-0">{value}</p>
          <i className={icon}></i>
        </div>
      </div>
    );
  };

  return (
    <div className="full-height">
      <Navbar title={LABELS.DASHBOARD_TEXT} />
      {/* <Loader /> */}
      <main className="main-content">
        <div className="container-fluid pt-5">
          <div className="row">
            {DASHBOARD_STATISTICS.map((ele: any) => {
              const value = count !== undefined ? count[ele.id] : "-";
              return dashboardCountBlock(ele.title, value, ele.icon);
            })}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
