import Navbar from "../../Navbar/NavbarComponent/Navbar";
import { useEffect, useMemo, useRef, useState } from "react";
import Table from "../../../shared/components/Table";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  SEARCH_DATA,
  toastError,
  toastSuccess,
  TOAST_MESSAGE,
} from "../../../shared/constants";
import CustomModal from "../../../shared/components/CustomModal";
import VendorDetail from "./VendorDetail";
import Search from "../../../shared/components/Search";
import {
  useVendorGetQuery,
  useVendorImportMutation,
  useVendorListQuery,
} from "../../../Features/vendor/vendor-slice";
import { skipToken } from "@reduxjs/toolkit/query";
import SimpleButton from "../../../shared/components/Button/Button";
import { CSVLink } from "react-csv";
import authProvider from "../../../shared/config/authProvider";
import { useFilter } from "../../../shared/utils/hooks/useFilter";
import Filter from "../../Common/Filter/Filter";

const Vendor = (props: any) => {
  const {} = props;

  const [search, setSearch] = useState(false);
  const [sort, setSort] = useState(SEARCH_DATA);
  const [vendorList, setVendorList] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [vendorId, setVendorId]: any = useState();
  const [count, setCount] = useState();
  const [vendorDetailData, setVendorDetailData] = useState();
  const iconInput = useRef<any>(null);
  const [handleFilter] = useFilter(sort, setSort);
  const [showFilter, setShowFilter] = useState(false);
  const [filterCalled, setFilterCalled]: any = useState(false);
  const [filterSelectionError, setFilterSelectionError]: any = useState();
  const [filterValues, setFilterValues] = useState<any>({});

  const {
    data,
    isSuccess,
    refetch: vendorRefetch,
    isFetching: vendorListFetching,
  } = useVendorListQuery(sort, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: getVendorData,
    isLoading: getVendorLoading,
    isSuccess: getVendorSuccess,
  } = useVendorGetQuery(vendorId ? vendorId : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [
    vendorImport,
    { isSuccess: vendorImportSuccess, isLoading: vendorImportLoading },
  ] = useVendorImportMutation();

  useEffect(() => {
    vendorRefetch();
  }, [filterCalled]);

  useEffect(() => {
    if (isSuccess) {
      setVendorList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (vendorId !== undefined && getVendorSuccess) {
      setVendorDetailData(getVendorData.item);
    }
  }, [vendorId, getVendorData, getVendorSuccess]);

  const vendorImportFlow = (data: any) => {
    vendorRefetch();
    toastSuccess(data.message);
  };

  const handleCallFilter = () => {
    // if (Object.keys(sort.filter).length > 0) {
    handleFilter();
    setFilterCalled(!filterCalled);
    setSort({ ...sort, filter: filterValues, filterFlag: true, skip: 0 });
    setShowFilter(!showFilter);
    // } else {
    //   setFilterSelectionError("*Please select atlease one filter.");
    // }
  };

  const resetFilter = () => {
    setFilterValues({});
    setSort({ ...sort, filter: {}, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setSort({ ...sort, filter: {}, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(false);
  };

  const handleClose = () => {
    setShowDetail(false);
  };

  const handleSearch = () => {
    setSearch(!search);
    setSort({ ...sort, skip: 0 });
  };

  const handleSearchTextChange = (e: any) => {
    setSort({ ...sort, [e.target.name]: e.target.value, skip: 0 });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleRef = () => {
    iconInput.current.click();
  };

  const handleCsvImport = async (e: any) => {
    let formData = new FormData();
    formData.append("csv", e.target.files[0], e.target.files[0].name);
    const vendorImportResponse = await vendorImport(formData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (vendorImportResponse && vendorImportResponse?.status === true) {
      vendorImportFlow(vendorImportResponse);
    }
  };

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setFilterValues({
      ...filterValues,
      [e.target.name]: trimmedValue,
    });
  };

  const handleTextChange = (e: any) => {
    setFilterValues({
      ...filterValues,
      [e.target.name]: e.target.value,
    });
  };

  const csvData = [
    {
      JOBBER_ID: "",
      CODE: "2232",
      NAME: "VISHAL CREATION",
      ALIAS: "VC",
      CITY: "",
      CLASS_TYPE: "Customer",
      STATE: "",
      CLASS_NAME: "Customer",
      AR_LEDGER: "",
      IDENTITY_NO: "CUS-0622",
      BILLING_CITY: "Kolkata",
      BILLING_CONTACT_PERSON: "",
      BILLING_EMAIL_1: "",
      BILLING_OFFICE_PHONE_1: "",
      SHIPPING_CONTACT_PERSON: "",
      CONTACT_PERSON_NAME: "",
      ADDRESS: "",
      PIN: "",
      TAX_REGION: "",
      PHONE_NO: "",
      TRADE_GROUP: "",
      EMAIL: "",
      // PURCHASE_TERM: "",
      // PAN_NO: "",
      // GST_CATEGORY: "",
      // GST_IN_NO: "",
      // GST_STATE: "",
    },
    {
      JOBBER_ID: "",
      CODE: "2233",
      NAME: "VALI CREATIVE WORKS",
      ALIAS: "VCW",
      CITY: "",
      CLASS_TYPE: "Jobber",
      STATE: "",
      CLASS_NAME: "Jobber",
      AR_LEDGER: "",
      IDENTITY_NO: "JOB-062",
      BILLING_CITY: "MUMBAI",
      BILLING_CONTACT_PERSON: "",
      BILLING_EMAIL_1: "",
      BILLING_OFFICE_PHONE_1: "",
      SHIPPING_CONTACT_PERSON: "",
      CONTACT_PERSON_NAME: "",
      ADDRESS: "",
      PIN: "",
      TAX_REGION: "",
      PHONE_NO: "",
      TRADE_GROUP: "",
      EMAIL: "",
      // PURCHASE_TERM: "",
      // PAN_NO: "",
      // GST_CATEGORY: "",
      // GST_IN_NO: "",
      // GST_STATE: "",
    },
    {
      JOBBER_ID: "",
      CODE: "2230",
      NAME: "AAGAM (JODHPUR)",
      ALIAS: "AAGAM JODHPUR",
      CITY: "",
      CLASS_TYPE: "Customer",
      STATE: "",
      CLASS_NAME: "Customer",
      AR_LEDGER: "",
      IDENTITY_NO: "CUS-0616",
      BILLING_CITY: "JODHPUR",
      BILLING_CONTACT_PERSON: "LOKESH PATWA",
      BILLING_EMAIL_1: "aagamsaleraj@gmail.com",
      BILLING_OFFICE_PHONE_1: "",
      SHIPPING_CONTACT_PERSON: "",
      CONTACT_PERSON_NAME: "",
      ADDRESS: "",
      PIN: "",
      TAX_REGION: "",
      PHONE_NO: "",
      TRADE_GROUP: "",
      EMAIL: "",
      // PURCHASE_TERM: "",
      // PAN_NO: "",
      // GST_CATEGORY: "",
      // GST_IN_NO: "",
      // GST_STATE: "",
    },
    {
      JOBBER_ID: "",
      CODE: "2228",
      NAME: "YASH FASHION HOUSE (SUPLLIER)",
      ALIAS: "YASH FASHION HOUSE - SUP",
      CITY: "",
      CLASS_TYPE: "Supplier",
      STATE: "",
      CLASS_NAME: "Supplier",
      AR_LEDGER: "",
      IDENTITY_NO: "SUP-361",
      BILLING_CITY: "SURAT",
      BILLING_CONTACT_PERSON: "",
      BILLING_EMAIL_1: "info@yashfashionhouse.com",
      BILLING_OFFICE_PHONE_1: "",
      SHIPPING_CONTACT_PERSON: "",
      CONTACT_PERSON_NAME: "",
      ADDRESS: "",
      PIN: "",
      TAX_REGION: "",
      PHONE_NO: "",
      TRADE_GROUP: "",
      EMAIL: "",
      // PURCHASE_TERM: "",
      // PAN_NO: "",
      // GST_CATEGORY: "",
      // GST_IN_NO: "",
      // GST_STATE: "",
    },
  ];

  const handleVendorDetails = (e: any, row: any) => {
    setVendorId(row._id);
    setShowDetail(true);
  };

  const filterContent = (handleTextChange: any) => {
    return [
      {
        label: LABELS.CLASS_TYPE_TEXT,
        name: ACTION_LABEL.CLASS_TYPE,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.CITY_TEXT,
        name: ACTION_LABEL.CITY,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.STATE_TEXT,
        name: ACTION_LABEL.STATE,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
    ];
  };

  const vendorModalButton = [
    {
      text: BUTTON_LABEL.CANCEL,
      action: handleClose,
      className: "btn btn theme-btn",
    },
  ];

  const columns = useMemo(
    () => [
      {
        header: LABELS.JOBBER_ID_TEXT,
        accessorKey: ACTION_LABEL.JOBBER_ID,
        isShow: true,
      },
      {
        header: LABELS.CODE_TEXT,
        accessorKey: ACTION_LABEL.CODE,
        isShow: true,
      },
      {
        header: LABELS.NAME_TEXT,
        accessorKey: ACTION_LABEL.NAME,
        isShow: true,
      },
      {
        header: LABELS.ALIAS_TEXT,
        accessorKey: ACTION_LABEL.ALIAS,
        isShow: true,
      },
      {
        header: LABELS.CITY_TEXT,
        accessorKey: ACTION_LABEL.CITY,
        isShow: true,
      },
      {
        header: LABELS.CLASS_TYPE_TEXT,
        accessorKey: ACTION_LABEL.CLASS_TYPE,
        isShow: true,
      },
      {
        header: LABELS.STATE_TEXT,
        accessorKey: ACTION_LABEL.STATE,
        isShow: true,
      },
      {
        header: LABELS.CLASS_NAME_TEXT,
        accessorKey: ACTION_LABEL.CLASS_NAME,
        isShow: true,
      },
      {
        header: LABELS.AR_LEDGER_TEXT,
        accessorKey: ACTION_LABEL.AR_LEDGER,
        isShow: false,
      },
      {
        header: LABELS.IDENTITY_NO_TEXT,
        accessorKey: ACTION_LABEL.IDENTITY_NO,
        isShow: false,
      },
      {
        header: LABELS.BILLING_CITY_TEXT,
        accessorKey: ACTION_LABEL.BILLING_CITY,
        isShow: false,
      },
      {
        header: LABELS.BILLING_CONTACT_PERSON_TEXT,
        accessorKey: ACTION_LABEL.BILLING_CONTACT_PERSON,
        isShow: true,
      },
      {
        header: LABELS.BILLING_EMAIL_1_TEXT,
        accessorKey: ACTION_LABEL.BILLING_EMAIL_1,
        isShow: true,
      },
      {
        header: LABELS.BILLING_OFFICE_PHONE_1_TEXT,
        accessorKey: ACTION_LABEL.BILLING_OFFICE_PHONE_1,
        isShow: false,
      },
      {
        header: LABELS.SHIPPING_CONTACT_PERSON_TEXT,
        accessorKey: ACTION_LABEL.SHIPPING_CONTACT_PERSON,
        isShow: false,
      },
      {
        header: LABELS.CONTACT_PERSON_NAME_TEXT,
        accessorKey: ACTION_LABEL.CONTACT_PERSON_NAME,
        isShow: false,
      },
      {
        header: LABELS.ADDRESS_TEXT,
        accessorKey: ACTION_LABEL.ADDRESS,
        isShow: false,
      },
      {
        header: LABELS.PIN_TEXT,
        accessorKey: ACTION_LABEL.PIN,
        isShow: false,
      },
      {
        header: LABELS.TAX_REGION_TEXT,
        accessorKey: ACTION_LABEL.TAX_REGION,
        isShow: false,
      },
      {
        header: LABELS.PHONE_NO_TEXT,
        accessorKey: ACTION_LABEL.PHONE_NO,
        isShow: false,
      },
      {
        header: LABELS.TRADE_GROUP_TEXT,
        accessorKey: ACTION_LABEL.TRADE_GROUP,
        isShow: false,
      },
      {
        header: LABELS.EMAIL_TEXT,
        accessorKey: ACTION_LABEL.EMAIL,
        isShow: false,
      },
      // {
      //   header: LABELS.PURCHASE_TERM_TEXT,
      //   accessorKey: ACTION_LABEL.PURCHASE_TERM,
      //   isShow: false,
      // },
      // {
      //   header: LABELS.PAN_NO_TEXT,
      //   accessorKey: ACTION_LABEL.PAN_NO,
      //   isShow: false,
      // },
      // {
      //   header: LABELS.GST_CATEGORY_TEXT,
      //   accessorKey: ACTION_LABEL.GST_CATEGORY,
      //   isShow: false,
      // },
      // {
      //   header: LABELS.GSTIN_NO_TEXT,
      //   accessorKey: ACTION_LABEL.GST_IN_NO,
      //   isShow: false,
      // },
      // {
      //   header: LABELS.GST_STATE_TEXT,
      //   accessorKey: ACTION_LABEL.GST_STATE,
      //   isShow: false,
      // },
    ],
    [sort]
  );

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.VENDOR_MANAGEMENT_TEXT} />
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-12 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.q}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    />

                    {authProvider.show("vendor", "edit") ? (
                      <>
                        <SimpleButton
                          datatoggle="tooltip"
                          dataplacement="top"
                          title={BUTTON_LABEL.IMPORT}
                          className={
                            "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                          }
                          onClick={handleRef}
                          text={BUTTON_LABEL.IMPORT}
                        >
                          <input
                            type="file"
                            style={{ display: "none" }}
                            ref={iconInput}
                            onChange={handleCsvImport}
                            accept=".csv"
                            onClick={(e: any) => {
                              e.target.value = null;
                            }}
                            hidden
                          />
                        </SimpleButton>

                        <CSVLink
                          data={csvData}
                          filename={"vendor.csv"}
                          className="w-auto px-0"
                        >
                          <SimpleButton
                            datatoggle="tooltip"
                            dataplacement="top"
                            title={BUTTON_LABEL.SAMPLE_CSV}
                            className={
                              "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                            }
                            text={BUTTON_LABEL.SAMPLE_CSV}
                          />
                        </CSVLink>

                        {/* <SimpleButton
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      }
                      text={BUTTON_LABEL.EXPORT}
                    /> */}
                      </>
                    ) : (
                      ""
                    )}

                    <SimpleButton
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse filter-btn"
                      }
                      btnIcon={icons.ICON_FILTER}
                      btnIconClass={"me-2 d-inline-block"}
                      onClick={handleCallFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={vendorList}
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    coloumnDrop={true}
                    count={count}
                    isFetching={vendorListFetching}
                    handleRow={handleVendorDetails}
                    isHandleRowEnabled
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <CustomModal
        show={showDetail}
        close={handleClose}
        size={"lg"}
        message={""}
        modalTitle={LABELS.VENDOR_DETAILS_TEXT}
        modalButton={vendorModalButton}
        fullscreen={undefined}
        className={"light-modal inventory-detail-modal"}
        centerClass={""}
        modalTitleIcon={""}
      >
        <VendorDetail
          vendorDetailData={vendorDetailData}
          getVendorLoading={getVendorLoading}
        />
      </CustomModal>

      <Filter
        sort={filterValues}
        filterSelectionError={filterSelectionError}
        filterObject={filterContent(handleTextChange)}
        show={showFilter}
        onClose={closeFilter}
        handleReset={resetFilter}
        handleApply={handleCallFilter}
        handleOnBlur={handleOnBlur}
      />
    </>
  );
};

export default Vendor;
