import { API_URL } from "../../shared/constants";
import { emptySplitApi } from "../emptySplit-api";

export const dashboardApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    dashboardCounts: builder.query<any, any>({
      query: () => {
        return {
          url: `/${API_URL.DASHBOARD}/counts`,
          method: "post",
        };
      },
    }),
    dashboardSearch: builder.query<any, any>({
      query: () => {
        return {
          url: `/${API_URL.DASHBOARD}/search`,
          method: "post",
          //   body: data,
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const { useDashboardCountsQuery, useDashboardSearchQuery } =
  dashboardApi;
