import TextArea from "../../../../../shared/components/TextArea";
import { ACTION_LABEL, LABELS } from "../../../../../shared/constants";

const CancelOrder = (props: any) => {
  const {
    orderId,
    orderCancelData,
    orderCancelLoading,
    handleTextChange,
    handleBlur,
    handleSave,
    handleCancel,
  } = props;
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <TextArea
              name={ACTION_LABEL.REMARKS}
              placeholder={LABELS.REMARKS_TEXT}
              id={LABELS.REMARKS_TEXT}
              label={LABELS.REMARKS_TEXT}
              onChange={handleTextChange}
              onBlur={handleBlur}
              value={orderCancelData.remarks}
              maxLength={40}
            />
          </div>
        </div>
      </div>
      {/* <div className="row mt-3 justify-content-center align-items-center">
        <div className="col-12 text-center">
          <SimpleButton
            className="btn theme-btn theme-btn-sm me-2"
            onClick={(e: any) => handleSave(e, orderId)}
            text={BUTTON_LABEL.SUBMIT}
            // text={orderCancelLoading ? <ButtonLoader /> : BUTTON_LABEL.SUBMIT}
          />

          <SimpleButton
            className="btn theme-btn theme-btn-sm btn-inverse"
            onClick={handleCancel}
            text={BUTTON_LABEL.CANCEL}
          />
        </div>
      </div> */}
    </>
  );
};

export default CancelOrder;
