import Select from "react-select";

const SelectionSearch = (props: any) => {
  const {
    data,
    onChange,
    value,
    text,
    label,
    id,
    required,
    errorMessage,
    placeholder,
    className,
    handleKeyDown,
    onInputChange,
    isMulti,
    searchKeyword,
  } = props;

  let updated = data?.map((ele: any) => {
    return { value: ele._id, label: ele.name || ele.itemCode };
  });

  let selectedValue = null;
  if (value) {
    selectedValue = updated?.find((option: any) => option.value === value);
  }

  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}{" "}
      {required && <span className="text-error">*</span>}
      <Select
        options={[{ value: "", label: text || "Select option" }, ...updated]}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            primary25: "#F4F1EA",
            primary: "#3E2215",
          },
        })}
        onChange={onChange}
        onInputChange={onInputChange}
        value={selectedValue}
        placeholder={placeholder}
        className={className && className ? className : ""}
        onKeyDown={handleKeyDown}
        isMulti={isMulti || undefined}
      />
      <span className="text-error">{errorMessage}</span>
    </>
  );
};

export default SelectionSearch;
