export const LABELS = {
  ROLE_MANAGEMENT: "Role Management",
  NAME_TEXT: "Name",
  FIRSTNAME_TEXT: "First Name",
  LASTNAME_TEXT: "Last Name",
  PHONE_TEXT: "Phone",
  EMAIL_TEXT: "Email",
  SIGNIN: "Sign In",
  EMAIL_ADDRESS_TEXT: "Email Address",
  USERNAME_TEXT: "User Name",
  PASSWORD_TEXT: "Password",
  NEW_PASSWORD_TEXT: "New Password",
  CONFIRM_PASSWORD_TEXT: "Confirm Password",
  CURRENT_PASSWORD_TEXT: "Current Password",
  RESET_PASSWORD_TEXT: "Reset Password",
  EXISTING_PASSWORD_TEXT: "Existing Password",
  ACTIVE_NAME_TEXT: "Active",
  INACTIVE_NAME_TEXT: "Inactive",
  ROLE_TEXT: "Role",
  STATE_TEXT: "State",
  STATUS_TEXT: "Status",
  USER_TYPE_TEXT: "User Type",
  ADDRESS_TEXT: "Address",
  CREATED_AT_TEXT: "Created At",
  UPDATED_AT_TEXT: "Updated At",
  LOGIN_TEXT: "Login",
  LOGOUT_TEXT: "Logout",
  PROFILE_TEXT: "Profile",
  CUSTOMER_MANAGEMENT_TEXT: "Customer Management",
  DASHBOARD_TEXT: "Dashboard",
  INVENTORY_TEXT: "Inventory Management",
  ORDER_MANAGEMENT_TEXT: "Order Management",
  FOLLOW_UP_TEXT: "Follow Up",
  ORDER_INFORMATION_TEXT: "Order Information",
  PRODUCT_MANAGEMENT_TEXT: "Product Management",
  ROLE_MANAGEMENT_TEXT: "Role Management",
  ROLES_TEXT: "Roles",
  SALES_PERSON_TEXT: "Sales Persons",
  STORE_COORDINATOR_TEXT: "Store Coordinator",
  USERS_TEXT: "Users",
  STORE_TEXT: "Store",
  STORE_MANAGEMENT_TEXT: "Store Management",
  USER_MANAGEMENT_TEXT: "User Management",
  USER_PROFILE_TEXT: "User Profile",
  VENDOR_MANAGEMENT_TEXT: "Vendor Management",
  EDIT_USER_TEXT: "Edit User",
  ADD_USER_TEXT: "Add User",
  ADD_ORDER_TEXT: "Add Order",
  EDIT_ORDER_TEXT: "Edit Order",
  ORDER_DETAILS_TEXT: "Order Details",
  PRODUCT_DETAILS_TEXT: "Product Details",
  VENDOR_DETAILS_TEXT: "Vendor Details",
  USER_DETAILS_TEXT: "User Details",
  SALES_PERSON_DETAILS_TEXT: "Sales Persons Details",
  STORE_COORDINATOR_DETAILS_TEXT: "Store Coordinator Details",
  EDIT_STORE_COORDINATOR_TEXT: "Edit Store Coordinator",
  ADD_STORE_COORDINATOR_TEXT: "Add Store Coordinator",
  ITEM_TEXT: "Item",
  PRODUCT_BOM_DETAILS_TEXT: "Product BOM Details",
  MY_PROFILE_TEXT: "My Profile",
  PERSONAL_INFORMATION_TEXT: "Personal Information",
  CHANGE_PASSWORD_TEXT: "Change Password",
  E_MAIL_ADDRESS_TEXT: "E-Mail Address",
  DESIGNATION_TEXT: "Designation",
  CITY_TEXT: "City",
  SEARCH_TEXT: "Search",
  USER_MANAGEMENT_DETAILS_TEXT: "User Management Details",
  STORE_MANAGEMENT_DETAILS_TEXT: "Store Management Details",
  INVENTORY_MANAGEMENT_DETAILS_TEXT: "Inventory Management Details",
  CUSTOMER_DETAILS_TEXT: "Customer Details",
  REPORT_DETAILS_TEXT: "Report Details",
  CODE_TEXT: "Code",
  DIVISION_TEXT: "Division",
  SECTION_TEXT: "Section",
  DEPARMENT_TEXT: "Department",
  ARTICLE_NAME_TEXT: "Article Name",
  CATEGORY_TEXT: "Category",
  REMEMBER_ME_TEXT: "Remember Me",
  FORGOT_PASSWORD_TEXT: "Forgot Password?",
  CONFIRMATION_CODE_TEXT: "Confirmation Code",
  SALES_PERSON_NAME_TEXT: "Sales Person Name",
  PHONE_NO_TEXT: "Phone No.",
  DESIGN_NO_TEXT: "Design No.",
  ORDER_NO_TEXT: "Order No.",
  CUSTOMER_NAME_TEXT: "Customer Name",
  DELIVERY_DATE_TEXT: "Delivery Date",
  REMAINING_DAY_TEXT: "Remaining Day",
  SALUTATION_TEXT: "Salutation",
  MIDDLE_NAME_TEXT: "Middle Name",
  DISTRICT_TEXT: "District",
  PIN_TEXT: "Pin",
  MOBILE_NO_TEXT: "Mobile No.",
  REMARKS_TEXT: "Remarks",
  GST_STATE_TEXT: "GST State",
  GST_STATE_NAME_TEXT: "GST State Name",
  GST_IN_TEXT: "GSTIN",
  JOBBER_ID_TEXT: "Jobber ID",
  ALIAS_TEXT: "Alias",
  CLASS_TYPE_TEXT: "Class Type",
  CLASS_NAME_TEXT: "Class Name",
  AR_LEDGER_TEXT: "AR Ledger",
  CONTACT_PERSON_NAME_TEXT: "Contact Person Name",
  TAX_REGION_TEXT: "Tax Region",
  TRADE_GROUP_TEXT: "Trade Group",
  PURCHASE_TERM_TEXT: "Purchase Term",
  PAN_NO_TEXT: "PAN No.",
  GST_CATEGORY_TEXT: "GST Category",
  GSTIN_NO_TEXT: "GSTIN No.",
  BARCODE_TEXT: "Barcode",
  ITEM_NAME_TEXT: "Item name",
  DEPARTMENT_TEXT: "Department",
  HSN_CODE_TEXT: "HSN Code",
  SITE_STOCK_TEXT: "Site Stock",
  CUID_TEXT: "CUID",
  SHORT_CODE_TEXT: "Short Code",
  DOC_IDENTIFICATION_NO_TEXT: "Doc.Indentification No.",
  PRICE_TYPE_TEXT: "Price Type",
  SITE_TYPE_TEXT: "Site Type",
  ORGANIZATION_UNIT_TEXT: "Organization Unit",
  STORE_SIZE_TEXT: "Store Size",
  SYNC_DATE_AND_TIME_TEXT: "Sync Date and Time",
  EMAIL_ID_TEXT: "Email Id",
  STRING_DESC_TEXT: "String Desc",
  MATERIAL_TYPE_TEXT: "Material-Type",
  UOM_TEXT: "UOM",
  MRP_TEXT: "MRP",
  RSP_TEXT: "RSP",
  WSP_TEXT: "WSP",
  LAST_MODIFIED_ON_TEXT: "Last Modified On",
  LAST_INWARD_RATE_TEXT: "Last Inward Rate",
  STANDARD_RATE_TEXT: "Standard Rate",
  CLOSING_QTY_TEXT: "Closing QTY",
  LAST_STOCK_INWARD_DATE_TEXT: "last stock inward date",
  CREATED_ON_TEXT: "Created On",
  FULLNAME_TEXT: "Full Name",
  STOCK_POINT_TEXT: "Stock Point",
  MOBILE_TEXT: "Mobile",
  TARGET_SALES_TEXT: "Target sales",
  CUSTOMER_TEXT: "Customer",
  SHIPPING_CONTACT_PERSON_TEXT: "Shipping Contact Person",
  BILLING_CITY_TEXT: "Billing City",
  BILLING_OFFICE_PHONE_1_TEXT: "Billing Office Phone 1",
  BILLING_CONTACT_PERSON_TEXT: "Billing Contact Person",
  BILLING_EMAIL_1_TEXT: "Billing Email 1",
  COMPANY_TYPE_TEXT: "Company_Type",
  INDUSTRY_TYPE_TEXT: "Industry_Type",
  IDENTITY_NO_TEXT: "Identity No",
  EDIT_CUSTOMER_TEXT: "Edit Customer",
  ADD_CUSTOMER_TEXT: "Add Customer",
  REPORT_TEXT: "Reports",
  ALREADY_HAVE_AN_ACOUNT_TEXT: "Already Have An Account ?",
  ITEM_CODE_TEXT: "Item Code",
  DESTINATION_SITE_TEXT: "Destination Site",
  SIZE_TEXT: "Size",
  ORDER_DATE_TEXT: "Order Date",
  FABRIC_CODE_TEXT: "Fabric Code",
  I_CODE_TEXT: "I Code",
  COLOR_TEXT: "Color",
  PRICE_TEXT: "Price",
  SO_NO_TEXT: "S.O No.",
  QTY_TEXT: "Qty",
  ISD_CODE_TEXT: "ISD Code",
  GENDER_TEXT: "Gender",
  DOB_TEXT: "DOB",
  RECIEVE_MESSAGE_TEXT: "Recieve Message",
  PREF_COMMUNICATION_MODE_TEXT: "Pref Communication Mode",
  SITE_CODE_TEXT: "Site Code",
  NOT_FOUND_TEXT: "Not Found",
  CUSTOMER_INFORMATION_TEXT: "Customer Information",
  SUB_ASSEMBLY_ITEM_TEXT: "Sub Assembly item",
  TRACKING_INFORMATION_TEXT: "Tracking Information",
  CUS_TEXT: "Cus.",
  SR_NO_TEXT: "Sr. No.",
  D_NO_TEXT: "D. No.",
  DATE_TEXT: "Date",
  No_DATA_FOUND_TEXT: "No Data Found",
  POPULATE_TEXT: "Populate",
  PRINT_ORDER_TEXT: "Print Order",
  CONFIRM_TEXT: "Confirm",
  PENDING_TEXT: "Pending",
  PRINT_ALL_TEXT: "Print All",
  DELETE_TEXT: "Delete",
  DELETE_USER_TEXT: "Delete User",
  DELETE_ROLE_TEXT: "Delete Role",
  DELETE_STORE_COORDINATOR_TEXT: "Delete Store Coordinator",
};

export const BUTTON_LABEL = {
  ADD: "Add",
  YES: "Yes",
  NO: "No",
  CANCEL: "Cancel",
  VIEW: "View",
  EDIT: "Edit",
  DELETE: "Delete",
  SAVE: "Save",
  SEARCH: "Search",
  DOWNLOAD: "Download",
  EXPORT: "Export",
  IMPORT: "Import",
  PRINT: "Print",
  SIGNIN: "Sign In",
  UPDATE: "update",
  FILTER: "Filter",
  RESET_FILTER: "Reset Filter",
  CHANGE_PASSWORD: "Change Password",
  REQUEST_PASSWORD: "Request Password",
  VALIDATE_OTP: "Validate OTP",
  LOGIN: "Login",
  SEND_OTP: "Send Otp",
  SUBMIT: "Submit",
  ADD_ROLE: "Add Role",
  CLOSE: "Close",
  CONFIRM: "Confirm",
  ENABLE_EDIT: "Enable Edit",
  SAVE_UPDATE: "Save Update",
  SAMPLE_CSV: "Sample CSV",
  COLUMN_CONFIGURATION: "Column Configuration",
  DOWNLOAD_PDF: "Download the PDF",
  PRINT_TRANSACTION: "Print Transaction",
  POPULATE: "Populate",
};

export const TOAST_MESSAGE = {
  RECORD_NOT_FOUND: "The record you are looking for was not found.",
  KEY_COPIED: "key copied to clipboard.",
  USER_PROFILE_UPDATED: "User Profile updated successfully.",
  USER_UPDATED: "User updated successfully.",
  USER_DELETED: "User deleted successfully.",
  USER_CREATED: "User added successfully.",
  ROLE_UPDATED: "Role updated successfully.",
  ROLE_DELETED: "Role deleted successfully.",
  ROLE_CREATED: "Role added successfully.",
  EMAIL_NOT_MATCH: "Email / Mobile Number doesn't matched.",
  OTP_VALID: "OTP validated successfully.",
  OTP_NOT_VALID: "OTP not valid ",
  INVALID_PASSWORD: "Invalid Password",
  PASSWORD_DOES_NOT_MATCH:
    "The password you entered doesn't match the password in the system.",
  PASSWORD_UPDATED: "Password has been updated successfully.",
  FORBIDDEN_ACCESS: "Forbidden access.",
  PERMISSION_REQUIRED: "Please Select atleast one Role .",
  FILE_DOWNLOAD: "File downloaded successfully.",
  PHOTO_UPLOADED: "Image uploaded successfully.",
  CSV_IMPORTED: "CSV imported successfully.",
  CSV_EXPORTED: "CSV exported successfully.",
  STORE_CREATED: "Store added Successfully.",
  LOGIN: "Successfully Logged In",
  LOGOUT: "You have successfully logged out",
  SALES_PERSON_UPDATED: "Sales Person updated successfully.",
  STORE_COORDINATOR_CREATED: "Store Coordinator created successfully.",
  STORE_COORDINATOR_UPDATED: "Store Coordinator updated successfully.",
  STORE_COORDINATOR_DELETED: "Store Coordinator deleted successfully.",
  CUSTOMER_UPDATED: "Customer updated successfully.",
};

export const PAGE_TITLE = {};

export const MESSAGE = {
  DELETE_MESSAGE: "Are you sure, you want to delete ",
  TITLE_TEXT: "A royal fusion of modern and ethnic wear for all occasions",
};

export const ERROR_MESSAGE = {
  NAME_REQUIRED: "Name is required.",
  FIRST_NAME_REQUIRE: "First Name is required.",
  MIDDLE_NAME_REQUIRE: "Middle Name is required.",
  FULL_NAME_REQUIRE: "Full Name is required.",
  LAST_NAME_REQUIRE: "Last Name is required.",
  USER_NAME_REQUIRE: "User Name is required.",
  EMAIL_REQUIRE: "E-Mail is required.",
  EMAIL_ADDRESS_REQUIRE: "E-Mail Address is required.",
  PASSWORD_REQUIRE: "Password is required.",
  CONFIRM_PASSWORD_REQUIRE: "Confirm Password is required.",
  EXISTING_PASSWORD_REQUIRE: "Existing Password is required",
  CODE_REQUIRED: "Code is required.",
  ADDRESS_REQUIRED: "Address is required.",
  ADDRESS_1_REQUIRED: "Address 1 is required.",
  ADDRESS_2_REQUIRED: "Address 2 is required.",
  ROLE_REQUIRE: "Role is required.",
  NAME_MIN_LENGTH: "Name must be more than 4 characters!",
  NAME_MAX_LENGTH: "Name cannot exceed more than 50 characters!",
  PHONE_REQUIRE: "Phone is required.",
  MOBILE_REQUIRE: "Mobile is required.",
  NAME_REGEX_MESSAGE: "Name must be alphabetic.",
  FULLNAME_REGEX_MESSAGE: "Full Name must be alphabetic.",
  DESCRIPTION_REGEX_MESSAGE: "Description only allows alphabets and numbers.",
  KEY_REGEX_MESSAGE: "Key must be alphabetic",
  VALUE_REGEX_MESSAGE: "Value must be alphabetic",
  EMAIL_REGEX_MESSAGE: "Enter valid E-mail.",
  EMAIL_ADDRESS_REGEX_MESSAGE: "Enter valid E-Mail address.",
  CONTACT_NUMBER_REGEX_MESSAGE: "Enter valid contact number.",
  PASSWORD_AND_CONFIRM_PASSWORD_MESSAGE:
    "password and confirm password must be same.",
  OTP_REQUIRED: "otp required.",
  PHONE_REGEX_MESSAGE: "Enter valid phone number.",
  MOBILE_NO_REGEX_MESSAGE: "Enter valid mobile no.",
  USER_TYPE_REQUIRED: "User Type is required.",
  STORE_REQUIRE: "Store is required.",
  DESIGNATION_REQUIRED: "Designation is required.",
  STATE_REQUIRED: "State is required.",
  CITY_REQUIRED: "City is required.",
  PERMISSION_REQUIRED: "Permission is required.",
  SALUTATION_REQUIRE: "Salutation is required.",
  DISTRICT_REQUIRED: "District is required.",
  PIN_REQUIRED: "Pin is required.",
  REMARKS_REQUIRED: "Remarks is required",
  GST_STATE_NAME_REQUIRED: "GST State Name is required",
  GST_IN_REQUIRED: "GSTIN is required",
  ISD_CODE_REQUIRED: "ISD Code is required.",
  GENDER_REQUIRED: "Gender is required.",
  DOB_REQUIRED: "DOB is required.",
  RECEIVED_MESSAGE_REQUIRED: "Received Message is required.",
  PREF_COMMUNICATION_MODE_REQUIRED: "Pref Communication Mode is required.",
  SITE_CODE_REQUIRED: "Site Code is required.",
};

export const REGULAR_EX = {
  name: /^[a-zA-z ]{2,}$/,
  NAME_REGEX: /^[a-zA-z .,_-]{1,}$/,
  EMAIL_REGEX:
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  PHONE_REGEX: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  WHITE_SPACE_WITH_TEXT: /^(?! )[A-Za-z 0-9]{3,}$/,
  ONLY_WHITE_SPACE: /^\S*$/,
  ALPHANUMBERIC_REGEX: /^[a-zA-Z0-9\s]+$/,
  // USERNAME_REGEX: /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
  USERNAME_REGEX: /^[a-z]+([\w]+){5,20}$/,
};

export const INPUT_TYPES = {
  TEXT: "text",
  BUTTON: "button",
  CHECKBOX: "checkbox",
  NUMBER: "number",
  USERNAME: "username",
  PASSWORD: "password",
  SUBMIT: "submit",
};
