export const useFilter = (sort: any, setSort: any) => {
  const handleFilter = () => {
    setSort(sort);
  };

  const resetFilter = () => {
    setSort({ ...sort, filter: { startDate: "", endDate: "" } });
  };

  return [handleFilter, resetFilter];
};
