import Navbar from "../../Navbar/NavbarComponent/Navbar";
import {
  useStoreByIdQuery,
  useStoreImportMutation,
  useStoreListQuery,
} from "../../../Features/store/store-slice";
import { useEffect, useMemo, useRef, useState } from "react";
import Table from "../../../shared/components/Table";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  SEARCH_DATA,
  toastError,
  toastSuccess,
  TOAST_MESSAGE,
} from "../../../shared/constants";
import CustomModal from "../../../shared/components/CustomModal";
import StoreDetail from "./StoreDetail";
import Search from "../../../shared/components/Search";
import { skipToken } from "@reduxjs/toolkit/query";
import SimpleButton from "../../../shared/components/Button/Button";
import { CSVLink } from "react-csv";

const StoreList = () => {
  const [search, setSearch] = useState(false);
  const [sort, setSort] = useState(SEARCH_DATA);
  const [count, setCount] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [storeId, setStoreId]: any = useState();
  const [storeDetailData, setStoreDetailData] = useState();
  const iconInput = useRef<any>(null);

  const {
    data,
    isSuccess,
    error,
    isFetching: storeListFetching,
    refetch: storeRefetch,
  } = useStoreListQuery(sort);

  const {
    data: getStoreData,
    isLoading: getStoreLoading,
    isSuccess: getStoreSuccess,
  } = useStoreByIdQuery(storeId ? storeId : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [
    storeImport,
    { isSuccess: storeImportSuccess, isLoading: storeImportLoading },
  ] = useStoreImportMutation();

  useEffect(() => {
    if (isSuccess) {
      setStoreList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (storeId !== undefined && getStoreSuccess) {
      setStoreDetailData(getStoreData.item);
    }
  }, [storeId, getStoreData, getStoreSuccess]);

  const storeImportFlow = (data: any) => {
    storeRefetch();
    toastSuccess(data.message);
  };

  const handleClose = () => setShowDetail(false);

  const handleShow = () => {
    setShowDetail(true);
  };

  const actions = [];

  const handleSearch = () => {
    // if (val === "") {
    //   setSearch(!search);
    //   setSort({ ...sort, q: undefined, skip: 0 });
    // } else {
    //   setSearch(!search);
    //   setSort({ ...sort, skip: 0 });
    // }

    setSearch(!search);
    setSort({ ...sort, skip: 0 });
  };

  const handleSearchTextChange = (e: any) => {
    setSort({ ...sort, [e.target.name]: e.target.value, skip: 0 });
    // if (e.target.value.length === 0) {
    //   handleSearch(e.target.value);
    // }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
      // handleSearch(e.target.value);
    }
  };

  const handleRef = () => {
    iconInput.current.click();
  };

  const handleCsvImport = async (e: any) => {
    let formData = new FormData();
    formData.append("csv", e.target.files[0], e.target.files[0].name);
    const storeImportResponse = await storeImport(formData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (storeImportResponse && storeImportResponse?.status === true) {
      storeImportFlow(storeImportResponse);
    }
  };

  const csvData = [
    {
      CUID: "1123",
      SHORT_CODE: "INDORE Y N ROAD",
      DOC_IDENTIFICATION_NO: "A24",
      NAME: "N M FASHION DESIGNS PVT LTD (KORA INDORE)",
      STORE_SIZE: "0",
      TARGET_SALES: "0",
      PRICE_TYPE: "MRP",
      SITE_TYPE: "US-CO-OM",
      CUSTOMER: "RIRONSI COUTURE LLP",
      ADDRESS:
        "GR FLOOR AND 1ST FLOOR,SHIVAAY ONE,2, YASHWANT NIWAS ROAD,NEAR SBI BANK,",
      SYNC_DATE_AND_TIME: "",
      GST_STATE_NAME: "Maharashtra",
      STATE: "",
      CITY: "JAIPUR",
      PIN: "",
      EMAIL: "",
      CODE: "708",
      MOBILE: "9414062062",
      PHONE_NO_1: "",
      TAX_REGION: "",
    },
    {
      CUID: "1125",
      SHORT_CODE: "COIMBATORE GANDHIPURAM",
      DOC_IDENTIFICATION_NO: "A26",
      NAME: "N M FASHION DESIGNS PVT LTD (KORA COIBATORE)",
      STORE_SIZE: "0",
      TARGET_SALES: "0",
      PRICE_TYPE: "MRP",
      SITE_TYPE: "US-CO-OM-TS",
      CUSTOMER: "PCUBE RAGE LLP (KORA VIJAYWADA)",
      ADDRESS:
        "PLOT NO 301/1, CROSS CUT ROAD,NEAR KEERTI LAL JEWELLERY,GANDHIPURAM,",
      SYNC_DATE_AND_TIME: "",
      GST_STATE_NAME: "Andhra Pradesh",
      STATE: "",
      CITY: "NEW DELHI",
      PIN: "",
      EMAIL: "",
      CODE: "502",
      MOBILE: "9412088888",
      PHONE_NO_1: "",
      TAX_REGION: "",
    },
    {
      CUID: "1225",
      SHORT_CODE: "INDOMUMBAI BORIVALI",
      DOC_IDENTIFICATION_NO: "A13",
      NAME: "N M FASHION DESIGNS PVT LTD (KORA BORIVALI)",
      STORE_SIZE: "0",
      TARGET_SALES: "0",
      PRICE_TYPE: "MRP",
      SITE_TYPE: "US-CO-OM",
      CUSTOMER: "RIRONSI COUTURE LLP",
      ADDRESS:
        "KORA SHOP NO. 1& 2,KABRA ELITA HUB, SODAWALA LANE,BORIVALI (WEST)",
      GST_STATE_NAME: "Maharashtra",
      STATE: "",
      CITY: "SURAT",
      PIN: "",
      EMAIL: "",
      CODE: "390",
      MOBILE: "8758788577",
      PHONE_NO_1: "",
      TAX_REGION: "",
    },
    {
      CUID: "2121",
      SHORT_CODE: "DUBAI MEENA BAZAR",
      DOC_IDENTIFICATION_NO: "A11",
      NAME: "SATUTI GUPTA GARMENTS TRADING LLC (KORA DUBAI)",
      STORE_SIZE: "0",
      TARGET_SALES: "0",
      PRICE_TYPE: "MRP",
      SITE_TYPE: "US-CO-OM",
      CUSTOMER: "SATUTI GUPTA GARMENTS TRADING IIC (KORA DUBAI)",
      ADDRESS: "OPP.BAWREE,COSMOS LANE,MEENA BAZAR,BUR DUBAI",
      GST_STATE_NAME: "",
      STATE: "",
      CITY: "ANAND",
      PIN: "",
      EMAIL: "",
      CODE: "375",
      MOBILE: "9824079557",
      PHONE_NO_1: "",
      TAX_REGION: "",
    },
  ];

  const handleStoreDetails = (e: any, row: any) => {
    setStoreId(row._id);
    setShowDetail(true);
  };

  const storeModalButton = [
    {
      text: BUTTON_LABEL.CANCEL,
      action: handleClose,
      className: "btn btn theme-btn",
    },
  ];

  const columns = useMemo(
    () => [
      {
        header: LABELS.CUID_TEXT,
        accessorKey: ACTION_LABEL.CUID,
        isShow: true,
      },
      {
        header: LABELS.SHORT_CODE_TEXT,
        accessorKey: ACTION_LABEL.SHORTCODE,
        isShow: true,
      },
      {
        header: LABELS.DOC_IDENTIFICATION_NO_TEXT,
        accessorKey: ACTION_LABEL.DOC_IDENTIFICATION_NO,
        isShow: true,
      },
      {
        header: LABELS.NAME_TEXT,
        accessorKey: ACTION_LABEL.NAME,
        isShow: true,
      },
      {
        header: LABELS.TARGET_SALES_TEXT,
        accessorKey: ACTION_LABEL.TARGET_SALES,
        isShow: true,
      },
      {
        header: LABELS.CUSTOMER_TEXT,
        accessorKey: ACTION_LABEL.CUSTOMER,
        isShow: false,
      },
      {
        header: LABELS.PRICE_TYPE_TEXT,
        accessorKey: ACTION_LABEL.PRICE_TYPE,
        isShow: true,
      },
      {
        header: LABELS.SITE_TYPE_TEXT,
        accessorKey: ACTION_LABEL.SITE_TYPE,
        isShow: true,
      },
      {
        header: LABELS.STORE_SIZE_TEXT,
        accessorKey: ACTION_LABEL.STORE_SIZE,
        isShow: true,
      },
      {
        header: LABELS.SYNC_DATE_AND_TIME_TEXT,
        accessorKey: ACTION_LABEL.SYNC_DATE_AND_TIME,
        isShow: false,
      },
      {
        header: LABELS.GST_STATE_NAME_TEXT,
        accessorKey: ACTION_LABEL.GST_STATE_NAME,
        isShow: false,
      },
      {
        header: LABELS.ADDRESS_TEXT,
        accessorKey: ACTION_LABEL.ADDRESS,
        isShow: false,
      },
      {
        header: LABELS.STATE_TEXT,
        accessorKey: ACTION_LABEL.STATE,
        isShow: false,
      },
      {
        header: LABELS.CITY_TEXT,
        accessorKey: ACTION_LABEL.CITY,
        isShow: false,
      },
      {
        header: LABELS.PIN_TEXT,
        accessorKey: ACTION_LABEL.PIN,
        isShow: false,
      },
      {
        header: LABELS.MOBILE_TEXT,
        accessorKey: ACTION_LABEL.MOBILE,
        isShow: false,
      },
      {
        header: `${LABELS.PHONE_NO_TEXT} 1`,
        accessorKey: `${ACTION_LABEL.PHONE_NO}1`,
        isShow: false,
      },
      // {
      //   header: `${LABELS.PHONE_NO_TEXT} 2`,
      //   accessorKey: `${ACTION_LABEL.PHONE_NO}2`,
      //   isShow: false,
      // },
      {
        header: LABELS.EMAIL_ID_TEXT,
        accessorKey: ACTION_LABEL.EMAIL,
        isShow: false,
      },
      {
        header: LABELS.CODE_TEXT,
        accessorKey: ACTION_LABEL.CODE,
        isShow: false,
      },
      {
        header: LABELS.TAX_REGION_TEXT,
        accessorKey: ACTION_LABEL.TAX_REGION,
        isShow: false,
      },
    ],
    [sort]
  );

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.STORE_MANAGEMENT_TEXT} />
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-4 d-flex align-items-center">
                  <SimpleButton
                    className={"me-2 btn theme-btn theme-btn-sm btn-inverse"}
                    btnIcon={icons.ICON_FILTER}
                    btnIconClass={"me-2 d-inline-block"}
                    text={BUTTON_LABEL.FILTER}
                  />
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.q}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    />
                    <SimpleButton
                      datatoggle="tooltip"
                      dataplacement="top"
                      title={BUTTON_LABEL.IMPORT}
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      }
                      onClick={handleRef}
                      text={BUTTON_LABEL.IMPORT}
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={iconInput}
                        onChange={handleCsvImport}
                        accept=".csv"
                        onClick={(e: any) => {
                          e.target.value = null;
                        }}
                        hidden
                      />
                    </SimpleButton>
                    <CSVLink
                      data={csvData}
                      filename={"store.csv"}
                      className="w-auto px-0"
                    >
                      <SimpleButton
                        datatoggle="tooltip"
                        dataplacement="top"
                        title={BUTTON_LABEL.SAMPLE_CSV}
                        className={
                          "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                        }
                        text={BUTTON_LABEL.SAMPLE_CSV}
                      />
                    </CSVLink>

                    {/* <SimpleButton
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      }
                      onClick={handleShow}
                      text={BUTTON_LABEL.EXPORT}
                    /> */}
                  </div>
                </div>
              </div>

              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={storeList}
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    coloumnDrop={true}
                    count={count}
                    handleRow={handleStoreDetails}
                    isHandleRowEnabled
                    isFetching={storeListFetching}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <CustomModal
        show={showDetail}
        close={handleClose}
        size={"lg custom-lg"}
        message={""}
        modalTitle={LABELS.STORE_MANAGEMENT_DETAILS_TEXT}
        modalButton={storeModalButton}
        fullscreen={undefined}
        className={"light-modal inventory-detail-modal"}
        centerClass={""}
        modalTitleIcon={""}
      >
        <StoreDetail
          storeDetailData={storeDetailData}
          getStoreLoading={getStoreLoading}
        />
      </CustomModal>
    </>
  );
};

export default StoreList;
