import { API_URL } from "../../shared/constants";
import { emptySplitApi } from "../emptySplit-api";

export const customerApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    customerList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.CUSTOMERS}/list`,
          method: "post",
          body: data,
        };
      },
    }),
    customerCreate: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.CUSTOMERS}/create`,
          method: "post",
          body: data,
        };
      },
    }),
    customerUpdate: builder.mutation<any, any>({
      query: ({ id, data }) => {
        return {
          url: `/${API_URL.CUSTOMERS}/update/${id}`,
          method: "post",
          body: data,
        };
      },
    }),
    customerDelete: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.CUSTOMERS}/delete`,
          method: "post",
          body: data,
        };
      },
    }),
    customerGet: builder.query({
      query: (id) => ({
        url: `/${API_URL.CUSTOMERS}/get/${id}`,
        method: "post",
      }),
    }),
    customerImport: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.CUSTOMERS}/import`,
          method: "post",
          body: data,
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useCustomerListQuery,
  useCustomerCreateMutation,
  useCustomerUpdateMutation,
  useCustomerDeleteMutation,
  useCustomerGetQuery,
  useCustomerImportMutation,
} = customerApi;
