import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { icons, LABELS, URLS } from "../../../shared/constants";
import { UserImage } from "../../../App/assets/img";

const Navbar = (props: any) => {
  const { title } = props;
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { authState } = authContext;

  const IMAGE_URL = process.env.REACT_APP_BACKEND_URL + "/uploads/";

  let username = authState.userInfo.item.name;
  let userLogo = authState.userInfo.item.image;

  const handleLogout = async () => {
    await authContext.setAuthState({ userInfo: {} });
    localStorage.removeItem("userInfo");
    localStorage.removeItem("x-access-token");
    localStorage.clear();
    navigate(URLS.LOGIN);
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary top-nav">
      <div className="container-fluid">
        <div className="navbar-collapse">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link page-title" href="#">
                {title}
              </a>
            </li>
          </ul>
          <ul className="navbar-nav right-navbar ms-auto align-items-center">
            {/* <form className="d-flex search-form">
              <Dropdown className="search-dropdown">
                <Dropdown.Toggle className="no-btn position-relative">
                  <button className="btn search-btn">
                    <i className={`icon ${icons.ICON_SEARCH}`}></i>
                  </button>
                  <input className="form-control me-2" placeholder="Search" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu has-checkbox">
                  <DropdownItem>Dashboard</DropdownItem>
                  <DropdownItem>Store Management</DropdownItem>
                  <DropdownItem>Brand Management</DropdownItem>
                  <DropdownItem>Inventory Management</DropdownItem>
                  <DropdownItem>user Management</DropdownItem>
                </Dropdown.Menu>
              </Dropdown>
            </form>
            <li className="nav-item">
              <a className="nav-link" href="#" title="Notification">
                <i className={`icon ${icons.ICON_NOTIFICATION}`}></i>
              </a>
            </li> */}
            <li className="nav-item" onClick={handleLogout}>
              <a className="nav-link" title={LABELS.LOGOUT_TEXT} href="#">
                <i className={`icon ${icons.ICON_LOGOUT}`}></i>
              </a>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link user-profile"
                title={LABELS.PROFILE_TEXT}
                to={URLS.USER_PROFILE}
              >
                <img
                  src={
                    userLogo && userLogo !== undefined
                      ? IMAGE_URL + userLogo
                      : UserImage
                  }
                  alt=""
                />
                <div>
                  <span className="text-capitalize">{username}</span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
