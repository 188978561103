import Loader from "../../../../shared/components/Loader";
import TextBox from "../../../../shared/components/TextBox";
import { ACTION_LABEL, LABELS } from "../../../../shared/constants";

const StoreCoordinatorDetail = (props: any) => {
  const { storeCoordinatorDetailData, getStoreCoordinatorLoading } = props;

  return (
    <>
      {!getStoreCoordinatorLoading &&
      storeCoordinatorDetailData !== undefined ? (
        <div className="row">
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.FULLNAME_TEXT}
                placeholder={LABELS.FULLNAME_TEXT}
                name={ACTION_LABEL.NAME}
                value={storeCoordinatorDetailData.name}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.EMAIL_TEXT}
                placeholder={LABELS.EMAIL_TEXT}
                name={ACTION_LABEL.EMAIL}
                value={storeCoordinatorDetailData.email}
              />
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.ROLE_TEXT}
                placeholder={LABELS.ROLE_TEXT}
                name={ACTION_LABEL.ROLE}
                value={storeCoordinatorDetailData.userType}
              />
            </div>
          </div> */}

          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.STORE_TEXT}
                placeholder={LABELS.STORE_TEXT}
                name={ACTION_LABEL.STORE}
                value={storeCoordinatorDetailData.store}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.STATUS_TEXT}
                placeholder={
                  storeCoordinatorDetailData.isActive
                    ? LABELS.ACTIVE_NAME_TEXT
                    : LABELS.INACTIVE_NAME_TEXT
                }
                name={ACTION_LABEL.ISACTIVE}
                value={
                  storeCoordinatorDetailData.isActive
                    ? LABELS.ACTIVE_NAME_TEXT
                    : LABELS.INACTIVE_NAME_TEXT
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default StoreCoordinatorDetail;
