export const ACTION_LABEL = {
  NAME: "name",
  ROLE: "role",
  CITY: "city",
  CODE: "code",
  EMAIL: "email",
  PHONE: "phone",
  STORE: "store",
  STATE: "state",
  SEARCH: "search",
  SECTION: "section",
  ADDRESS: "address",
  LASTNAME: "lastName",
  FULLNAME: "fullName",
  USERNAME: "username",
  PASSWORD: "password",
  ISACTIVE: "isActive",
  DIVISION: "division",
  USERTYPE: "userType",
  CATEGORY: "category",
  FIRSTNAME: "firstName",
  DEPARTMENT: "department",
  DESIGNATION: "designation",
  ARTICLE_NAME: "articleName",
  RESET_PASSWORD: "resetPassword",
  NEW_PASSWORD: "newPassword",
  SALES_PERSON_NAME: "salesPersonName",
  PHONE_NO: "phoneNo",
  DESIGN_NO: "designNo",
  ORDER_NO: "orderNo",
  CUSTOMER_NAME: "customerName",
  DELIVERY_DATE: "deliveryDate",
  REMAINING_DAY: "remainingDay",
  SALUTATION: "salutation",
  MIDDLENAME: "middleName",
  DISTRICT: "district",
  PIN: "pin",
  MOBILE_NO: "mobileNo",
  REMARKS: "remarks",
  GST_STATE: "gstState",
  GST_IN: "gstin",
  JOBBER_ID: "jobberId",
  ALIAS: "alias",
  CLASS_TYPE: "classType",
  CLASS_NAME: "className",
  AR_LEDGER: "arLedger",
  CONTACT_PERSON_NAME: "contactPersonName",
  TAX_REGION: "taxRegion",
  TRADE_GROUP: "tradeGroup",
  PURCHASE_TERM: "purchaseTerm",
  PAN_NO: "panNo",
  GST_CATEGORY: "gstCategory",
  GST_IN_NO: "gstInNo",
  BARCODE: "barcode",
  ITEM_NAME: "itemName",
  HSN_CODE: "hsnCode",
  SITE_STOCK: "siteStock",
  CUID: "CUID",
  SHORTCODE: "shortCode",
  DOC_IDENTIFICATION_NO: "docIdentificationNo",
  PRICE_TYPE: "priceType",
  SITE_TYPE: "siteType",
  STORE_SIZE: "storeSize",
  SYNC_DATE_AND_TIME: "syncDateAndTime",
  STATUS: "status",
  CREATED_ON: "createdOn",
  CREATED_AT: "createdAt",
  CREATED_BY: "createdBy",
  UPDATED_ON: "updatedOn",
  UPDATED_AT: "updatedAt",
  UPDATED_BY: "updatedBy",
  MATERIAL_TYPE: "materialType",
  MRP: "mrp",
  RSP: "rsp",
  STRING_DESC: "stringDesc",
  UOM: "uom",
  WSP: "wsp",
  LAST_INWARD_RATE: "lastInwardRate",
  LAST_MODIFIED_ON: "lastModifiedOn",
  STANDARD_RATE: "standardRate",
  LAST_STOCK_INWARD_DATE: "lastStockInwardDate",
  CLOSING_QTY: "closingQTY",
  STOCK_POINT: "stockPoint",
  GST_STATE_NAME: "gstStateName",
  SHORT_CODE: "shortCode",
  MOBILE: "mobile",
  TARGET_SALES: "targetSales",
  CUSTOMER: "customer",
  SHIPPING_CONTACT_PERSON: "shippingContactPerson",
  BILLING_CITY: "billingCity",
  BILLING_OFFICE_PHONE_1: "billingOfficePhone1",
  BILLING_CONTACT_PERSON: "billingContactPerson",
  BILLING_EMAIL_1: "billingEmail1",
  COMPANY_TYPE: "companyType",
  INDUSTRY_TYPE: "industryType",
  IDENTITY_NO: "identityNo",
  ISD_CODE: "isdCode",
  GENDER: "gender",
  DOB: "dob",
  RECIEVE_MESSAGE: "receiveMessage",
  RECIEVED_MESSAGE: "receivedMessage",
  PREF_COMMUNICATION_MODE: "prefCommuniCationMode",
  ITEM_CODE: "itemCode",
  FABRIC_CODE: "fabricCode",
  SIZE: "size",
  COLOR: "color",
  QTY: "qty",
  SITE_CODE: "siteCode",
  D_NO: "dNo",
  SR_NO: "srNo",
  REMARK: "remark",
  CUS: "cus",
  DOCUMENT_NO: "documentNo",
  TYPE: "type",
  DESIGN: "design",
  SO_NO: "soNo",
  SO_DATE: "soDate",
  STORE_DELIVERY_DATE: "deliveryStDate",
  ORDER_STATUS: "orderStatus",
  SALES_PERSON: "salesPerson",
};
