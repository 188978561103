import { API_URL } from "../../shared/constants";
import { emptySplitApi } from "../emptySplit-api";

export const orderApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    orderList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.ORDERS}/list`,
          method: "post",
          body: data,
        };
      },
    }),
    orderGet: builder.query({
      query: (id) => ({
        url: `/${API_URL.ORDERS}/get/${id}`,
        method: "post",
      }),
    }),
    orderUpdate: builder.mutation<any, any>({
      query: ({ id, data }) => {
        return {
          url: `/${API_URL.ORDERS}/update/${id}`,
          method: "post",
          body: data,
        };
      },
    }),
    orderConfirm: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `/${API_URL.ORDERS}/confirm/${id}`,
          method: "post",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useOrderListQuery,
  useOrderGetQuery,
  useOrderUpdateMutation,
  useOrderConfirmMutation,
} = orderApi;
