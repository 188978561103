import { useEffect, useMemo, useRef, useState } from "react";
import Navbar from "../../Navbar/NavbarComponent";
import CustomModal from "../../../shared/components/CustomModal";
import CustomerDetail from "./CustomerDetail";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  MESSAGE,
  SEARCH_DATA,
  toastError,
  toastSuccess,
} from "../../../shared/constants";
import Table from "../../../shared/components/Table";
import Search from "../../../shared/components/Search";
import { listCheck } from "../../../shared/helperFunc/listCheck";
import { useDeleteModal } from "../../../shared/utils/hooks/useDeleteModal";
import {
  useCustomerGetQuery,
  useCustomerImportMutation,
  useCustomerListQuery,
  useCustomerUpdateMutation,
  useCustomerCreateMutation,
  useCustomerDeleteMutation,
} from "../../../Features/customer/customer-slice";
import { skipToken } from "@reduxjs/toolkit/query";
import AddCustomer from "./AddCustomer";
import { customerValidation } from "../../../shared/validation/customer-validation";
import authProvider from "../../../shared/config/authProvider";
import SimpleButton from "../../../shared/components/Button/Button";
import { CSVLink } from "react-csv";
import {
  dateConvert,
  standardDateConvert,
} from "../../../shared/helperFunc/dateConvert";
import { useFilter } from "../../../shared/utils/hooks/useFilter";
import Filter from "../../Common/Filter/Filter";

const Customer = () => {
  const [sort, setSort] = useState(SEARCH_DATA);
  const [search, setSearch] = useState(false);
  const [count, setCount] = useState();
  const [multiDelete, setMultiDelete] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [customerId, setCustomerId]: any = useState();
  const [customerDetailData, setCustomerDetailData]: any = useState();
  const iconInput = useRef<any>(null);
  const [recievedMessage, setRecievedMessage] = useState(false);

  const [addCustomerData, setAddCustomerData]: any = useState({
    salutation: undefined,
    firstName: "",
    middleName: "",
    lastName: "",
    address1: undefined,
    address2: undefined,
    city: undefined,
    state: undefined,
    district: undefined,
    pin: undefined,
    // mobileNo: undefined,
    remarks: undefined,
    // gstStateName: undefined,
    // gstin: undefined,
    mobile: "",
    isdCode: "",
    gender: undefined,
    dob: null,
    receiveMessage: "N",
    siteCode: undefined,
    prefCommuniCationMode: undefined,
  });
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [errors, setErrors] = useState({});
  const [handleFilter] = useFilter(sort, setSort);
  const [showFilter, setShowFilter] = useState(false);
  const [filterCalled, setFilterCalled]: any = useState(false);
  const [filterSelectionError, setFilterSelectionError]: any = useState();
  const [filterValues, setFilterValues] = useState<any>({});

  const [
    showModal,
    deletedName,
    deleteId,
    deleteShowModal,
    deleteCloseModal,
    deleteShowModals,
  ] = useDeleteModal();

  const {
    data,
    isSuccess,
    error,
    refetch: customerRefetch,
    isFetching: customerListFetching,
  } = useCustomerListQuery(sort, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [
    customerCreate,
    { isLoading: customerCreateLoading, isSuccess: customerCreateSuccess },
  ] = useCustomerCreateMutation();

  const [
    customerUpdate,
    { isLoading: customerUpdateLoading, isSuccess: customerUpdateSuccess },
  ] = useCustomerUpdateMutation();

  const [
    customerDelete,
    { isSuccess: customerDeleteSuccess, isLoading: customerDeleteLoading },
  ] = useCustomerDeleteMutation();

  const {
    data: getCustomerData,
    isLoading: getCustomerLoading,
    isSuccess: getCustomerSuccess,
  } = useCustomerGetQuery(customerId ? customerId : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [
    customerImport,
    { isSuccess: customerImportSuccess, isLoading: customerImportLoading },
  ] = useCustomerImportMutation();

  useEffect(() => {
    customerRefetch();
  }, [filterCalled]);

  useEffect(() => {
    if (customerId !== undefined && getCustomerSuccess) {
      setCustomerDetailData(getCustomerData.item);
    }
  }, [customerId, getCustomerData, getCustomerSuccess]);

  useEffect(() => {
    if (isSuccess) {
      setCustomerList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  const customerAddFlow = async (data: any) => {
    toastSuccess(data.message.messageText || data.message);
    setCustomerId("");
    setEditMode(false);
    setErrors({});
    setShowCustomerModal(false);
    setAddCustomerData({});
    customerRefetch();
  };

  const customerUpdateFlow = async (data: any) => {
    toastSuccess(data.message.messageText || data.message);
    setCustomerId("");
    setEditMode(false);
    setErrors({});
    setShowCustomerModal(false);
    setAddCustomerData({});
    customerRefetch();
  };

  const customerDeleteFlow = async (data: any) => {
    toastSuccess(data.message);
    deleteCloseModal();
    deleteShowModal([]);
    removeMultiDelete();
    setShowCustomerModal(false);
    customerRefetch();
  };

  const customerImportFlow = (data: any) => {
    customerRefetch();
    toastSuccess(data.message);
  };

  const handleCallFilter = () => {
    // if (Object.keys(sort.filter).length > 0) {
    handleFilter();
    setFilterCalled(!filterCalled);
    setSort({ ...sort, filter: filterValues, filterFlag: true, skip: 0 });
    setShowFilter(!showFilter);
    // } else {
    //   setFilterSelectionError("*Please select atlease one filter.");
    // }
  };

  const resetFilter = () => {
    setFilterValues({});
    setSort({ ...sort, filter: {}, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setSort({ ...sort, filter: {}, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(false);
  };

  const handleCustomerModal = () => {
    setShowCustomerModal(true);
  };

  const closeCustomerModal = () => {
    setShowCustomerModal(false);
    setCustomerId("");
    setAddCustomerData({});
    setEditMode(false);
    setErrors({});
  };

  const handleOnChange = (e: any) => {
    // setAddCustomerData({ ...addCustomerData, [e.target.name]: e.target.value });
    setAddCustomerData({
      ...addCustomerData,
      [e.target.name]:
        e.target.name === "siteCode" ? +e.target.value : e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setAddCustomerData({
      ...addCustomerData,
      [e.target.name]: trimmedValue,
    });
  };

  const handleDateChange = (value: any) => {
    setAddCustomerData({ ...addCustomerData, dob: value });
    setErrors({ ...errors, dob: "" });
  };

  const handleReceiveMessage = () => {
    setRecievedMessage(!recievedMessage);
    setAddCustomerData({
      ...addCustomerData,
      receiveMessage: !recievedMessage ? "Y" : "N",
    });
  };

  const handleClose = () => {
    setShowDetail(false);
  };

  const handleSearch = () => {
    setSearch(!search);
    setSort({ ...sort, skip: 0 });
  };

  const handleSearchTextChange = (e: any) => {
    setSort({ ...sort, [e.target.name]: e.target.value, skip: 0 });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const customerModalButton = [
    {
      text: BUTTON_LABEL.CANCEL,
      action: handleClose,
      className: "btn btn theme-btn",
    },
  ];

  const handleBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setFilterValues({
      ...filterValues,
      [e.target.name]: trimmedValue,
    });
  };

  const handleTextChange = (e: any) => {
    setFilterValues({
      ...filterValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (item: any, e: any) => {
    let selectedValue = item?.value;

    if (selectedValue) {
      setFilterValues({
        ...filterValues,
        [e.target.name]: selectedValue,
      });
    } else {
      let updatedFilter: any = { ...filterValues };
      delete updatedFilter[e.name];
      setFilterValues(updatedFilter);
    }
    setFilterSelectionError("");
  };

  const handleDate = (dates: any) => {
    const [start, end] = dates;

    const fromDate = start ? new Date(start).toISOString() : null;
    const toDate = end ? new Date(end).toISOString() : null;

    setFilterValues({
      ...filterValues,
      createdAt: { from: fromDate, to: toDate },
    });
  };

  const filterContent = (
    handleTextChange: any,
    handleSelect: any,
    handleDate: any
  ) => {
    return [
      {
        label: LABELS.CITY_TEXT,
        name: ACTION_LABEL.CITY,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.STATE_TEXT,
        name: ACTION_LABEL.STATE,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.GENDER_TEXT,
        name: ACTION_LABEL.GENDER,
        elementType: "select",
        action: handleSelect,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.CREATED_AT,
        name: ACTION_LABEL.CREATED_AT,
        elementType: "createdAt",
        action: handleDate,
        type: "",
        operator: "is from",
      },
    ];
  };

  const columns = useMemo(
    () => [
      {
        header: LABELS.SALUTATION_TEXT,
        accessorKey: ACTION_LABEL.SALUTATION,
        isShow: true,
      },
      {
        header: LABELS.FIRSTNAME_TEXT,
        accessorKey: ACTION_LABEL.FIRSTNAME,
        isShow: true,
      },
      {
        header: LABELS.MIDDLE_NAME_TEXT,
        accessorKey: ACTION_LABEL.MIDDLENAME,
        isShow: true,
      },
      {
        header: LABELS.LASTNAME_TEXT,
        accessorKey: ACTION_LABEL.LASTNAME,
        isShow: true,
      },
      {
        header: `${LABELS.ADDRESS_TEXT} 1`,
        accessorKey: `${ACTION_LABEL.ADDRESS}1`,
        isShow: true,
      },
      {
        header: `${LABELS.ADDRESS_TEXT} 2`,
        accessorKey: `${ACTION_LABEL.ADDRESS}2`,
        isShow: false,
      },
      {
        header: LABELS.CITY_TEXT,
        accessorKey: ACTION_LABEL.CITY,
        isShow: true,
      },
      {
        header: LABELS.STATE_TEXT,
        accessorKey: ACTION_LABEL.STATE,
        isShow: true,
      },
      {
        header: LABELS.DISTRICT_TEXT,
        accessorKey: ACTION_LABEL.DISTRICT,
        isShow: false,
      },
      {
        header: LABELS.PIN_TEXT,
        accessorKey: ACTION_LABEL.PIN,
        isShow: false,
      },
      // {
      //   header: LABELS.EMAIL_TEXT,
      //   accessorKey: ACTION_LABEL.EMAIL,
      //   isShow: false,
      // },
      {
        header: LABELS.MOBILE_NO_TEXT,
        accessorKey: ACTION_LABEL.MOBILE,
        // accessorKey: ACTION_LABEL.MOBILE_NO,
        isShow: false,
      },
      {
        header: LABELS.REMARKS_TEXT,
        accessorKey: ACTION_LABEL.REMARKS,
        isShow: false,
      },
      // {
      //   header: LABELS.GST_STATE_TEXT,
      //   accessorKey: ACTION_LABEL.GST_STATE_NAME,
      //   isShow: false,
      // },
      // {
      //   header: LABELS.GST_IN_TEXT,
      //   accessorKey: ACTION_LABEL.GST_IN,
      //   isShow: false,
      // },
      {
        header: LABELS.ISD_CODE_TEXT,
        accessorKey: ACTION_LABEL.ISD_CODE,
        isShow: false,
      },
      {
        header: LABELS.GENDER_TEXT,
        accessorKey: ACTION_LABEL.GENDER,
        isShow: false,
      },
      {
        header: LABELS.DOB_TEXT,
        accessorKey: ACTION_LABEL.DOB,
        cell: ({ row }: any) =>
          standardDateConvert(row.original[ACTION_LABEL.DOB]),
        isShow: false,
      },
      {
        header: LABELS.RECIEVE_MESSAGE_TEXT,
        accessorKey: ACTION_LABEL.RECIEVE_MESSAGE,
        isShow: false,
      },
      {
        header: LABELS.PREF_COMMUNICATION_MODE_TEXT,
        accessorKey: ACTION_LABEL.PREF_COMMUNICATION_MODE,
        isShow: false,
      },
      {
        header: LABELS.SITE_CODE_TEXT,
        accessorKey: ACTION_LABEL.SITE_CODE,
        isShow: false,
      },
    ],
    [sort]
  );

  const setCheckBox = (flag: any) => {
    let updated = listCheck(JSON.parse(JSON.stringify(customerList)), flag);
    return updated;
  };

  const checkSelectedData = (data: any) => {
    return data.filter((ele: any) => {
      return ele.isSelected;
    });
  };

  const checked = (e: any, index: any) => {
    setMultiDelete(true);
    let data = JSON.parse(JSON.stringify(customerList));

    if (e.target.checked) {
      data[index].isSelected = true;
    } else {
      data[index].isSelected = false;
    }
    setCustomerList(data);
    const visibleName = checkSelectedData(data);
    deleteShowModal(visibleName);
  };

  const removeMultiDelete = () => {
    let unSelected = setCheckBox(false);
    setCustomerList(unSelected);
    const visibleName = checkSelectedData(unSelected);
    deleteShowModal(visibleName);
  };

  const actionMultiDelete = () => {
    deleteShowModals();
    const visibleName = checkSelectedData(customerList);
    deleteShowModal(visibleName);
  };

  const allCheck = (e: any) => {
    setMultiDelete(true);
    let data = [];
    data = setCheckBox(e.target.checked);
    const visibleName = checkSelectedData(data);
    deleteShowModal(visibleName);

    setCustomerList(data);
  };

  const handleRef = () => {
    iconInput.current.click();
  };

  const handleCsvImport = async (e: any) => {
    let formData = new FormData();
    formData.append("csv", e.target.files[0], e.target.files[0].name);
    const customerImportResponse = await customerImport(formData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (customerImportResponse && customerImportResponse?.status === true) {
      customerImportFlow(customerImportResponse);
    }
  };

  const csvData = [
    {
      SALUTATION: "",
      FIRST_NAME: "Keyan",
      MIDDLE_NAME: "Shaktibhai",
      LAST_NAME: "Darji",
      ADDRESS1: "",
      ADDRESS2: "",
      CITY: "",
      STATE: "",
      DISTRICT: "",
      PIN: "",
      MOBILE: "9276665522",
      REMARKS: "",
      // GST_STATE_NAME: "",
      // GST_IN: "",
      ISD_CODE: "+91",
      GENDER: "M",
      DOB: "17/10/2024",
      RECIEVE_MESSAGE: "Y",
      PREF_COMMUNICATION_MODE: "S",
      SITE_CODE: 720,
    },
    {
      SALUTATION: "",
      FIRST_NAME: "Jaksh",
      MIDDLE_NAME: "Aksh",
      LAST_NAME: "Modi",
      ADDRESS1: "",
      ADDRESS2: "",
      CITY: "",
      STATE: "",
      DISTRICT: "",
      PIN: "",
      MOBILE: "9872287522",
      REMARKS: "",
      // GST_STATE_NAME: "",
      // GST_IN: "",
      ISD_CODE: "+91",
      GENDER: "M",
      DOB: "17/10/2024",
      RECIEVE_MESSAGE: "Y",
      PREF_COMMUNICATION_MODE: "S",
      SITE_CODE: 720,
    },
    {
      SALUTATION: "",
      FIRST_NAME: "Kantibhai",
      MIDDLE_NAME: "Dhirjibhai",
      LAST_NAME: "Mistry",
      ADDRESS1: "",
      ADDRESS2: "",
      CITY: "",
      STATE: "",
      DISTRICT: "",
      PIN: "",
      MOBILE: "9825378522",
      REMARKS: "",
      // GST_STATE_NAME: "",
      // GST_IN: "",
      ISD_CODE: "+91",
      GENDER: "M",
      DOB: "17/10/2024",
      RECIEVE_MESSAGE: "N",
      PREF_COMMUNICATION_MODE: "S",
      SITE_CODE: 201,
    },
    {
      SALUTATION: "",
      FIRST_NAME: "Jay",
      MIDDLE_NAME: "Kanubhai ",
      LAST_NAME: "Darji",
      ADDRESS1: "",
      ADDRESS2: "",
      CITY: "",
      STATE: "",
      DISTRICT: "",
      PIN: "",
      MOBILE: "9115375133",
      REMARKS: "",
      // GST_STATE_NAME: "",
      // GST_IN: "",
      ISD_CODE: "+91",
      GENDER: "M",
      DOB: "17/10/2024",
      RECIEVE_MESSAGE: "N",
      PREF_COMMUNICATION_MODE: "S",
      SITE_CODE: 4,
    },
  ];

  const handleView = (row: any) => {
    setCustomerId(row._id);
    setShowDetail(true);
  };

  const handleSave = async () => {
    const { formErrors, formValid } = customerValidation(addCustomerData);
    if (formValid) {
      if (editMode) {
        const customerUpdateResponse = await customerUpdate({
          id: customerId,
          data: addCustomerData,
        })
          .unwrap()
          .catch((err: any) => {
            toastError(
              err.data?.errors[0]?.msg || err.data?.errors[0]?.msg.messageText
            );
          });
        if (customerUpdateResponse && customerUpdateResponse?.status === true) {
          customerUpdateFlow(customerUpdateResponse);
        }
      } else {
        const customerAddResponse = await customerCreate(addCustomerData)
          .unwrap()
          .catch((err: any) => {
            toastError(
              err.data?.errors[0]?.msg || err.data?.errors[0]?.msg.messageText
            );
          });
        if (customerAddResponse && customerAddResponse?.status === true) {
          customerAddFlow(customerAddResponse);
        }
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleEdit = (row: any) => {
    setCustomerId(row._id);
    setShowCustomerModal(true);
  };
  const handleShowModal = (row: any) => (
    deleteShowModals(true), deleteShowModal([row])
  );

  const addCustomerModalButton = [
    {
      text: BUTTON_LABEL.SAVE,
      action: handleSave,
      className: "btn theme-btn",
      isLoading: editMode ? customerUpdateLoading : customerCreateLoading,
    },
    {
      text: BUTTON_LABEL.CANCEL,
      action: closeCustomerModal,
      className: "btn btn theme-btn",
    },
  ];

  const tableAction = (
    handleView: any,
    handleEdit: any,
    handleShowModal: any
  ) => {
    return [
      {
        elementType: "button",
        text: BUTTON_LABEL.VIEW,
        className: `${`icon ${icons.ICON_VIEW}`} mx-1 `,
        icon: `icon ${icons.ICON_VIEW}`,
        action: handleView,
        access: authProvider.show("customer", "view"),
      },
      {
        elementType: "button",
        text: BUTTON_LABEL.EDIT,
        className: `${`icon ${icons.ICON_EDIT}`} mx-1 `,
        // icon: "",
        action: handleEdit,
        access: authProvider.show("customer", "edit"),
      },
      // {
      //   elementType: "button",
      //   text: BUTTON_LABEL.DELETE,
      //   className: `${`icon ${icons.ICON_DELETE}`} mx-1 `,
      //   // icon: "",
      //   action: handleShowModal,
      // },
    ];
  };

  const action = tableAction(handleView, handleEdit, handleShowModal);

  const deleteModal = (handleDelete: any, closeModal: any, isLoading: any) => {
    return [
      {
        text: BUTTON_LABEL.YES,
        action: handleDelete,
        className: "btn theme-btn theme-btn-sm w-auto",
        isLoading: isLoading,
      },
      {
        text: BUTTON_LABEL.NO,
        action: closeModal,
        className: "btn theme-btn theme-btn-sm btn-inverse w-auto",
      },
    ];
  };

  const handleDelete = async () => {
    const customerDeleteResponse = await customerDelete({ _id: deleteId })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (customerDeleteResponse && customerDeleteResponse?.status === true) {
      customerDeleteFlow(customerDeleteResponse);
    }
  };

  // const closeModal = () => {
  //   deleteCloseModal();
  //   deleteShowModal([]);
  //   removeMultiDelete();
  // };

  // const deleteModalButton = deleteModal(
  //   handleDelete,
  //   closeModal,
  //   customerDeleteLoading
  // );

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.CUSTOMER_MANAGEMENT_TEXT} />
        {/* <Sidebar /> */}
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-12 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.q}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    />

                    {authProvider.show("customer", "edit") ? (
                      <>
                        <SimpleButton
                          className={
                            "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                          }
                          onClick={handleCustomerModal}
                          btnIcon={icons.ICON_ADD}
                          text={BUTTON_LABEL.ADD}
                        />

                        <SimpleButton
                          datatoggle="tooltip"
                          dataplacement="top"
                          title={BUTTON_LABEL.IMPORT}
                          className={
                            "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                          }
                          onClick={handleRef}
                          text={BUTTON_LABEL.IMPORT}
                        >
                          <input
                            type="file"
                            style={{ display: "none" }}
                            ref={iconInput}
                            onChange={handleCsvImport}
                            accept=".csv"
                            onClick={(e: any) => {
                              e.target.value = null;
                            }}
                            hidden
                          />
                        </SimpleButton>
                        <CSVLink
                          data={csvData}
                          filename={"customer.csv"}
                          className="w-auto px-0"
                        >
                          <SimpleButton
                            datatoggle="tooltip"
                            dataplacement="top"
                            title={BUTTON_LABEL.SAMPLE_CSV}
                            className={
                              "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                            }
                            text={BUTTON_LABEL.SAMPLE_CSV}
                          />
                        </CSVLink>

                        {/* <SimpleButton
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      }
                      // onClick={handleCsvExport}
                      text={BUTTON_LABEL.EXPORT}
                    /> */}
                      </>
                    ) : (
                      ""
                    )}

                    <SimpleButton
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse filter-btn"
                      }
                      btnIcon={icons.ICON_FILTER}
                      btnIconClass={"me-2 d-inline-block"}
                      onClick={handleCallFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={customerList}
                    columns={columns}
                    select={false}
                    action={action}
                    sort={sort}
                    setSort={setSort}
                    allCheck={allCheck}
                    allCheckedCheckbox={deleteId.length === customerList.length}
                    checked={checked}
                    coloumnDrop={true}
                    count={count}
                    isFetching={customerListFetching}
                    deleteId={deleteId}
                    removeMultiDelete={removeMultiDelete}
                    actionMultiDelete={actionMultiDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* <CustomModal
        show={showModal}
        close={closeModal}
        message={`${MESSAGE.DELETE_MESSAGE} ${deletedName} Customer?`}
        size="md"
        modalTitle={""}
        modalButton={deleteModalButton}
        children={undefined}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
        // modalTitleIcon={icons.ICON_DELETE_V2}
      /> */}

      <CustomModal
        show={showCustomerModal}
        close={closeCustomerModal}
        size={"lg"}
        message={""}
        modalTitle={
          editMode ? LABELS.EDIT_CUSTOMER_TEXT : LABELS.ADD_CUSTOMER_TEXT
        }
        modalButton={addCustomerModalButton}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
      >
        <AddCustomer
          customerId={customerId}
          editMode={editMode}
          setEditMode={setEditMode}
          recievedMessage={recievedMessage}
          setRecievedMessage={setRecievedMessage}
          addCustomerData={addCustomerData}
          setAddCustomerData={setAddCustomerData}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          handleDateChange={handleDateChange}
          handleReceiveMessage={handleReceiveMessage}
          errors={errors}
        />
      </CustomModal>

      <CustomModal
        show={showDetail}
        close={handleClose}
        size={"lg"}
        message={""}
        modalTitle={LABELS.CUSTOMER_DETAILS_TEXT}
        modalButton={customerModalButton}
        fullscreen={undefined}
        className={"light-modal inventory-detail-modal"}
        centerClass={""}
        modalTitleIcon={""}
      >
        <CustomerDetail
          customerDetailData={customerDetailData}
          getCustomerLoading={getCustomerLoading}
        />
      </CustomModal>

      <Filter
        sort={filterValues}
        filterSelectionError={filterSelectionError}
        filterObject={filterContent(handleTextChange, handleSelect, handleDate)}
        show={showFilter}
        onClose={closeFilter}
        handleReset={resetFilter}
        handleApply={handleCallFilter}
        handleOnBlur={handleBlur}
      />
    </>
  );

  // return (
  //   <>
  //     <div className="full-height">
  //       <Navbar title={LABELS.CUSTOMER_MANAGEMENT_TEXT} />
  //       <main className="main-content">
  //         <div className="pt-3">
  //           <div className="container-fluid">
  //             <div className="row table-top-actions">
  //               <div className="col-12 col-md-4 d-flex align-items-center">
  //                 <a
  //                   href="#"
  //                   className="me-2 btn theme-btn theme-btn-sm btn-inverse"
  //                 >
  //                   <i
  //                     className={`icon ${icons.ICON_FILTER} me-2 d-inline-block`}
  //                   ></i>
  //                   Filter
  //                 </a>
  //               </div>
  //               <div className="col-12 col-md-8 d-flex justify-content-end">
  //                 <div className="d-flex align-items-center row w-100 mx-m-0">
  //                   <div className="col-12 col-md-7 ms-auto">
  //                     <div className="form-group mb-0 search-input position-relative ">
  //                       <div className="icon">
  //                         <i className={`icon ${icons.ICON_SEARCH} `}></i>
  //                       </div>
  //                       <input
  //                         type="text"
  //                         className="form-control bg-white"
  //                         placeholder="Search"
  //                       />
  //                     </div>
  //                   </div>
  //                   <a
  //                     href="#"
  //                     onClick={handleShow}
  //                     className="me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
  //                   >
  //                     Export
  //                   </a>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="card custom-card card-full mt-3">
  //               <div className="card-body p-0">
  //                 <div className="row">
  //                   <div className="col-12">
  //                     <div className="table-responsive">
  //                       {/* d-none */}
  //                       <div className="sticky-action">
  //                         <span>10 items selected</span>
  //                         <i className={`icon ${icons.ICON_CLOSE}`}></i>
  //                         <i className={`icon ${icons.ICON_DELETE}`}></i>
  //                       </div>
  //                       <table className="table table-header-shadow cell-1-small mb-0">
  //                         <thead>
  //                           <tr>
  //                             <th>
  //                               <div className="custom-checkbox">
  //                                 <input
  //                                   className="custom-control-input"
  //                                   type="checkbox"
  //                                   id="inlineCheckbox1"
  //                                   value="option1"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="inlineCheckbox1"
  //                                 ></label>
  //                               </div>
  //                             </th>
  //                             <th>Salutation</th>
  //                             <th>First Name</th>
  //                             <th>Middle Name</th>
  //                             <th>Last Name</th>
  //                             <th>Address</th>
  //                             <th>City</th>
  //                             <th>State</th>
  //                             <th>
  //                               <div className="dropdown cell-dropdown"></div>
  //                               <Dropdown className="dropdown cell-dropdown float-end">
  //                                 <Dropdown.Toggle className="no-btn">
  //                                   <a href="#">
  //                                     <svg
  //                                       xmlns="http://www.w3.org/2000/svg"
  //                                       width="18"
  //                                       height="18.015"
  //                                       viewBox="0 0 18 18.015"
  //                                     >
  //                                       <g
  //                                         id="Group_11176"
  //                                         data-name="Group 11176"
  //                                         transform="translate(-1297.437 -289.429)"
  //                                       >
  //                                         <g
  //                                           id="Rectangle_19126"
  //                                           data-name="Rectangle 19126"
  //                                           transform="translate(1297.437 289.437)"
  //                                           fill="#fff"
  //                                           stroke="#707070"
  //                                           stroke-width="1"
  //                                         >
  //                                           <rect
  //                                             width="18"
  //                                             height="18"
  //                                             stroke="none"
  //                                           ></rect>
  //                                           <rect
  //                                             x="0.5"
  //                                             y="0.5"
  //                                             width="17"
  //                                             height="17"
  //                                             fill="none"
  //                                           ></rect>
  //                                         </g>
  //                                         <line
  //                                           id="Line_75"
  //                                           data-name="Line 75"
  //                                           y2="18.015"
  //                                           transform="translate(1303.434 289.429)"
  //                                           fill="none"
  //                                           stroke="#707070"
  //                                           stroke-width="1"
  //                                         ></line>
  //                                         <line
  //                                           id="Line_76"
  //                                           data-name="Line 76"
  //                                           y2="18.015"
  //                                           transform="translate(1309.439 289.429)"
  //                                           fill="none"
  //                                           stroke="#707070"
  //                                           stroke-width="1"
  //                                         ></line>
  //                                       </g>
  //                                     </svg>
  //                                   </a>
  //                                 </Dropdown.Toggle>
  //                                 <Dropdown.Menu className="dropdown-menu has-checkbox">
  //                                   <li>
  //                                     <div className="dropdown-item">
  //                                       <div className="custom-checkbox">
  //                                         <input
  //                                           className="custom-control-input"
  //                                           type="checkbox"
  //                                           id="cat1"
  //                                           value="option1"
  //                                         />
  //                                         <label
  //                                           className="custom-control-label"
  //                                           htmlFor="cat1"
  //                                         >
  //                                           Category 1
  //                                         </label>
  //                                       </div>
  //                                     </div>
  //                                   </li>
  //                                   <li>
  //                                     <div className="dropdown-item">
  //                                       <div className="custom-checkbox">
  //                                         <input
  //                                           className="custom-control-input"
  //                                           type="checkbox"
  //                                           id="cat2"
  //                                           value="option1"
  //                                         />
  //                                         <label
  //                                           className="custom-control-label"
  //                                           htmlFor="cat2"
  //                                         >
  //                                           Category 2
  //                                         </label>
  //                                       </div>
  //                                     </div>
  //                                   </li>
  //                                   <li>
  //                                     <div className="dropdown-item">
  //                                       <div className="custom-checkbox">
  //                                         <input
  //                                           className="custom-control-input"
  //                                           type="checkbox"
  //                                           id="cat3"
  //                                           value="option1"
  //                                         />
  //                                         <label
  //                                           className="custom-control-label"
  //                                           htmlFor="cat3"
  //                                         >
  //                                           Category 3
  //                                         </label>
  //                                       </div>
  //                                     </div>
  //                                   </li>
  //                                   <li>
  //                                     <div className="dropdown-item">
  //                                       <div className="custom-checkbox">
  //                                         <input
  //                                           className="custom-control-input"
  //                                           type="checkbox"
  //                                           id="cat4"
  //                                           value="option1"
  //                                         />
  //                                         <label
  //                                           className="custom-control-label"
  //                                           htmlFor="cat4"
  //                                         >
  //                                           Category 4
  //                                         </label>
  //                                       </div>
  //                                     </div>
  //                                   </li>
  //                                   <li>
  //                                     <div className="dropdown-item">
  //                                       <div className="custom-checkbox">
  //                                         <input
  //                                           className="custom-control-input"
  //                                           type="checkbox"
  //                                           id="cat5"
  //                                           value="option1"
  //                                         />
  //                                         <label
  //                                           className="custom-control-label"
  //                                           htmlFor="cat5"
  //                                         >
  //                                           Category 5
  //                                         </label>
  //                                       </div>
  //                                     </div>
  //                                   </li>
  //                                   <li>
  //                                     <div className="dropdown-item">
  //                                       <div className="custom-checkbox">
  //                                         <input
  //                                           className="custom-control-input"
  //                                           type="checkbox"
  //                                           id="cat6"
  //                                           value="option1"
  //                                         />
  //                                         <label
  //                                           className="custom-control-label"
  //                                           htmlFor="cat6"
  //                                         >
  //                                           Category 6
  //                                         </label>
  //                                       </div>
  //                                     </div>
  //                                   </li>
  //                                 </Dropdown.Menu>
  //                               </Dropdown>
  //                             </th>
  //                           </tr>
  //                         </thead>
  //                         <tbody>
  //                           <tr>
  //                             <td>
  //                               <div className="custom-control custom-checkbox d-inline-block">
  //                                 <input
  //                                   type="checkbox"
  //                                   className="custom-control-input"
  //                                   id="check_2"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="check_2"
  //                                 ></label>
  //                               </div>
  //                             </td>
  //                             <td>Mr.</td>
  //                             <td>John</td>
  //                             <td>Cris </td>
  //                             <td>Doe</td>
  //                             <td>San house rydot infotech</td>
  //                             <td>Ahmedabad</td>
  //                             <td>Gujarat</td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                           <tr>
  //                             <td>
  //                               <div className="custom-control custom-checkbox d-inline-block">
  //                                 <input
  //                                   type="checkbox"
  //                                   className="custom-control-input"
  //                                   id="check_2"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="check_2"
  //                                 ></label>
  //                               </div>
  //                             </td>
  //                             <td>Mr.</td>
  //                             <td>John</td>
  //                             <td>Cris </td>
  //                             <td>Doe</td>
  //                             <td>San house rydot infotech</td>
  //                             <td>Ahmedabad</td>
  //                             <td>Gujarat</td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                           <tr>
  //                             <td>
  //                               <div className="custom-control custom-checkbox d-inline-block">
  //                                 <input
  //                                   type="checkbox"
  //                                   className="custom-control-input"
  //                                   id="check_2"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="check_2"
  //                                 ></label>
  //                               </div>
  //                             </td>
  //                             <td>Mr.</td>
  //                             <td>John</td>
  //                             <td>Cris </td>
  //                             <td>Doe</td>
  //                             <td>San house rydot infotech</td>
  //                             <td>Ahmedabad</td>
  //                             <td>Gujarat</td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                           <tr>
  //                             <td>
  //                               <div className="custom-control custom-checkbox d-inline-block">
  //                                 <input
  //                                   type="checkbox"
  //                                   className="custom-control-input"
  //                                   id="check_2"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="check_2"
  //                                 ></label>
  //                               </div>
  //                             </td>
  //                             <td>Mr.</td>
  //                             <td>John</td>
  //                             <td>Cris </td>
  //                             <td>Doe</td>
  //                             <td>San house rydot infotech</td>
  //                             <td>Ahmedabad</td>
  //                             <td>Gujarat</td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                           <tr>
  //                             <td>
  //                               <div className="custom-control custom-checkbox d-inline-block">
  //                                 <input
  //                                   type="checkbox"
  //                                   className="custom-control-input"
  //                                   id="check_2"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="check_2"
  //                                 ></label>
  //                               </div>
  //                             </td>
  //                             <td>Mr.</td>
  //                             <td>John</td>
  //                             <td>Cris </td>
  //                             <td>Doe</td>
  //                             <td>San house rydot infotech</td>
  //                             <td>Ahmedabad</td>
  //                             <td>Gujarat</td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                           <tr>
  //                             <td>
  //                               <div className="custom-control custom-checkbox d-inline-block">
  //                                 <input
  //                                   type="checkbox"
  //                                   className="custom-control-input"
  //                                   id="check_2"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="check_2"
  //                                 ></label>
  //                               </div>
  //                             </td>
  //                             <td>Mr.</td>
  //                             <td>John</td>
  //                             <td>Cris </td>
  //                             <td>Doe</td>
  //                             <td>San house rydot infotech</td>
  //                             <td>Ahmedabad</td>
  //                             <td>Gujarat</td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                         </tbody>
  //                       </table>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className="row align-items-center pagination">
  //                   <div className="col-12 col-md-4">
  //                     <div className="form-group mb-0">
  //                       Show
  //                       <select
  //                         name=""
  //                         id=""
  //                         className="form-control w-auto d-inline-block"
  //                       >
  //                         <option value="10">10</option>
  //                         <option value="50">50</option>
  //                         <option value="100">100</option>
  //                       </select>
  //                       &nbsp; Entries
  //                     </div>
  //                   </div>
  //                   <div className="col-12 col-md-4">
  //                     <div className="form-group mb-0 text-center">
  //                       Showing 1 to 10 of 223 entries
  //                     </div>
  //                   </div>
  //                   <div className="col-12 col-md-4">
  //                     <nav>
  //                       <ul className="pagination mb-0 justify-content-end">
  //                         <li className="page-item">
  //                           <a className="page-link" href="#">
  //                             &lt;
  //                           </a>
  //                         </li>
  //                         <li className="page-item active">
  //                           <a className="page-link" href="#">
  //                             1
  //                           </a>
  //                         </li>
  //                         <li className="page-item">
  //                           <a className="page-link" href="#">
  //                             2
  //                           </a>
  //                         </li>
  //                         <li className="page-item">
  //                           <a className="page-link" href="#">
  //                             3
  //                           </a>
  //                         </li>
  //                         <li className="page-item">
  //                           <a className="page-link" href="#">
  //                             &gt;
  //                           </a>
  //                         </li>
  //                       </ul>
  //                     </nav>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </main>
  //     </div>

  //   </>
  // );
};

export default Customer;
