import { useEffect, useState } from "react";
import Navbar from "../../../Navbar/NavbarComponent/Navbar";
import {
  BUTTON_LABEL,
  LABELS,
  URLS,
  DATE_STANDARD_FORMAT,
  ACTION_LABEL,
  toastError,
  toastSuccess,
  icons,
} from "../../../../shared/constants";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useOrderConfirmMutation,
  useOrderGetQuery,
  useOrderUpdateMutation,
  useOrderCancelMutation,
} from "../../../../Features/order/order-slice";
import moment from "moment";
import SimpleButton from "../../../../shared/components/Button/Button";
import Loader from "../../../../shared/components/Loader";
import Select from "../../../../shared/components/Select";
import { useInventorySearchQuery } from "../../../../Features/inventory/inventory-slice";
import TextBox from "../../../../shared/components/TextBox";
import authProvider from "../../../../shared/config/authProvider";
import ButtonLoader from "../../../../shared/components/ButtonLoader";
import { OrderStatus } from "../../../../shared/constants/resource";
import CustomModal from "../../../../shared/components/CustomModal";
import CancelOrder from "./CancelOrder";

const OrderDetail = (props: any) => {
  const {} = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [editOrderDetail, setEditOrderDetail] = useState(false);
  const [orderId, setOrderId]: any = useState(id);
  const [orderDetailData, setOrderDetailData]: any = useState();
  const [showTopMeasurement, setShowTopMeasurement] = useState(false);
  const [measurementTopIndex, setMeasurementTopIndex] = useState();
  const [showBottomMeasurement, setShowBottomMeasurement] = useState(false);
  const [measurementBottomIndex, setMeasurementBottomIndex] = useState();
  const [showCustomMeasurement, setShowCustomMeasurement] = useState(false);
  const [measurementCustomIndex, setMeasurementCustomIndex] = useState();
  const [orderEditData, setOrderEditData]: any = useState({
    itemCode: orderDetailData?.itemCode,
    color: orderDetailData?.color,
    fabricCode: orderDetailData?.fabricCode,
    design: orderDetailData?.design,
    size: orderDetailData?.size,
    qty: orderDetailData?.qty,
  });
  const [orderCancelData, setOrderCancelData]: any = useState({
    remarks: "",
  });
  const [showCancelOrder, setShowCancelOrder] = useState(false);

  const {
    data: getOrderData,
    isLoading: getOrderLoading,
    isSuccess: getOrderSuccess,
    refetch: getOrderRefetch,
  } = useOrderGetQuery(orderId ? orderId : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const { data: inventorySearchData, isFetching: inventoryFetching } =
    useInventorySearchQuery(editOrderDetail ? editOrderDetail : skipToken, {
      refetchOnMountOrArgChange: true,
    });

  const [orderUpdate, { isLoading: orderUpdateLoading }] =
    useOrderUpdateMutation();

  const [orderConfirm, { isLoading: orderConfirmLoading }] =
    useOrderConfirmMutation();

  const [orderCancel, { isLoading: orderCancelLoading }] =
    useOrderCancelMutation();

  useEffect(() => {
    if (orderId !== undefined && getOrderSuccess) {
      setOrderDetailData(getOrderData.item);
    }
  }, [orderId, getOrderData, getOrderSuccess]);

  useEffect(() => {
    if (inventorySearchData) {
      const matchedItem = inventorySearchData.list.find(
        (el: any) => el?.itemCode === orderEditData?.itemCode
      );
      if (matchedItem) {
        // setOrderEditData(matchedItem);
        setOrderEditData((prevData: any) => ({
          ...matchedItem,
          qty: orderDetailData.qty, // Retain the existing qty
        }));
      }
    }
  }, [inventorySearchData, orderEditData?.itemCode]);

  const orderUpdateFlow = async (data: any) => {
    toastSuccess(data.message);
    setEditOrderDetail(false);
    getOrderRefetch();
  };

  const orderConfirmFlow = async (data: any) => {
    toastSuccess(data.message);
    getOrderRefetch();
  };
  const orderCancelFlow = async (data: any) => {
    toastSuccess(data.message);
    getOrderRefetch();
    setShowCancelOrder(false);
  };

  const toggleTopMeasurement = (index: any) => {
    setShowTopMeasurement(!showTopMeasurement);
    setMeasurementTopIndex(index);
  };

  const toggleBottomMeasurement = (index: any) => {
    setShowBottomMeasurement(!showBottomMeasurement);
    setMeasurementBottomIndex(index);
  };

  const toggleCustomMeasurement = (index: any) => {
    setShowCustomMeasurement(!showCustomMeasurement);
    setMeasurementCustomIndex(index);
  };

  const handleCancelOrder = () => {
    setShowCancelOrder(true);
  };

  const closeCancelOrder = () => {
    setShowCancelOrder(false);
  };

  const handleTextChange = (e: any) => {
    setOrderCancelData({ ...orderCancelData, remarks: e.target.value });
  };

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setOrderCancelData({
      ...orderCancelData,
      remarks: trimmedValue,
    });
  };

  const handleSaveCancelOrder = async (id: any) => {
    console.log(`id`, id);
    const orderCancelResponse = await orderCancel({
      id: id,
      data: orderCancelData,
    })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (orderCancelResponse && orderCancelResponse?.status === true) {
      orderCancelFlow(orderCancelResponse);
    }
  };

  const handleEditOrderDetails = () => {
    setEditOrderDetail(true);
  };

  const handleUpdateOrderDetails = async (e: any, id: any) => {
    const orderUpdateResponse = await orderUpdate({
      id: id,
      data: orderEditData,
    })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (orderUpdateResponse && orderUpdateResponse?.status === true) {
      orderUpdateFlow(orderUpdateResponse);
    }
  };

  const handleConfirmOrder = async (e: any, id: any) => {
    const orderConfirmResponse = await orderConfirm(id)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (orderConfirmResponse && orderConfirmResponse?.status === true) {
      orderConfirmFlow(orderConfirmResponse);
    }
  };

  const handleOnChange = (e: any) => {
    setOrderEditData({
      ...orderEditData,
      [e.target.name]: e.target.value,
    });
  };

  const orderCancelModalButton = [
    {
      text: BUTTON_LABEL.SUBMIT,
      action: () => handleSaveCancelOrder(orderId),
      className: "btn btn theme-btn",
    },
    {
      text: BUTTON_LABEL.CANCEL,
      action: closeCancelOrder,
      className: "btn btn theme-btn btn-inverse",
    },
  ];

  const measurementStandardBlock = (item: any) => {
    return (
      <div className="value">
        <label htmlFor="">{item?.title}</label>
        <span className="label-box">{item?.value}</span>
      </div>
    );
  };

  const measurementCustomBlock = (title: any, value: any) => {
    if (value !== "") {
      return (
        <div className="col-md-4">
          <div className="slug-custom-dropdown mb-3 open">
            <div className="toggle d-flex align-items-center w-100 justify-content-between">
              <span>{title}</span>
            </div>

            <div className="custom-dropdown-menu">
              <div className="item-card">
                <div className="item-body">
                  <div className="populate-values">
                    <span>{value}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {!getOrderLoading && orderDetailData !== undefined ? (
        <>
          <div className="full-height">
            <Navbar
              title={`${LABELS.ORDER_INFORMATION_TEXT} - ${orderDetailData.designNo}`}
            />
            <main className="main-content">
              <div className="pt-3">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className={`card custom-card card-full mt-3 has-radius ${
                          editOrderDetail ? "" : "has-input"
                        }`}
                      >
                        <div className="card-body">
                          {authProvider.show("order", "edit") ? (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <p className="font-20 section-title mb-0">
                                {LABELS.ORDER_DETAILS_TEXT}
                              </p>
                              <div>
                                {orderDetailData.status !==
                                  OrderStatus.CANCELED && (
                                  <>
                                    {orderDetailData.status ===
                                      OrderStatus.CONFIRM_IN_PROGRESS &&
                                      (editOrderDetail ? (
                                        <SimpleButton
                                          className={
                                            "btn theme-btn theme-btn-sm btn-inverse me-2"
                                          }
                                          onClick={(e: any) =>
                                            handleUpdateOrderDetails(e, orderId)
                                          }
                                          text={
                                            orderUpdateLoading ? (
                                              <ButtonLoader />
                                            ) : (
                                              BUTTON_LABEL.SAVE_UPDATE
                                            )
                                          }
                                        />
                                      ) : (
                                        <SimpleButton
                                          className={
                                            "btn theme-btn theme-btn-sm me-2"
                                          }
                                          onClick={handleEditOrderDetails}
                                          text={BUTTON_LABEL.ENABLE_EDIT}
                                          disabled={
                                            orderConfirmLoading
                                              ? "disabled"
                                              : ""
                                          }
                                        />
                                      ))}

                                    {(orderDetailData.status ===
                                      OrderStatus.PENDING ||
                                      orderDetailData.status ===
                                        OrderStatus.CONFIRM_IN_PROGRESS) && (
                                      <SimpleButton
                                        className={`btn ${
                                          orderDetailData.status !==
                                          OrderStatus.CONFIRM_IN_PROGRESS
                                            ? "success-btn"
                                            : "info-btn"
                                        } theme-btn theme-btn-sm btn-inverse d-inline-block me-2`}
                                        text={
                                          orderConfirmLoading ? (
                                            <ButtonLoader />
                                          ) : orderDetailData.status !==
                                            OrderStatus.CONFIRM_IN_PROGRESS ? (
                                            BUTTON_LABEL.CONFIRM
                                          ) : (
                                            BUTTON_LABEL.CONFIRM_IN_PROGRESS
                                          )
                                        }
                                        disabled={
                                          editOrderDetail || orderConfirmLoading
                                            ? "disabled"
                                            : ""
                                        }
                                        onClick={(e: any) =>
                                          handleConfirmOrder(e, orderId)
                                        }
                                      />
                                    )}
                                    <SimpleButton
                                      className={
                                        "btn theme-btn theme-btn-sm me-2"
                                      }
                                      onClick={handleCancelOrder}
                                      text={BUTTON_LABEL.CANCEL}
                                      disabled={
                                        orderConfirmLoading ? "disabled" : ""
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="card section-card mb-3">
                            <div className="card-body">
                              {editOrderDetail ? (
                                <div className="col-2">
                                  <div className="form-group">
                                    <Select
                                      data={
                                        !inventoryFetching &&
                                        inventorySearchData
                                          ? inventorySearchData.list
                                          : []
                                      }
                                      label={LABELS.ITEM_CODE_TEXT}
                                      id={LABELS.ITEM_CODE_TEXT}
                                      name={ACTION_LABEL.ITEM_CODE}
                                      value={orderEditData?.itemCode}
                                      onChange={handleOnChange}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <p>
                                  <div className="row align-items-center">
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <strong>
                                            {LABELS.I_CODE_TEXT} -{" "}
                                          </strong>
                                          {orderDetailData.itemCode
                                            ? orderDetailData.itemCode
                                            : " NA"}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <strong>
                                            {LABELS.ORDER_NO_TEXT} -{" "}
                                          </strong>
                                        </div>
                                        <div className="col-md-6">
                                          {orderDetailData.documentNo
                                            ? orderDetailData.documentNo
                                            : " NA"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </p>
                              )}
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.DESIGN_NO_TEXT} -
                                      </label>
                                    </div>
                                    {/* <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.designNo}
                                    </p> */}
                                    <div className="col-md-6">
                                      <TextBox
                                        placeholder={LABELS.DESIGN_NO_TEXT}
                                        name={ACTION_LABEL.DESIGN_NO}
                                        value={
                                          editOrderDetail
                                            ? orderEditData?.designNo
                                            : orderDetailData?.designNo
                                        }
                                        disable
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.ORDER_DATE_TEXT} -
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <p className="mb-0 text-muted">
                                        {moment(
                                          orderDetailData.createdAt
                                        ).format(DATE_STANDARD_FORMAT.REGULAR)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.FABRIC_CODE_TEXT} -
                                      </label>
                                    </div>
                                    {/* <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.fabricCode
                                        ? orderDetailData.fabricCode
                                        : "-"}
                                    </p> */}
                                    <div className="col-md-6">
                                      <TextBox
                                        placeholder={LABELS.FABRIC_CODE_TEXT}
                                        name={ACTION_LABEL.FABRIC_CODE}
                                        value={
                                          editOrderDetail
                                            ? orderEditData?.fabricCode
                                            : orderDetailData?.fabricCode
                                        }
                                        disable
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.DELIVERY_DATE_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {moment(
                                        orderDetailData.deliveryDate
                                      ).format(DATE_STANDARD_FORMAT.REGULAR)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.SIZE_TEXT} -
                                      </label>
                                    </div>
                                    {/* <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.size}
                                    </p> */}
                                    <div className="col-md-6">
                                      <TextBox
                                        placeholder={LABELS.SIZE_TEXT}
                                        name={ACTION_LABEL.SIZE}
                                        value={
                                          editOrderDetail
                                            ? orderEditData?.size
                                            : orderDetailData?.size
                                        }
                                        disable
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.DESTINATION_SITE_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.destinationSiteCode
                                        ? orderDetailData.destinationSiteCode
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.COLOR_TEXT} -
                                      </label>
                                    </div>
                                    {/* <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.color}
                                    </p> */}
                                    <div className="col-md-6">
                                      <TextBox
                                        placeholder={LABELS.COLOR_TEXT}
                                        name={ACTION_LABEL.COLOR}
                                        value={
                                          editOrderDetail
                                            ? orderEditData?.color
                                            : orderDetailData?.color
                                        }
                                        disable
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.PRICE_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.price}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.QTY_TEXT} -
                                      </label>
                                    </div>
                                    {/* <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.qty}
                                    </p> */}
                                    <div className="col-md-6">
                                      <TextBox
                                        placeholder={LABELS.QTY_TEXT}
                                        name={ACTION_LABEL.QTY}
                                        value={
                                          editOrderDetail
                                            ? orderEditData?.qty
                                            : orderDetailData?.qty
                                        }
                                        onChange={handleOnChange}
                                        disable={editOrderDetail ? false : true}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.SO_NO_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.ordCode
                                        ? orderDetailData.ordCode
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.CHECKER_ID_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.checkerId
                                        ? orderDetailData.checkerId
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.REMARKS_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.remarks
                                        ? orderDetailData.remarks
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* ------------------------------------------------------------Conditional Sub Assembly Item Block-----------------------------------------------------------*/}

                              {/* <div className="row">
                                <p>
                                  <strong>
                                    {LABELS.SUB_ASSEMBLY_ITEM_TEXT}
                                  </strong>
                                </p>
                                {orderDetailData?.measurement?.top?.length !==
                                  0 &&
                                  orderDetailData?.measurement?.top?.map(
                                    (topEl: any, index: any) => {
                                      return (
                                        <div className="col-md-4">
                                          <div
                                            className={`slug-custom-dropdown  ${
                                              showTopMeasurement &&
                                              measurementTopIndex === index
                                                ? "open"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              toggleTopMeasurement(index)
                                            }
                                          >
                                            <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                              <span>{topEl.type}</span>
                                              <span>
                                                <i
                                                  className={
                                                    showTopMeasurement &&
                                                    measurementTopIndex ===
                                                      index
                                                      ? icons.ICON_CARET_UP
                                                      : icons.ICON_CARET_DOWN
                                                  }
                                                ></i>
                                              </span>
                                            </div>
                                            {showTopMeasurement &&
                                            measurementTopIndex === index ? (
                                              <div className="custom-dropdown-menu">
                                                <div className="item-card">
                                                  <div className="item-body">
                                                    <div className="populate-values">
                                                      {topEl.data.map(
                                                        (el: any) => {
                                                          return measurementStandardBlock(
                                                            el
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                {orderDetailData?.measurement?.bottom
                                  ?.length !== 0 &&
                                  orderDetailData?.measurement?.bottom?.map(
                                    (bottomEl: any, index: any) => {
                                      return (
                                        <div className="col-md-4">
                                          <div
                                            className={`slug-custom-dropdown ${
                                              showBottomMeasurement &&
                                              measurementBottomIndex === index
                                                ? "open"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              toggleBottomMeasurement(index)
                                            }
                                          >
                                            <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                              <span>{bottomEl.type}</span>
                                              <span>
                                                <i
                                                  className={
                                                    showBottomMeasurement &&
                                                    measurementBottomIndex ===
                                                      index
                                                      ? icons.ICON_CARET_UP
                                                      : icons.ICON_CARET_DOWN
                                                  }
                                                ></i>
                                              </span>
                                            </div>
                                            {showBottomMeasurement &&
                                            measurementBottomIndex === index ? (
                                              <div className="custom-dropdown-menu">
                                                <div className="item-card">
                                                  <div className="item-body">
                                                    <div className="populate-values">
                                                      {bottomEl.data.map(
                                                        (el: any) => {
                                                          return measurementStandardBlock(
                                                            el
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}

                                {Object.keys(
                                  orderDetailData?.measurement?.custom
                                ).map((ele: any, index: any) => {
                                  return measurementCustomBlock(
                                    ele,
                                    orderDetailData?.measurement?.custom[ele],
                                    index
                                  );
                                })}
                              </div> */}
                            </div>
                          </div>

                          {/* ------------------------------------------------------------Open Sub Assembly Item Block-----------------------------------------------------------*/}
                          <h6 className="my-3 section-title">
                            {LABELS.SUB_ASSEMBLY_ITEM_TEXT}
                          </h6>
                          <div className="card section-card mb-3">
                            <div className="card-body">
                              <div className="row">
                                {orderDetailData?.measurement?.top?.length !==
                                  0 &&
                                  orderDetailData?.measurement?.top?.map(
                                    (topEl: any) => {
                                      return (
                                        <div className="col-md-4">
                                          <div className="slug-custom-dropdown mb-3 open">
                                            <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                              <span>{topEl.type}</span>
                                            </div>
                                            <div className="custom-dropdown-menu">
                                              <div className="item-card">
                                                <div className="item-body">
                                                  <div className="populate-values">
                                                    {topEl.data.map(
                                                      (el: any) => {
                                                        return measurementStandardBlock(
                                                          el
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                {orderDetailData?.measurement?.bottom
                                  ?.length !== 0 &&
                                  orderDetailData?.measurement?.bottom?.map(
                                    (bottomEl: any) => {
                                      return (
                                        <div className="col-md-4">
                                          <div className="slug-custom-dropdown mb-3 open">
                                            <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                              <span>{bottomEl.type}</span>
                                            </div>
                                            <div className="custom-dropdown-menu">
                                              <div className="item-card">
                                                <div className="item-body">
                                                  <div className="populate-values">
                                                    {bottomEl.data.map(
                                                      (el: any) => {
                                                        return measurementStandardBlock(
                                                          el
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}

                                {Object.keys(
                                  orderDetailData?.measurement?.custom
                                ).map((ele: any) => {
                                  return measurementCustomBlock(
                                    ele,
                                    orderDetailData?.measurement?.custom[ele]
                                  );
                                })}
                                {/* <div className="col-md-4">
                                  <div className="slug-custom-dropdown">
                                    <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                      <span>Kurta </span>
                                      <span>
                                        <i className="icon-caret-down"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>

                          <h6 className="my-3 section-title">
                            {LABELS.CUSTOMER_INFORMATION_TEXT}
                          </h6>
                          <div className="card section-card mb-3">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-3 col-12">
                                  <div className="form-group mb-0">
                                    <label
                                      htmlFor=""
                                      className="text-black font-weight-600"
                                    >
                                      {LABELS.NAME_TEXT}
                                    </label>
                                    <p className="mb-0 text-muted">
                                      {orderDetailData.firstName +
                                        " " +
                                        orderDetailData.middleName +
                                        " " +
                                        orderDetailData.lastName}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-3 col-12">
                                  <div className="form-group mb-0">
                                    <label
                                      htmlFor=""
                                      className="text-black font-weight-600"
                                    >
                                      {LABELS.PHONE_TEXT}
                                    </label>
                                    <p className="mb-0 text-muted">
                                      {orderDetailData.customerMobile}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-3 col-12">
                                  <div className="form-group mb-0">
                                    <label
                                      htmlFor=""
                                      className="text-black font-weight-600"
                                    >
                                      {LABELS.ADDRESS_TEXT}
                                    </label>
                                    <p className="mb-0 text-muted">
                                      {orderDetailData.address
                                        ? orderDetailData.address
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {orderDetailData.status === OrderStatus.CANCELED ? (
                            <>
                              <h6 className="my-3 section-title">
                                {LABELS.CANCEL_DETAILS_TEXT}
                              </h6>
                              <div className="card section-card mb-3">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-3 col-12">
                                      <div className="form-group mb-0">
                                        <label
                                          htmlFor=""
                                          className="text-black font-weight-600"
                                        >
                                          {LABELS.REMARKS_TEXT}
                                        </label>
                                        <p className="mb-0 text-muted">
                                          {orderDetailData.cancel?.remarks ||
                                            "-"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-12">
                                      <div className="form-group mb-0">
                                        <label
                                          htmlFor=""
                                          className="text-black font-weight-600"
                                        >
                                          {LABELS.CANCEL_CODE_TEXT}
                                        </label>
                                        <p className="mb-0 text-muted">
                                          {orderDetailData.cancel?.code || "-"}
                                        </p>
                                      </div>
                                    </div>
                                    {/* <div className="col-md-3 col-12">
                                  <div className="form-group mb-0">
                                    <label
                                      htmlFor=""
                                      className="text-black font-weight-600"
                                    >
                                      {LABELS>EMAIL.TEXT}
                                    </label>
                                    <p className="mb-0 text-muted">
                                      hemnashum@gmail.com
                                    </p>
                                  </div>
                                </div> */}
                                    <div className="col-md-3 col-12">
                                      <div className="form-group mb-0">
                                        <label
                                          htmlFor=""
                                          className="text-black font-weight-600"
                                        >
                                          {LABELS.CANCEL_NO_TEXT}
                                        </label>
                                        <p className="mb-0 text-muted">
                                          {orderDetailData.cancel?.no || "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          <h6 className="my-3 section-title">
                            {LABELS.TRACKING_INFORMATION_TEXT}
                          </h6>
                          {/* <div className="tracking-status">
                            <div className="item">
                              <div className="item-title">Shervani</div>
                            </div>
                            <div className="track-stepper">
                              <ul className="list-unstyled mb-0">
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status done">
                                      <i className="icon-check"></i>
                                    </div>
                                    <div className="w-100 text-center">
                                      Cutting
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status in-progress">
                                      <i className="icon-refresh"></i>
                                    </div>
                                    <div className="w-100 text-center">
                                      Embrooidry
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status"></div>
                                    <div className="w-100 text-center">
                                      Stitching
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status"></div>
                                    <div className="w-100 text-center">
                                      Finishing
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="slug-custom-dropdown mt-4 d-inline-block">
                              <div className="toggle d-flex align-items-center w-100 justify-content-between primary-bg text-white">
                                <span>Sub Assembly item</span>
                                <span className="ms-3">
                                  <i className="icon-caret-down"></i>
                                </span>
                              </div>
                            </div>
                            <div className="row mt-4 align-items-center assembally-attr">
                              <div className="col-md-3">
                                <label htmlFor="">Pant</label>
                              </div>
                              <div className="col-md-6">
                                <div className="track-stepper">
                                  <ul className="list-unstyled my-0">
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status done">
                                          <i className="icon-check"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Cutting
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status in-progress">
                                          <i className="icon-refresh"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Embrooidry
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status">
                                          <i className="icon"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Stitching
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status">
                                          <i className="icon"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Finishing
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4 align-items-center assembally-attr">
                              <div className="col-md-3">
                                <label htmlFor="">Pant</label>
                              </div>
                              <div className="col-md-6">
                                <div className="track-stepper">
                                  <ul className="list-unstyled my-0">
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status done">
                                          <i className="icon-check"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Cutting
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status in-progress">
                                          <i className="icon-refresh"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Embrooidry
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status">
                                          <i className="icon"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Stitching
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status">
                                          <i className="icon"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Finishing
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {LABELS.NOT_FOUND_TEXT}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </>
      ) : (
        <Loader />
      )}

      <CustomModal
        show={showCancelOrder}
        close={closeCancelOrder}
        size={"lg"}
        message={""}
        modalTitle={LABELS.ITEM_DETAILS_TEXT}
        modalButton={orderCancelModalButton}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
      >
        <CancelOrder
          orderId={orderId}
          orderCancelData={orderCancelData}
          orderCancelLoading={orderCancelLoading}
          handleTextChange={handleTextChange}
          handleBlur={handleOnBlur}
          handleSave={handleSaveCancelOrder}
          handleCancel={closeCancelOrder}
        />
      </CustomModal>
    </>
  );
};

export default OrderDetail;
