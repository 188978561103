import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { STATUS_CODE, toastError } from "../shared/constants";

const unauthorizedFlow = (errorMessage: string) => {
  toastError(errorMessage);
  setTimeout(() => {
    localStorage.clear();
    window.location.pathname = "/login";
  }, 1000);
};

const forbiddenFlow = (errorMessage: string) => {
  toastError(errorMessage);
  setTimeout(() => {
    window.location.pathname = "/dashboard";
  }, 1000);
};

const handleApiError = (error: any) => {
  if (!error) {
    return;
  }

  const status = error.status;
  const message =
    error.data?.message ||
    error.data?.errors[0]?.msg.messageText ||
    error.data?.errors[0]?.msg ||
    "An unexpected error occurred";

  if (status === 401) {
    unauthorizedFlow(message);
  } else if (status === 403) {
    forbiddenFlow(message);
  } else if (STATUS_CODE.includes(status)) {
    toastError(message);
  } else {
    toastError(error.data.errors[0].msg);
  }
};

const baseQueryWithErrorHandling = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL + "/api",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("x-access-token");
      if (token) {
        headers.set("x-access-token", token);
      }
      return headers;
    },
  });

  try {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error) {
      handleApiError(result.error);
    }

    return result;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const emptySplitApi = createApi({
  baseQuery: baseQueryWithErrorHandling,
  endpoints: () => ({}),
});
