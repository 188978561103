const NumberBox = (props: any) => {
  const {
    placeholder,
    required,
    id,
    value,
    name,
    onChange,
    label,
    errorMessage,
    disable,
    className,
    onClick,
    onKeyDown,
    onDoubleClick,
    onFocus,
    min,
    max,
  } = props;
  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      {required && <span className="text-error">*</span>}
      <input
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onKeyDown={onKeyDown}
        type="number"
        className={className ? className : "form-control"}
        placeholder={placeholder}
        name={name}
        value={value}
        disabled={disable || undefined}
        onChange={onChange}
        onFocus={onFocus}
        autoComplete="false"
        min={min ? min : ""}
        max={max ? max : ""}
      />
      <span className="text-error">{errorMessage}</span>
    </>
  );
};

export default NumberBox;
