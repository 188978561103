import { API_URL } from "../../shared/constants";
import { emptySplitApi } from "../emptySplit-api";

export const productApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    productList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.PRODUCTS}/list`,
          method: "post",
          body: data,
        };
      },
    }),
    productGet: builder.query({
      query: (id) => ({
        url: `/${API_URL.PRODUCTS}/get/${id}`,
        method: "post",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useProductListQuery } = productApi;
