import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../Auth/Login";
import DefaultLayout from "../layouts/DefaultLayout";
import ProtectedLayout from "../layouts/ProtectedLayout";
import Dashboard from "../BackOffice/Pages/Dashboard";
import StoreList from "../BackOffice/Pages/Store";
import SetOtp from "../Auth/SetOtp";
import SetPassword from "../Auth/SetPassword";
import Inventory from "../BackOffice/Pages/Inventory";
import User from "../BackOffice/Pages/User";
import StoreCoordinator from "../BackOffice/Pages/StoreCoordinator";
import Role from "../BackOffice/Pages/Role";
import Product from "../BackOffice/Pages/Product";
import Vendor from "../BackOffice/Pages/Vendor";
import Customer from "../BackOffice/Pages/Customer";
import ProductDetail from "../BackOffice/Pages/Product/ProductDetail";
import { AuthProvider } from "../context/AuthContext";
import UserProfile from "../BackOffice/Pages/UserProfile";
import ForgotPassword from "../Auth/ForgotPassword";
import OrderList from "../BackOffice/Pages/Order/OrderList";
import OrderDetail from "../BackOffice/Pages/Order/OrderDetail";
import ReportList from "../BackOffice/Pages/Report";
import AddRole from "../BackOffice/Pages/Role/AddRole";
import { URLS } from "../shared/constants";

const App = () => {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route element={<DefaultLayout />}>
            <Route path={URLS.HOME} element={<Navigate to={URLS.LOGIN} />} />
            <Route path={URLS.LOGIN} element={<Login />} />
            <Route path={URLS.RESET_PASSWORD} element={<ForgotPassword />} />
            <Route path={URLS.SET_OTP} element={<SetOtp />} />
            <Route path={URLS.SET_PASSWORD} element={<SetPassword />} />
          </Route>
          <Route element={<ProtectedLayout />}>
            <Route path={URLS.DASHBOARD} element={<Dashboard />} />
            {/* <Route path={URLS.PRODUCT} element={<Product />} /> */}
            {/* <Route path={URLS.PRODUCT_ADD} element={<ProductDetail />} /> */}
            <Route path={URLS.STORE} element={<StoreList />} />
            <Route path={URLS.INVENTORY} element={<Inventory />} />
            <Route path={URLS.VENDOR} element={<Vendor />} />
            <Route path={URLS.USER} element={<User />} />
            <Route path={URLS.CUSTOMER} element={<Customer />} />
            <Route
              path={URLS.STORE_COORDINATOR}
              element={<StoreCoordinator />}
            />
            <Route path={URLS.ROLES} element={<Role />} />
            <Route path={URLS.ROLES_ADD} element={<AddRole />} />
            <Route path={URLS.REPORT} element={<ReportList />} />
            <Route path={URLS.ORDER} element={<OrderList />} />
            <Route path={URLS.ORDER_DETAIL} element={<OrderDetail />} />
            <Route path={URLS.USER_PROFILE} element={<UserProfile />} />
          </Route>
        </Routes>
      </AuthProvider>
    </>
  );
};

export default App;
