export const URLS: any = {
  HOME: "/",
  LOGIN: "/login",
  RESET_PASSWORD: "/resetpassword",
  SET_PASSWORD: "/setpassword",
  SET_OTP: "/setotp",
  DASHBOARD: "/dashboard",
  USER_PROFILE: "/user-profile",
  PRODUCT: "/product-management",
  PRODUCT_ADD: "/product-management/add",
  STORE: "/store-management",
  INVENTORY: "/inventory-management",
  VENDOR: "/vendor-management",
  USER: "/user-management/users",
  CUSTOMER: "/customer-management",
  STORE_COORDINATOR: "/store-coordinator",
  ROLES: "/user-management/roles",
  ROLES_ADD: "/user-management/roles/create",
  REPORT: "/reports",
  ORDER: "/order-management",
  ORDER_DETAIL: "/order-details/:id",
};

export const API_URL = {
  USERS: "users",
  CUSTOMERS: "customers",
  STORES: "stores",
  INVENTORIES: "inventories",
  ORDERS: "orders",
  PRODUCTS: "products",
  REPORTS: "reports",
  ROLES: "roles",
  VENDORS: "vendors",
  STORE_COORDINATORS: "store-coordinators",
  ACCOUNT: "account",
  AUTH: "auth",
  FORGOT_PASSWORD: "forgot-password",
  DASHBOARD: "dashboards",
  PROFILE: "profile",
  COMMON: "common",
};

export const VALID_URLS: any = [];

export const FULL_PAGE_URLS: any = [];
