import TextBox from "../../../../shared/components/TextBox";
import TextArea from "../../../../shared/components/TextArea";
import Password from "../../../../shared/components/Password";
import Select from "../../../../shared/components/Select";
import { useEffect } from "react";
import { useUsersGetQuery } from "../../../../Features/user/user-slice";
import { skipToken } from "@reduxjs/toolkit/query";
import CheckBox from "../../../../shared/components/CheckBox/CheckBox";
import { ACTION_LABEL, LABELS } from "../../../../shared/constants";
import { useRolesSearchQuery } from "../../../../Features/role/role-slice";
import Loader from "../../../../shared/components/Loader";

const AddUser = (props: any) => {
  const {
    userId,
    editMode,
    setEditMode,
    addUserData,
    setAddUserData,
    resetPassword,
    setResetPassword,
    handleOnChange,
    handleOnBlur,
    handleResetPassword,
    errors,
  } = props;

  // const {
  //   data: storeSearchData,
  //   // isSuccess ,
  //   // error,
  //   isFetching: storeFetching,
  // } = useStoreSearchQuery("");

  const { data: rolesSearchData, isFetching: rolesFetching } =
    useRolesSearchQuery("");

  // const {
  //   data: getUserData,
  //   isLoading: getUserLoading,
  //   isSuccess: getUserSuccess,
  //   refetch: getUserRefetch,
  // } = useUsersGetQuery(userId || skipToken);

  const {
    data: getUserData,
    isLoading: getUserLoading,
    isSuccess: getUserSuccess,
    refetch: getUserRefetch,
  } = useUsersGetQuery(userId ? userId : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (userId !== null && getUserSuccess) {
      setEditMode(true);
      setAddUserData(getUserData.item);
    }
  }, [userId, getUserData, getUserSuccess, setAddUserData]);
  return (
    <>
      {!getUserLoading ? (
        <>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.NAME}
                  placeholder={LABELS.FULLNAME_TEXT}
                  id={LABELS.FULLNAME_TEXT}
                  label={LABELS.FULLNAME_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addUserData.name}
                  errorMessage={errors.name ? errors.name : null}
                  maxLength={40}
                  required
                />
                {/* <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              id="name"
            /> */}
              </div>
            </div>
            {/* <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.LASTNAME}
              placeholder={LABELS.LASTNAME_TEXT}
              id={LABELS.LASTNAME_TEXT}
              label={LABELS.LASTNAME_TEXT}
              onChange={handleOnChange}
              value={addUserData.lastName}
              errorMessage={errors.lastName ? errors.lastName : null}
            />
          </div>
        </div> */}
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.EMAIL}
                  placeholder={LABELS.EMAIL_TEXT}
                  id={LABELS.EMAIL_TEXT}
                  label={LABELS.EMAIL_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addUserData.email}
                  errorMessage={errors.email ? errors.email : null}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.PHONE}
                  placeholder={LABELS.PHONE_TEXT}
                  id={LABELS.PHONE_TEXT}
                  label={LABELS.PHONE_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addUserData.phone}
                  errorMessage={errors.phone ? errors.phone : null}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.DESIGNATION}
                  placeholder={LABELS.DESIGNATION_TEXT}
                  id={LABELS.DESIGNATION_TEXT}
                  label={LABELS.DESIGNATION_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addUserData.designation}
                  errorMessage={errors.designation ? errors.designation : null}
                  required
                />
              </div>
            </div>
            {/* <div className="col-6">
          <div className="form-group">
            <Select
              data={
                !storeFetching && storeSearchData ? storeSearchData.list : []
              }
              label={LABELS.STORE_TEXT}
              id={LABELS.STORE_TEXT}
              name={ACTION_LABEL.STORE}
              value={addUserData.store}
              onChange={handleOnChange}
              errorMessage={errors.store ? errors.store : null}
            />
          </div>
        </div> */}
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.STATE}
                  placeholder={LABELS.STATE_TEXT}
                  id={LABELS.STATE_TEXT}
                  label={LABELS.STATE_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addUserData.state}
                  errorMessage={errors.state ? errors.state : null}
                  required
                />
                {/* <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="hemanshumistry@gmail.com"
              id="email"
            /> */}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.CITY}
                  placeholder={LABELS.CITY_TEXT}
                  id={LABELS.CITY_TEXT}
                  label={LABELS.CITY_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addUserData.city}
                  errorMessage={errors.city ? errors.city : null}
                  required
                />
                {/* <label htmlFor="role">Role</label>
            <select name="role" id="role" className="form-control">
              <option value="Store 1">Store 1</option>
              <option value="Store 2">Store 2</option>
              <option value="Store 3">Store 3</option>
            </select> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <Select
                  data={
                    !rolesFetching && rolesSearchData
                      ? rolesSearchData.list
                      : []
                  }
                  label={LABELS.ROLE_TEXT}
                  id={LABELS.ROLE_TEXT}
                  name={ACTION_LABEL.ROLE}
                  value={addUserData.role}
                  onChange={handleOnChange}
                  errorMessage={errors.role ? errors.role : null}
                  required
                />
                {/* <label htmlFor="phone">Phone No.</label>
            <input
              type="number"
              className="form-control"
              placeholder="85263203569"
              id="phone"
            /> */}
              </div>
            </div>
            {editMode ? (
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    htmlFor={LABELS.RESET_PASSWORD_TEXT}
                    className="d-block mb-2"
                  >
                    &nbsp;
                  </label>
                  <CheckBox
                    label={LABELS.RESET_PASSWORD_TEXT}
                    name={ACTION_LABEL.RESET_PASSWORD}
                    id={LABELS.RESET_PASSWORD_TEXT}
                    value={resetPassword}
                    onChange={handleResetPassword}
                  />
                </div>
              </div>
            ) : (
              <div className="col-6">
                <div className="form-group">
                  <Password
                    placeholder={LABELS.PASSWORD_TEXT}
                    label={LABELS.PASSWORD_TEXT}
                    value={addUserData.password}
                    name={ACTION_LABEL.PASSWORD}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    errorMessage={errors.password ? errors.password : null}
                  />
                  {/* <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="***************"
              id="password"
            /> */}
                </div>
              </div>
            )}

            {resetPassword ? (
              <div className="col-6">
                <div className="form-group">
                  <Password
                    placeholder={LABELS.PASSWORD_TEXT}
                    label={LABELS.PASSWORD_TEXT}
                    value={addUserData.password}
                    name={ACTION_LABEL.PASSWORD}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    errorMessage={errors.password ? errors.password : null}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <TextArea
                  name={ACTION_LABEL.ADDRESS}
                  placeholder={LABELS.ADDRESS_TEXT}
                  id={LABELS.ADDRESS_TEXT}
                  label={LABELS.ADDRESS_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addUserData.address}
                  errorMessage={errors.address ? errors.address : null}
                  required
                />
                {/* <label htmlFor="address">Address</label>
            <textarea
              name="address"
              id="address"
              className="form-control"
              style={{ height: "90px" }}
              defaultValue={
                "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
              }
            /> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AddUser;
