import DatePicker from "react-datepicker";

const DatePickerComponent = (props: any) => {
  const {
    value,
    label,
    onChange,
    placeholder,
    format,
    errorMessage,
    required,
    placement,
    startDate,
    endDate,
    selectsRange,
    isClearable,
    inline,
  } = props;

  return (
    <>
      <label>{label}</label>
      {required && <span className="text-error">*</span>}
      <DatePicker
        selected={value}
        onChange={onChange}
        placeholderText={placeholder}
        dateFormat={format}
        className="form-control"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperPlacement={placement}
        startDate={startDate}
        endDate={endDate}
        selectsRange={selectsRange ? selectsRange : undefined}
        isClearable={isClearable ? isClearable : undefined}
        inline={inline ? inline : undefined}
      />
      <span className="text-error">{errorMessage}</span>
    </>
  );
};

export default DatePickerComponent;
