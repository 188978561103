import Loader from "../../../../shared/components/Loader";
import TextBox from "../../../../shared/components/TextBox";
import { ACTION_LABEL, LABELS } from "../../../../shared/constants";

const CustomerDetail = (props: any) => {
  const { customerDetailData, getCustomerLoading } = props;

  return (
    <>
      {!getCustomerLoading && customerDetailData !== undefined ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.SALUTATION_TEXT}
                  placeholder={LABELS.SALUTATION_TEXT}
                  name={ACTION_LABEL.SALUTATION}
                  value={customerDetailData.salutation}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.FIRSTNAME_TEXT}
                  placeholder={LABELS.FIRSTNAME_TEXT}
                  name={ACTION_LABEL.FIRSTNAME}
                  value={customerDetailData.firstName}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.MIDDLE_NAME_TEXT}
                  placeholder={LABELS.MIDDLE_NAME_TEXT}
                  name={ACTION_LABEL.MIDDLENAME}
                  value={customerDetailData.middleName}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.LASTNAME_TEXT}
                  placeholder={LABELS.LASTNAME_TEXT}
                  name={ACTION_LABEL.LASTNAME}
                  value={customerDetailData.lastName}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  name={`${ACTION_LABEL.ADDRESS}1`}
                  placeholder={`${LABELS.ADDRESS_TEXT} 1`}
                  label={`${LABELS.ADDRESS_TEXT} 1`}
                  value={customerDetailData.address1}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  name={`${ACTION_LABEL.ADDRESS}2`}
                  placeholder={`${LABELS.ADDRESS_TEXT} 2`}
                  label={`${LABELS.ADDRESS_TEXT} 2`}
                  value={customerDetailData.address1}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CITY_TEXT}
                  placeholder={LABELS.CITY_TEXT}
                  name={ACTION_LABEL.CITY}
                  value={customerDetailData.city}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.STATE_TEXT}
                  placeholder={LABELS.STATE_TEXT}
                  name={ACTION_LABEL.STATE}
                  value={customerDetailData.state}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.DISTRICT_TEXT}
                  placeholder={LABELS.DISTRICT_TEXT}
                  name={ACTION_LABEL.DISTRICT}
                  value={customerDetailData.district}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.PIN_TEXT}
                  placeholder={LABELS.PIN_TEXT}
                  name={ACTION_LABEL.PIN}
                  value={customerDetailData.pin}
                  disable
                />
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.EMAIL_TEXT}
                  placeholder={LABELS.EMAIL_TEXT}
                  name={ACTION_LABEL.EMAIL}
                  value={customerDetailData.email}
                  disable
                />
              </div>
            </div> */}
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.MOBILE_TEXT}
                  placeholder={LABELS.MOBILE_TEXT}
                  name={ACTION_LABEL.MOBILE}
                  value={customerDetailData.mobile}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.ISD_CODE_TEXT}
                  placeholder={LABELS.ISD_CODE_TEXT}
                  name={ACTION_LABEL.ISD_CODE}
                  value={customerDetailData.isdCode}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.GENDER_TEXT}
                  placeholder={LABELS.GENDER_TEXT}
                  name={ACTION_LABEL.GENDER}
                  value={customerDetailData.gender}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.DOB_TEXT}
                  placeholder={LABELS.DOB_TEXT}
                  name={ACTION_LABEL.DOB}
                  value={customerDetailData.dob}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.RECIEVE_MESSAGE_TEXT}
                  placeholder={LABELS.RECIEVE_MESSAGE_TEXT}
                  name={ACTION_LABEL.RECIEVE_MESSAGE}
                  value={customerDetailData.receiveMessage}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.PREF_COMMUNICATION_MODE_TEXT}
                  placeholder={LABELS.PREF_COMMUNICATION_MODE_TEXT}
                  name={ACTION_LABEL.PREF_COMMUNICATION_MODE}
                  value={customerDetailData.prefCommuniCationMode}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.REMARKS_TEXT}
                  placeholder={LABELS.REMARKS_TEXT}
                  name={ACTION_LABEL.REMARKS}
                  value={customerDetailData.remarks}
                  disable
                />
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.GST_STATE_TEXT}
                  placeholder={LABELS.GST_STATE_TEXT}
                  name={ACTION_LABEL.GST_STATE}
                  value={customerDetailData.gstState}
                  disable
                />
              </div>
            </div> */}
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.SITE_CODE_TEXT}
                  placeholder={LABELS.SITE_CODE_TEXT}
                  name={ACTION_LABEL.SITE_CODE}
                  value={customerDetailData.siteCode}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.GST_IN_TEXT}
                  placeholder={LABELS.GST_IN_TEXT}
                  name={ACTION_LABEL.GST_IN}
                  value={customerDetailData.gstin}
                  disable
                />
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.SITE_CODE_TEXT}
                  placeholder={LABELS.SITE_CODE_TEXT}
                  name={ACTION_LABEL.SITE_CODE}
                  value={customerDetailData.siteCode}
                  disable
                />
              </div>
            </div> */}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CustomerDetail;
