import { useEffect, useState } from "react";
import { useOrderGetQuery } from "../../../Features/order/order-slice";
import SimpleButton from "../../../shared/components/Button/Button";
import { BUTTON_LABEL, icons, LABELS } from "../../../shared/constants";
import { skipToken } from "@reduxjs/toolkit/query";

const PopulateBar = (props: any) => {
  const { show, orderId, closePopulateBar, handlePrintOrder, populateData } =
    props;
  const [orderDetailData, setOrderDetailData]: any = useState();

  const {
    data: getOrderData,
    isLoading: getOrderLoading,
    isSuccess: getOrderSuccess,
    refetch: getOrderRefetch,
  } = useOrderGetQuery(orderId ? orderId : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (orderId !== undefined && getOrderSuccess) {
      setOrderDetailData(getOrderData.item);
    }
  }, [orderId, getOrderData, getOrderSuccess]);

  const measurementStandardBlock = (item: any) => {
    return (
      <div className="value">
        <label htmlFor="">{item?.title}</label>
        <span className="label-box">{item?.value}</span>
      </div>
    );
  };

  const customMeasurementBlock = (title: any, value: any) => {
    if (value !== "") {
      return (
        <div className="item-card">
          <div className="item-header ">
            <span>{title}</span>
            <span onClick={() => printData("single", title, "custom")}>
              <i className={icons.ICON_PRINTER_FILLED}></i>
            </span>
          </div>
          <div className="item-body">
            <div className="populate-values">
              <div className="value">
                <span className="label-box">{value}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const printData = (type: any, index: any, section: any) => {
    let dataToPrint;

    if (type === "single") {
      if (section === "top" && orderDetailData?.measurement?.top) {
        dataToPrint = {
          top: [orderDetailData?.measurement?.top[index]],
        };
      } else if (section === "bottom" && orderDetailData?.measurement?.bottom) {
        dataToPrint = {
          bottom: [orderDetailData?.measurement?.bottom[index]],
        };
      } else if (section === "custom") {
        dataToPrint = {
          custom: {
            [index]: orderDetailData?.measurement?.custom[index],
          },
        };
      }
    } else if (type === "multiple") {
      dataToPrint = orderDetailData.measurement;
    }
    handlePrintOrder(dataToPrint);
  };

  return (
    <>
      <div className={`populate-bar ${show ? "show" : ""}`}>
        <div
          className="populate-bar-header align-items-center d-flex"
          onClick={closePopulateBar}
        >
          <p className="cursor-pointer">
            <i className={icons.ICON_CHEVRON_LEFT}></i>
            <span>{LABELS.POPULATE_TEXT}</span>
          </p>
        </div>
        <div className="populate-bar-body custom-scrollbar">
          {populateData === undefined ||
          (populateData?.top?.length === 0 &&
            populateData?.bottom?.length === 0) ? (
            <span>{LABELS.NOT_FOUND_TEXT}</span>
          ) : (
            <>
              {populateData?.top?.length !== 0
                ? populateData?.top?.map((topEl: any, index: any) => {
                    return (
                      <div className="item-card">
                        <div className="item-header ">
                          <span>{topEl.type}</span>
                          <span
                            onClick={() => printData("single", index, "top")}
                          >
                            <i className={icons.ICON_PRINTER_FILLED}></i>
                          </span>
                        </div>
                        <div className="item-body">
                          <div className="populate-values">
                            {topEl.data.map((el: any) => {
                              return measurementStandardBlock(el);
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
              {populateData?.bottom?.length !== 0
                ? populateData?.bottom?.map((bottomEl: any, index: any) => {
                    return (
                      <div className="item-card">
                        <div className="item-header ">
                          <span>{bottomEl.type}</span>
                          <span
                            onClick={() => printData("single", index, "bottom")}
                          >
                            {/* <p>{LABELS.PRINT_ALL_TEXT}</p> */}
                            <i className={icons.ICON_PRINTER_FILLED}></i>
                          </span>
                        </div>
                        <div className="item-body">
                          <div className="populate-values">
                            {bottomEl.data.map((el: any) => {
                              return measurementStandardBlock(el);
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}

              {populateData?.custom &&
                typeof populateData?.custom === "object" &&
                Object.keys(populateData?.custom).map(
                  (ele: any, index: any) => {
                    return customMeasurementBlock(
                      ele,
                      populateData?.custom[ele]
                    );

                    // return populateData?.custom[ele] !== "" ? (
                    //   <div className="item-card">
                    //     <div className="item-header ">
                    //       <span>{ele}</span>
                    //       <span
                    //         onClick={() => printData("single", ele, "custom")}
                    //       >
                    //         <i className={icons.ICON_PRINTER_FILLED}></i>
                    //       </span>
                    //     </div>
                    //     <div className="item-body">
                    //       <div className="populate-values">
                    //         <div className="value">
                    //           <span className="label-box">
                    //             {populateData?.custom[ele]}
                    //           </span>
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </div>
                    // ) : (
                    //   ""
                    // );
                  }
                )}

              <div className="d-flex align-items-center">
                {/* <CheckBox
                  onChange={handlePrintAllToggle}
                  value={printAll}
                  label={"Print All"}
                  name={"printAll"}
                  id={"printAll"}
                /> */}

                <i
                  className={`${icons.ICON_PRINTER_FILLED} cursor-pointer ms-2`}
                  onClick={() => printData("multiple", null, null)}
                ></i>
              </div>
            </>
          )}
          <div className="text-center mt-5">
            <SimpleButton
              className={"btn theme-btn theme-btn-sm"}
              onClick={closePopulateBar}
              text={BUTTON_LABEL.CLOSE}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PopulateBar;
