import { API_URL } from "../../shared/constants";
import { emptySplitApi } from "../emptySplit-api";

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: `${API_URL.AUTH}/login`,
        method: "POST",
        body: credentials,
      }),
    }),
    logout: builder.mutation<any, void>({
      query: () => ({
        url: `${API_URL.AUTH}/logout`,
        method: "POST",
      }),
    }),
    getOtp: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `${API_URL.AUTH}/${API_URL.FORGOT_PASSWORD}/otp-request`,
          method: "post",
          body: data,
        };
      },
    }),
    validateOtp: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `${API_URL.AUTH}/${API_URL.FORGOT_PASSWORD}/validate-otp`,
          method: "post",
          body: data,
        };
      },
    }),
    resetPassword: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `${API_URL.AUTH}/${API_URL.FORGOT_PASSWORD}/reset-password`,
          method: "post",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useGetOtpMutation,
  useValidateOtpMutation,
  useResetPasswordMutation,
} = authApi;
