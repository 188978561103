import { useEffect, useMemo, useState } from "react";
import Navbar from "../../Navbar/NavbarComponent/Navbar";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  MESSAGE,
  SEARCH_DATA,
  toastError,
  toastSuccess,
  TOAST_MESSAGE,
  URLS,
} from "../../../shared/constants";
import Table from "../../../shared/components/Table";
import Search from "../../../shared/components/Search";
import { listCheck } from "../../../shared/helperFunc/listCheck";
import CustomSwitch from "../../../shared/components/CustomSwitch";
import {
  useRolesDeleteMutation,
  useRolesListQuery,
  useRolesUpdateMutation,
} from "../../../Features/role/role-slice";
import { useDeleteModal } from "../../../shared/utils/hooks/useDeleteModal";
import { dateConvert } from "../../../shared/helperFunc/dateConvert";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../shared/components/CustomModal";
import SimpleButton from "../../../shared/components/Button/Button";

const Role = () => {
  const navigate = useNavigate();

  const [showDetail, setShowDetail] = useState(false);
  const [sort, setSort] = useState(SEARCH_DATA);
  const [search, setSearch] = useState(false);
  const [count, setCount] = useState();
  const [multiDelete, setMultiDelete] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [roleId, setRoleId]: any = useState();
  const [
    showModal,
    deletedName,
    deleteId,
    deleteShowModal,
    deleteCloseModal,
    deleteShowModals,
  ] = useDeleteModal();

  const {
    data,
    isSuccess,
    error,
    refetch: rolesRefetch,
    isFetching: rolesListFetching,
  } = useRolesListQuery(sort);

  const [roleUpdate, { isSuccess: roleUpdateSuccess }] =
    useRolesUpdateMutation();

  const [
    roleDelete,
    { isSuccess: roleDeleteSuccess, isLoading: roleDeleteLoading },
  ] = useRolesDeleteMutation();

  useEffect(() => {
    if (isSuccess) {
      setRolesList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  const roleDeleteFlow = async (data: any) => {
    toastSuccess(data.message);
    deleteCloseModal();
    deleteShowModal([]);
    removeMultiDelete();
    rolesRefetch();
  };

  const handleAdd = () => {
    navigate(URLS.ROLES_ADD);
  };

  const handleSearch = () => {
    // if (val === "") {
    //   setSearch(!search);
    //   setSort({ ...sort, q: undefined, skip: 0 });
    // } else {
    //   setSearch(!search);
    //   setSort({ ...sort, skip: 0 });
    // }

    setSearch(!search);
    setSort({ ...sort, skip: 0 });
  };

  const handleSearchTextChange = (e: any) => {
    setSort({ ...sort, [e.target.name]: e.target.value, skip: 0 });
    // if (e.target.value.length === 0) {
    //   handleSearch(e.target.value);
    // }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
      // handleSearch(e.target.value);
    }
  };

  const roleUpdateFlow = async (data: any) => {
    toastSuccess(data.message);
    rolesRefetch();
  };

  const handleSwitchChange = async (id: any, isActive: any) => {
    const roleUpdateResponse = await roleUpdate({
      id: id,
      data: { isActive: !isActive },
    })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (roleUpdateResponse && roleUpdateResponse?.status === true) {
      roleUpdateFlow(roleUpdateResponse);
    }
  };

  const columns = useMemo(
    () => [
      {
        header: LABELS.NAME_TEXT,
        accessorKey: ACTION_LABEL.NAME,
        isShow: true,
      },
      {
        header: LABELS.CODE_TEXT,
        accessorKey: ACTION_LABEL.CODE,
        isShow: true,
      },
      {
        header: LABELS.CREATED_AT_TEXT,
        accessorKey: ACTION_LABEL.CREATED_AT,
        cell: ({ row }: any) =>
          dateConvert(row.original[ACTION_LABEL.CREATED_AT]),
        isShow: true,
      },
      {
        header: LABELS.STATUS_TEXT,
        cell: ({ row }: any) => CustomSwitch(row, handleSwitchChange),
        isShow: true,
      },
    ],
    [sort]
  );

  const setCheckBox = (flag: any) => {
    let updated = listCheck(JSON.parse(JSON.stringify(rolesList)), flag);
    return updated;
  };

  const checkSelectedData = (data: any) => {
    return data.filter((ele: any) => {
      return ele.isSelected;
    });
  };

  const checked = (e: any, index: any) => {
    setMultiDelete(true);
    let data = JSON.parse(JSON.stringify(rolesList));

    if (e.target.checked) {
      data[index].isSelected = true;
    } else {
      data[index].isSelected = false;
    }
    setRolesList(data);
    const visibleName = checkSelectedData(data);
    deleteShowModal(visibleName);
  };

  const removeMultiDelete = () => {
    let unSelected = setCheckBox(false);
    setRolesList(unSelected);
    const visibleName = checkSelectedData(unSelected);
    deleteShowModal(visibleName);
  };

  const actionMultiDelete = () => {
    deleteShowModals();
    const visibleName = checkSelectedData(rolesList);
    deleteShowModal(visibleName);
  };

  const allCheck = (e: any) => {
    setMultiDelete(true);
    let data = [];
    data = setCheckBox(e.target.checked);
    const visibleName = checkSelectedData(data);
    deleteShowModal(visibleName);

    setRolesList(data);
  };

  const handleView = (row: any) => {
    setRoleId(row._id);
    setShowDetail(true);
  };
  const handleEdit = (row: any) => {
    navigate(URLS.ROLES_ADD, { state: { _id: row._id } });
  };

  const handleShowModal = (row: any) => (
    deleteShowModals(true), deleteShowModal([row])
  );

  const tableAction = (
    handleView: any,
    handleEdit: any,
    handleShowModal: any
  ) => {
    return [
      // {
      //   elementType: "button",
      //   text: BUTTON_LABEL.VIEW,
      //   className: `${`icon ${icons.ICON_VIEW}`} mx-1 `,
      //   icon: `icon ${icons.ICON_VIEW}`,
      //   action: handleView,
      // },
      {
        elementType: "button",
        text: BUTTON_LABEL.EDIT,
        className: `${`icon ${icons.ICON_EDIT}`} mx-1 `,
        // icon: "",
        action: handleEdit,
      },
      {
        elementType: "button",
        text: BUTTON_LABEL.DELETE,
        className: `${`icon ${icons.ICON_DELETE}`} mx-1 `,
        // icon: "",
        action: handleShowModal,
      },
    ];
  };

  const deleteModal = (handleDelete: any, closeModal: any, isLoading: any) => {
    return [
      {
        text: BUTTON_LABEL.YES,
        action: handleDelete,
        className: "btn theme-btn theme-btn-sm w-auto",
        isLoading: isLoading,
      },
      {
        text: BUTTON_LABEL.NO,
        action: closeModal,
        className: "btn theme-btn theme-btn-sm btn-inverse w-auto",
      },
    ];
  };

  const handleDelete = async () => {
    const roleDeleteResponse = await roleDelete({ _id: deleteId })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (roleDeleteResponse && roleDeleteResponse?.status === true) {
      roleDeleteFlow(roleDeleteResponse);
      // deleteCloseModal();
      // deleteShowModal([]);
    }
  };
  const closeModal = () => {
    deleteCloseModal();
    deleteShowModal([]);
    removeMultiDelete();
  };

  const deleteModalButton = deleteModal(
    handleDelete,
    closeModal,
    roleDeleteLoading
  );

  const action = tableAction(handleView, handleEdit, handleShowModal);

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.ROLE_MANAGEMENT_TEXT} />
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-4 d-flex align-items-center">
                  <SimpleButton
                    className={"me-2 btn theme-btn theme-btn-sm btn-inverse"}
                    btnIcon={icons.ICON_FILTER}
                    btnIconClass={"me-2 d-inline-block"}
                    text={BUTTON_LABEL.FILTER}
                  />
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.q}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    />
                    <SimpleButton
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      }
                      onClick={handleAdd}
                      btnIcon={icons.ICON_ADD}
                      text={BUTTON_LABEL.ADD_ROLE}
                    />
                  </div>
                </div>
              </div>
              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={rolesList}
                    columns={columns}
                    select={true}
                    action={action}
                    sort={sort}
                    setSort={setSort}
                    allCheck={allCheck}
                    allCheckedCheckbox={deleteId.length === rolesList.length}
                    checked={checked}
                    coloumnDrop={false}
                    count={count}
                    isFetching={rolesListFetching}
                    deleteId={deleteId}
                    removeMultiDelete={removeMultiDelete}
                    actionMultiDelete={actionMultiDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <CustomModal
        show={showModal}
        close={closeModal}
        message={`${MESSAGE.DELETE_MESSAGE} ${deletedName} Role?`}
        size="md"
        modalTitle={LABELS.DELETE_ROLE_TEXT}
        modalButton={deleteModalButton}
        children={undefined}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
        // modalTitleIcon={icons.ICON_DELETE_V2}
      />
    </>
  );
};

export default Role;
