import Navbar from "../../Navbar/NavbarComponent/Navbar";
import {
  useStoreByIdQuery,
  useStoreImportMutation,
  useStoreListQuery,
} from "../../../Features/store/store-slice";
import { useEffect, useMemo, useRef, useState } from "react";
import Table from "../../../shared/components/Table";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  SEARCH_DATA,
  toastError,
  toastSuccess,
} from "../../../shared/constants";
import CustomModal from "../../../shared/components/CustomModal";
import StoreDetail from "./StoreDetail";
import Search from "../../../shared/components/Search";
import { skipToken } from "@reduxjs/toolkit/query";
import SimpleButton from "../../../shared/components/Button/Button";
import { CSVLink } from "react-csv";
import authProvider from "../../../shared/config/authProvider";
import Filter from "../../Common/Filter/Filter";
import { useFilter } from "../../../shared/utils/hooks/useFilter";

const StoreList = () => {
  const [search, setSearch] = useState(false);
  const [sort, setSort] = useState(SEARCH_DATA);
  const [count, setCount] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [storeId, setStoreId]: any = useState();
  const [storeDetailData, setStoreDetailData] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [filterCalled, setFilterCalled]: any = useState(false);
  const [filterSelectionError, setFilterSelectionError]: any = useState();
  const [handleFilter] = useFilter(sort, setSort);
  const iconInput = useRef<any>(null);
  const [filterValues, setFilterValues] = useState<any>({});

  const {
    data,
    isSuccess,
    error,
    isFetching: storeListFetching,
    refetch: storeRefetch,
  } = useStoreListQuery(sort, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: getStoreData,
    isLoading: getStoreLoading,
    isSuccess: getStoreSuccess,
  } = useStoreByIdQuery(storeId ? storeId : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [
    storeImport,
    { isSuccess: storeImportSuccess, isLoading: storeImportLoading },
  ] = useStoreImportMutation();

  useEffect(() => {
    storeRefetch();
  }, [filterCalled]);

  useEffect(() => {
    if (isSuccess) {
      setStoreList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (storeId !== undefined && getStoreSuccess) {
      setStoreDetailData(getStoreData.item);
    }
  }, [storeId, getStoreData, getStoreSuccess]);

  const storeImportFlow = (data: any) => {
    storeRefetch();
    toastSuccess(data.message);
  };

  const handleCallFilter = () => {
    // if (Object.keys(sort.filter).length > 0) {
    handleFilter();
    setFilterCalled(!filterCalled);
    setSort({ ...sort, filter: filterValues, filterFlag: true, skip: 0 });
    setShowFilter(!showFilter);
    // } else {
    //   setFilterSelectionError("*Please select atlease one filter.");
    // }
  };

  const resetFilter = () => {
    setFilterValues({});
    setSort({ ...sort, filter: {}, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setSort({ ...sort, filter: {}, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(false);
  };

  const handleClose = () => setShowDetail(false);

  const handleSearch = () => {
    setSearch(!search);
    setSort({ ...sort, skip: 0 });
  };

  const handleSearchTextChange = (e: any) => {
    setSort({ ...sort, [e.target.name]: e.target.value, skip: 0 });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleRef = () => {
    iconInput.current.click();
  };

  const handleCsvImport = async (e: any) => {
    let formData = new FormData();
    formData.append("csv", e.target.files[0], e.target.files[0].name);
    const storeImportResponse = await storeImport(formData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (storeImportResponse && storeImportResponse?.status === true) {
      storeImportFlow(storeImportResponse);
    }
  };

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setFilterValues({
      ...filterValues,
      [e.target.name]: trimmedValue,
    });
  };

  const handleTextChange = (e: any) => {
    setFilterValues({
      ...filterValues,
      [e.target.name]: e.target.value,
    });
  };

  // const handleSelect = (item: any, e: any) => {
  //   let selectedValue = item?.value;

  //   if (selectedValue) {
  //     setFilterValues({
  //       ...filterValues,
  //       [e.name]: selectedValue,
  //     });
  //   } else {
  //     let updatedFilter: any = { ...filterValues };
  //     delete updatedFilter[e.name];
  //     setFilterValues(updatedFilter);
  //   }
  //   setFilterSelectionError("");
  // };

  // const handleDate = (dates: any) => {
  //   const [start, end] = dates;

  //   const fromDate = start ? new Date(start).toISOString() : null;
  //   const toDate = end ? new Date(end).toISOString() : null;

  //   setFilterValues({
  //     ...filterValues,
  //     createdAt: { from: fromDate, to: toDate },
  //   });
  // };

  const handleStoreDetails = (e: any, row: any) => {
    setStoreId(row._id);
    setShowDetail(true);
  };

  const csvData = [
    {
      CUID: "1123",
      SHORT_CODE: "INDORE Y N ROAD",
      DOC_IDENTIFICATION_NO: "A24",
      NAME: "N M FASHION DESIGNS PVT LTD (KORA INDORE)",
      STORE_SIZE: "0",
      TARGET_SALES: "0",
      PRICE_TYPE: "MRP",
      SITE_TYPE: "US-CO-OM",
      CUSTOMER: "RIRONSI COUTURE LLP",
      ADDRESS:
        "GR FLOOR AND 1ST FLOOR,SHIVAAY ONE,2, YASHWANT NIWAS ROAD,NEAR SBI BANK,",
      SYNC_DATE_AND_TIME: "",
      GST_STATE_NAME: "Maharashtra",
      STATE: "",
      CITY: "JAIPUR",
      PIN: "",
      EMAIL: "",
      CODE: "708",
      MOBILE: "9414062062",
      PHONE_NO_1: "",
      TAX_REGION: "",
    },
    {
      CUID: "1125",
      SHORT_CODE: "COIMBATORE GANDHIPURAM",
      DOC_IDENTIFICATION_NO: "A26",
      NAME: "N M FASHION DESIGNS PVT LTD (KORA COIBATORE)",
      STORE_SIZE: "0",
      TARGET_SALES: "0",
      PRICE_TYPE: "MRP",
      SITE_TYPE: "US-CO-OM-TS",
      CUSTOMER: "PCUBE RAGE LLP (KORA VIJAYWADA)",
      ADDRESS:
        "PLOT NO 301/1, CROSS CUT ROAD,NEAR KEERTI LAL JEWELLERY,GANDHIPURAM,",
      SYNC_DATE_AND_TIME: "",
      GST_STATE_NAME: "Andhra Pradesh",
      STATE: "",
      CITY: "NEW DELHI",
      PIN: "",
      EMAIL: "",
      CODE: "502",
      MOBILE: "9412088888",
      PHONE_NO_1: "",
      TAX_REGION: "",
    },
    {
      CUID: "1225",
      SHORT_CODE: "INDOMUMBAI BORIVALI",
      DOC_IDENTIFICATION_NO: "A13",
      NAME: "N M FASHION DESIGNS PVT LTD (KORA BORIVALI)",
      STORE_SIZE: "0",
      TARGET_SALES: "0",
      PRICE_TYPE: "MRP",
      SITE_TYPE: "US-CO-OM",
      CUSTOMER: "RIRONSI COUTURE LLP",
      ADDRESS:
        "KORA SHOP NO. 1& 2,KABRA ELITA HUB, SODAWALA LANE,BORIVALI (WEST)",
      GST_STATE_NAME: "Maharashtra",
      STATE: "",
      CITY: "SURAT",
      PIN: "",
      EMAIL: "",
      CODE: "390",
      MOBILE: "8758788577",
      PHONE_NO_1: "",
      TAX_REGION: "",
    },
    {
      CUID: "2121",
      SHORT_CODE: "DUBAI MEENA BAZAR",
      DOC_IDENTIFICATION_NO: "A11",
      NAME: "SATUTI GUPTA GARMENTS TRADING LLC (KORA DUBAI)",
      STORE_SIZE: "0",
      TARGET_SALES: "0",
      PRICE_TYPE: "MRP",
      SITE_TYPE: "US-CO-OM",
      CUSTOMER: "SATUTI GUPTA GARMENTS TRADING IIC (KORA DUBAI)",
      ADDRESS: "OPP.BAWREE,COSMOS LANE,MEENA BAZAR,BUR DUBAI",
      GST_STATE_NAME: "",
      STATE: "",
      CITY: "ANAND",
      PIN: "",
      EMAIL: "",
      CODE: "375",
      MOBILE: "9824079557",
      PHONE_NO_1: "",
      TAX_REGION: "",
    },
  ];

  const storeModalButton = [
    {
      text: BUTTON_LABEL.CANCEL,
      action: handleClose,
      className: "btn btn theme-btn",
    },
  ];

  const filterContent = (handleTextChange: any) => {
    return [
      {
        label: LABELS.STORE_SIZE_TEXT,
        name: ACTION_LABEL.STORE_SIZE,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.STATE_TEXT,
        name: ACTION_LABEL.STATE,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.CITY_TEXT,
        name: ACTION_LABEL.CITY,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
    ];
  };

  const columns = useMemo(
    () => [
      {
        header: LABELS.CUID_TEXT,
        accessorKey: ACTION_LABEL.CUID,
        isShow: true,
      },
      {
        header: LABELS.SHORT_CODE_TEXT,
        accessorKey: ACTION_LABEL.SHORTCODE,
        isShow: true,
      },
      {
        header: LABELS.DOC_IDENTIFICATION_NO_TEXT,
        accessorKey: ACTION_LABEL.DOC_IDENTIFICATION_NO,
        isShow: true,
      },
      {
        header: LABELS.NAME_TEXT,
        accessorKey: ACTION_LABEL.NAME,
        isShow: true,
      },
      {
        header: LABELS.TARGET_SALES_TEXT,
        accessorKey: ACTION_LABEL.TARGET_SALES,
        isShow: true,
      },
      {
        header: LABELS.CUSTOMER_TEXT,
        accessorKey: ACTION_LABEL.CUSTOMER,
        isShow: false,
      },
      {
        header: LABELS.PRICE_TYPE_TEXT,
        accessorKey: ACTION_LABEL.PRICE_TYPE,
        isShow: true,
      },
      {
        header: LABELS.SITE_TYPE_TEXT,
        accessorKey: ACTION_LABEL.SITE_TYPE,
        isShow: true,
      },
      {
        header: LABELS.STORE_SIZE_TEXT,
        accessorKey: ACTION_LABEL.STORE_SIZE,
        isShow: true,
      },
      {
        header: LABELS.SYNC_DATE_AND_TIME_TEXT,
        accessorKey: ACTION_LABEL.SYNC_DATE_AND_TIME,
        isShow: false,
      },
      {
        header: LABELS.GST_STATE_NAME_TEXT,
        accessorKey: ACTION_LABEL.GST_STATE_NAME,
        isShow: false,
      },
      {
        header: LABELS.ADDRESS_TEXT,
        accessorKey: ACTION_LABEL.ADDRESS,
        isShow: false,
      },
      {
        header: LABELS.STATE_TEXT,
        accessorKey: ACTION_LABEL.STATE,
        isShow: false,
      },
      {
        header: LABELS.CITY_TEXT,
        accessorKey: ACTION_LABEL.CITY,
        isShow: false,
      },
      {
        header: LABELS.PIN_TEXT,
        accessorKey: ACTION_LABEL.PIN,
        isShow: false,
      },
      {
        header: LABELS.MOBILE_TEXT,
        accessorKey: ACTION_LABEL.MOBILE,
        isShow: false,
      },
      {
        header: `${LABELS.PHONE_NO_TEXT} 1`,
        accessorKey: `${ACTION_LABEL.PHONE_NO}1`,
        isShow: false,
      },
      // {
      //   header: `${LABELS.PHONE_NO_TEXT} 2`,
      //   accessorKey: `${ACTION_LABEL.PHONE_NO}2`,
      //   isShow: false,
      // },
      {
        header: LABELS.EMAIL_ID_TEXT,
        accessorKey: ACTION_LABEL.EMAIL,
        isShow: false,
      },
      {
        header: LABELS.CODE_TEXT,
        accessorKey: ACTION_LABEL.CODE,
        isShow: false,
      },
      {
        header: LABELS.TAX_REGION_TEXT,
        accessorKey: ACTION_LABEL.TAX_REGION,
        isShow: false,
      },
    ],
    [sort]
  );

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.STORE_MANAGEMENT_TEXT} />
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                {/* <div className="col-12 col-md-4 d-flex align-items-center"></div> */}
                <div className="col-12 col-md-12 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.q}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    />

                    {authProvider.show("store", "edit") ? (
                      <>
                        <SimpleButton
                          datatoggle="tooltip"
                          dataplacement="top"
                          title={BUTTON_LABEL.IMPORT}
                          className={
                            "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                          }
                          onClick={handleRef}
                          text={BUTTON_LABEL.IMPORT}
                        >
                          <input
                            type="file"
                            style={{ display: "none" }}
                            ref={iconInput}
                            onChange={handleCsvImport}
                            accept=".csv"
                            onClick={(e: any) => {
                              e.target.value = null;
                            }}
                            hidden
                          />
                        </SimpleButton>

                        <CSVLink
                          data={csvData}
                          filename={"store.csv"}
                          className="w-auto px-0"
                        >
                          <SimpleButton
                            datatoggle="tooltip"
                            dataplacement="top"
                            title={BUTTON_LABEL.SAMPLE_CSV}
                            className={
                              "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                            }
                            text={BUTTON_LABEL.SAMPLE_CSV}
                          />
                        </CSVLink>

                        <SimpleButton
                          className={
                            "me-2 btn theme-btn theme-btn-sm btn-inverse filter-btn"
                          }
                          btnIcon={icons.ICON_FILTER}
                          btnIconClass={"me-2 d-inline-block"}
                          // text={BUTTON_LABEL.FILTER}
                          onClick={handleCallFilter}
                        />

                        {/* <SimpleButton
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      }
                      onClick={handleShow}
                      text={BUTTON_LABEL.EXPORT}
                    /> */}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={storeList}
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    coloumnDrop={true}
                    count={count}
                    handleRow={handleStoreDetails}
                    isHandleRowEnabled
                    isFetching={storeListFetching}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <CustomModal
        show={showDetail}
        close={handleClose}
        size={"lg custom-lg"}
        message={""}
        modalTitle={LABELS.STORE_MANAGEMENT_DETAILS_TEXT}
        modalButton={storeModalButton}
        fullscreen={undefined}
        className={"light-modal inventory-detail-modal"}
        centerClass={""}
        modalTitleIcon={""}
      >
        <StoreDetail
          storeDetailData={storeDetailData}
          getStoreLoading={getStoreLoading}
        />
      </CustomModal>

      <Filter
        sort={filterValues}
        filterSelectionError={filterSelectionError}
        filterObject={filterContent(handleTextChange)}
        show={showFilter}
        onClose={closeFilter}
        handleReset={resetFilter}
        handleApply={handleCallFilter}
        handleOnBlur={handleOnBlur}
      />
    </>
  );
};

export default StoreList;
