import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { URLS } from "../shared/constants";

const DefaultLayout = () => {
  const auth = useContext(AuthContext);
  const { authState } = auth;

  if (authState?.userInfo?.token) {
    return <Navigate replace to={URLS.DASHBOARD} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default DefaultLayout;
