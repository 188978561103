import { BUTTON_LABEL, icons, LABELS } from "../../../shared/constants";
import SimpleButton from "../../../shared/components/Button/Button";
import { Offcanvas } from "react-bootstrap";
import Select from "../../../shared/components/Select";
import TextBox from "../../../shared/components/TextBox";
import DatePickerComponent from "../../../shared/components/DatePicker/DatePicker";
import NumberBox from "../../../shared/components/NumberBox";
import {
  ACTIVE_STATE,
  GENDER_DROP,
  STATUS_DROP,
} from "../../../shared/constants/resource";
import {
  useRolesListQuery,
  useRolesSearchQuery,
} from "../../../Features/role/role-slice";
import { useStoreSearchQuery } from "../../../Features/store/store-slice";

const Filter = (props: any) => {
  const {
    sort,
    filterSelectionError,
    filterObject,
    show,
    onClose,
    handleReset,
    handleApply,
    handleOnBlur,
  } = props;

  const { data: roleSearchData, isLoading: rolesLoading } =
    useRolesSearchQuery("");

  const { data: storeSearchData, isLoading: storeLoading } =
    useStoreSearchQuery("");

  const getOptions = (filter: any) => {
    switch (filter.name) {
      case "role":
        if (rolesLoading) {
          return [];
        }
        return roleSearchData?.list || [];
      case "store":
        if (storeLoading) {
          return [];
        }
        return storeSearchData?.list || [];
      case "status":
        return STATUS_DROP;
      case "gender":
        return GENDER_DROP;
      case "isActive":
        return ACTIVE_STATE;
      default:
        return [];
      //       }
    }
  };

  const renderFilterBlock = (filter: any) => {
    if (filter.elementType === "select") {
      return (
        <div className="col-12">
          <div className="form-group">
            <Select
              data={getOptions(filter) ? getOptions(filter) : []}
              label={filter.label}
              id={filter.label}
              name={filter.name}
              value={sort[filter?.name] || ""}
              onChange={(e: any) => filter.action(e.target, e)}
            />
          </div>
        </div>
      );
    }

    if (filter.elementType === "text") {
      return (
        <div className="col-12">
          <div className="form-group">
            <TextBox
              name={filter.name}
              placeholder={filter.label}
              id={filter.label}
              label={filter.label}
              onChange={filter.action}
              onBlur={handleOnBlur}
              value={sort[filter.name] || ""}
            />
          </div>
        </div>
      );
    }

    if (filter.elementType === "number") {
      return (
        <div className="col-12">
          <div className="form-group">
            <NumberBox
              name={filter.name}
              placeholder={filter.label}
              id={filter.label}
              label={filter.label}
              onChange={filter.action}
              value={sort[filter.name] || ""}
              min={"0"}
              max={100}
            />
          </div>
        </div>
      );
    }

    if (filter.elementType === "createdAt") {
      return (
        <div className="col-12">
          <div className="form-group">
            <DatePickerComponent
              label={filter.label}
              onChange={filter.action}
              placeholder={"DD/MM/YYYY"}
              format="dd/MM/yyyy"
              placement="bottom"
              startDate={
                sort?.createdAt?.from ? new Date(sort?.createdAt?.from) : null
              }
              endDate={
                sort?.createdAt?.to ? new Date(sort?.createdAt?.to) : null
              }
              selectsRange
              isClearable
            />
          </div>
        </div>
      );
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      // backdrop="true"
      placement="end"
      className="filter-bar"
    >
      <div className="filter-header">
        <h5>{LABELS.FILTER_TEXT}</h5>
        <div className="close-btn" onClick={onClose}>
          <i className={icons.ICON_CLOSE} />
        </div>
      </div>
      <Offcanvas.Body className="filter-body">
        {filterObject?.map((item: any, index: any) => {
          return renderFilterBlock(item);
        })}
        {filterSelectionError !== "" && (
          <span className="text-error">{filterSelectionError}</span>
        )}
        <div className="col-md-12 text-center mt-5">
          <SimpleButton
            text={BUTTON_LABEL.APPLY}
            className="btn theme-btn theme-btn-sm me-2"
            onClick={handleApply}
          />
          <SimpleButton
            className="btn theme-btn theme-btn-sm btn-inverse me-2"
            text={BUTTON_LABEL.RESET}
            onClick={handleReset}
          />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Filter;
