import Loader from "../../../../shared/components/Loader";
import TextBox from "../../../../shared/components/TextBox";
import { ACTION_LABEL, LABELS } from "../../../../shared/constants";
import { USER_TYPE } from "../../../../shared/constants/resource";

const StoreCoordinatorDetail = (props: any) => {
  const { storeCoordinatorDetailData, getStoreCoordinatorLoading } = props;

  return (
    <>
      {!getStoreCoordinatorLoading && storeCoordinatorDetailData ? (
        <div className="row">
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.FULLNAME_TEXT}
                placeholder={LABELS.FULLNAME_TEXT}
                name={ACTION_LABEL.NAME}
                value={storeCoordinatorDetailData.name}
                disable
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.USERCODE_TEXT}
                placeholder={LABELS.USERCODE_TEXT}
                name={ACTION_LABEL.USERNAME}
                value={storeCoordinatorDetailData.username}
                disable
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.EMAIL_TEXT}
                placeholder={LABELS.EMAIL_TEXT}
                name={ACTION_LABEL.EMAIL}
                value={storeCoordinatorDetailData.email}
                disable
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.PHONE_TEXT}
                placeholder={LABELS.PHONE_TEXT}
                name={ACTION_LABEL.PHONE}
                value={storeCoordinatorDetailData.phone}
                disable
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.DESIGNATION_TEXT}
                placeholder={LABELS.DESIGNATION_TEXT}
                name={ACTION_LABEL.DESIGNATION}
                value={storeCoordinatorDetailData.designation}
                disable
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.STATE_TEXT}
                placeholder={LABELS.STATE_TEXT}
                name={ACTION_LABEL.STATE}
                value={storeCoordinatorDetailData.state}
                disable
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.CITY_TEXT}
                placeholder={LABELS.CITY_TEXT}
                name={ACTION_LABEL.CITY}
                value={storeCoordinatorDetailData.city}
                disable
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.ADDRESS_TEXT}
                placeholder={LABELS.ADDRESS_TEXT}
                name={ACTION_LABEL.ADDRESS}
                value={storeCoordinatorDetailData.address}
                disable
              />
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.ROLE_TEXT}
                placeholder={LABELS.ROLE_TEXT}
                name={ACTION_LABEL.ROLE}
                value={storeCoordinatorDetailData.userType}
              />
            </div>
          </div> */}
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.USER_TYPE_TEXT}
                placeholder={LABELS.USER_TYPE_TEXT}
                name={ACTION_LABEL.TYPE}
                value={USER_TYPE[storeCoordinatorDetailData.type]}
                disable
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.STORE_TEXT}
                placeholder={LABELS.STORE_TEXT}
                name={ACTION_LABEL.STORE}
                value={storeCoordinatorDetailData.store}
                disable
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.STATUS_TEXT}
                placeholder={
                  storeCoordinatorDetailData.isActive
                    ? LABELS.ACTIVE_NAME_TEXT
                    : LABELS.INACTIVE_NAME_TEXT
                }
                name={ACTION_LABEL.ISACTIVE}
                value={
                  storeCoordinatorDetailData.isActive
                    ? LABELS.ACTIVE_NAME_TEXT
                    : LABELS.INACTIVE_NAME_TEXT
                }
                disable
              />
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default StoreCoordinatorDetail;
