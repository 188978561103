const TextArea = (props: any) => {
  const {
    placeholder,
    required,
    id,
    value,
    name,
    onChange,
    onBlur,
    label,
    errorMessage,
    disable,
    className,
    onClick,
    onKeyDown,
    onDoubleClick,
    color,
    maxLength,
  } = props;

  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}{" "}
      {required && <span className="text-error">*</span>}
      <textarea
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onKeyDown={onKeyDown}
        className={className ? className : "form-control"}
        placeholder={placeholder}
        value={value}
        name={name}
        disabled={disable || undefined}
        onChange={onChange}
        onBlur={onBlur}
        style={{ height: "90px", color: color ? color : undefined }}
        rows={10}
        maxLength={maxLength ? maxLength : "300"}
      />
      <span className="text-error">{errorMessage}</span>
    </>
  );
};

export default TextArea;
