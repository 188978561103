import { API_URL } from "../../shared/constants";
import { emptySplitApi } from "../emptySplit-api";

export const rolesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    rolesList: builder.query<any, any>({
      query: (data: any) => ({
        // return {
        url: `/${API_URL.ROLES}/list`,
        method: "post",
        body: data,
        // };
      }),
    }),
    rolesCreate: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.ROLES}/create`,
          method: "post",
          body: data,
        };
      },
    }),
    rolesUpdate: builder.mutation<any, any>({
      query: ({ id, data }) => {
        return {
          url: `/${API_URL.ROLES}/update/${id}`,
          method: "post",
          body: data,
        };
      },
    }),
    rolesDelete: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.ROLES}/delete`,
          method: "post",
          body: data,
        };
      },
    }),
    rolesGet: builder.query<any, any>({
      query: (id: any) => {
        return {
          url: `/${API_URL.ROLES}/get/${id}`,
          method: "post",
          // body: id,
        };
      },
    }),
    rolesSearch: builder.query<any, any>({
      query: () => {
        return {
          url: `/${API_URL.ROLES}/search`,
          method: "post",
          //   body: data,
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useRolesListQuery,
  useRolesCreateMutation,
  useRolesUpdateMutation,
  useRolesDeleteMutation,
  useRolesGetQuery,
  useRolesSearchQuery,
} = rolesApi;
