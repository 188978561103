import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./App/store";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/App/assets/css/main.css";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
// import { NavigationProvider } from "./context/NavigationContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        {/* <NavigationProvider> */}
        <App />
        <ToastContainer limit={1} />
        {/* </NavigationProvider> */}
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
