import React, { FC, useState } from "react";
import { icons, PAGINATION_DROP } from "../../constants";
import { usePagination } from "../../utils/hooks/usePagination";

interface PaginationProps {
  count: number;
  setSort: (sort: Sort) => void;
  sort: Sort;
}

interface Sort {
  skip: number;
  limit: number;
}

const Pagination: FC<PaginationProps> = (props) => {
  const { count, setSort, sort } = props;

  const DOTS = "...";

  const endData: number =
    sort.skip + sort.limit > count ? count : sort.skip + sort.limit;
  const startData: number = sort.skip + 1;
  const val: number = sort.skip + sort.limit;
  const page: number = Math.round(val / sort.limit);

  const lastIndex: number = Math.ceil(count / sort.limit);

  const [currentPage, setCurrentPage] = useState<number>(page);

  const pageSize: number = sort.limit;
  const siblingCount: number = 1;
  const totalCount: number = count;

  const paginationRange = usePagination({
    totalCount,
    pageSize,
    siblingCount,
    currentPage,
  });

  const handlePage = (e: number): void => {
    let s: number = (e - 1) * sort.limit;
    setSort({ ...sort, skip: s });
    setCurrentPage(e);
  };

  const goNext = (): void => {
    let s: number = page * sort.limit;
    setSort({ ...sort, skip: s });
    setCurrentPage(page + 1);
  };

  const goPrev = (): void => {
    let s: number = (page - 2) * sort.limit;
    setSort({ ...sort, skip: s });
    setCurrentPage(page - 1);
  };

  const handleLimit = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setSort({ ...sort, limit: parseInt(e.target.value), skip: 0 });
  };

  return (
    <>
      <div className="col-12 col-md-4">
        <div className="form-group mb-0">
          Show
          <select
            className="form-control w-auto d-inline-block"
            value={sort.limit}
            onChange={handleLimit}
          >
            {PAGINATION_DROP.map((val: any) => {
              return (
                <option value={val._id} key={val._id}>
                  {val.name}
                </option>
              );
            })}
          </select>
          &nbsp; Entries
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="form-group mb-0 text-center">
          Showing {startData} to {endData} of {count} entries
        </div>
      </div>

      <div className="col-12 col-md-4">
        <nav>
          <ul className="pagination mb-0 justify-content-end">
            <li
              className={`page-item ${page === 1 ? "custom-disabled" : ""}`}
              onClick={goPrev}
            >
              <a className="page-link">
                <i className={`icon ${icons.ICON_CHEVRON_LEFT} `}></i>
              </a>
            </li>

            {paginationRange?.map((pageNumber: any, index) => {
              if (pageNumber === DOTS) {
                return (
                  <li className="dots" key={index}>
                    <a className="page-link" href="javascript:void(0);">
                      ...
                    </a>
                  </li>
                );
              }

              return (
                <li
                  key={`page-${pageNumber}-${index}`}
                  className={`page-item ${
                    pageNumber === page ? " active" : ""
                  } `}
                  onClick={() => handlePage(pageNumber)}
                >
                  <a className="page-link" href="javascript:void(0);">
                    {pageNumber}
                  </a>
                </li>
              );
            })}

            <li
              className={`page-item ${
                page === lastIndex ? "disable class" : ""
              }`}
              onClick={goNext}
            >
              <a className="page-link" href="javascript:void(0);">
                <i className={`icon ${icons.ICON_CHEVRON_RIGHT}`}></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Pagination;
