export const mergeUniqueArrays = (arr1: any, arr2: any) => {
  const result: any = [];

  arr1
    ?.map((item1: any) => {
      if (!result.includes(item1?.itemCode)) {
        result.push(item1);
      }
      return null;
    })
    .filter((item1: any) => item1 !== null);

  arr2
    ?.map((item2: any) => {
      if (!result.includes(item2?.itemCode)) {
        result.push(item2);
      }
      return null;
    })
    .filter((item2: any) => item2 !== null);

  return result;
};
