import Loader from "../../../../shared/components/Loader";
import TextBox from "../../../../shared/components/TextBox";
import { ACTION_LABEL, LABELS } from "../../../../shared/constants";

const UserDetail = (props: any) => {
  const { userDetailData, getUserLoading } = props;

  return (
    <>
      {!getUserLoading && userDetailData !== undefined ? (
        <div className="row">
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.FULLNAME_TEXT}
                placeholder={LABELS.FULLNAME_TEXT}
                name={ACTION_LABEL.NAME}
                value={userDetailData.name}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.EMAIL_TEXT}
                placeholder={LABELS.EMAIL_TEXT}
                name={ACTION_LABEL.EMAIL}
                value={userDetailData.email}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.ROLE_TEXT}
                placeholder={LABELS.ROLE_TEXT}
                name={ACTION_LABEL.ROLE}
                value={userDetailData.role}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center mb-2">
              <TextBox
                label={LABELS.STATUS_TEXT}
                placeholder={
                  userDetailData.isActive
                    ? LABELS.ACTIVE_NAME_TEXT
                    : LABELS.INACTIVE_NAME_TEXT
                }
                name={ACTION_LABEL.ISACTIVE}
                value={
                  userDetailData.isActive
                    ? LABELS.ACTIVE_NAME_TEXT
                    : LABELS.INACTIVE_NAME_TEXT
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default UserDetail;
