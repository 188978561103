import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Logo } from "../../../../../App/assets/img";
import SimpleButton from "../../../../../shared/components/Button/Button";
import DatePickerComponent from "../../../../../shared/components/DatePicker/DatePicker";
import TextArea from "../../../../../shared/components/TextArea";
import TextBox from "../../../../../shared/components/TextBox";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  LABELS,
} from "../../../../../shared/constants";

const PrintOrder = (props: any) => {
  const { data, handleClosePrintOrder, currentRow } = props;
  const contentRef = useRef<HTMLDivElement>(null);
  const [printOrderData, setPrintOrderData] = useState({
    srNo: currentRow.documentNo,
    cus: currentRow.firstName + " " + currentRow.lastName,
    dNo: currentRow.designNo,
    date: currentRow.deliveryDate,
    remark: currentRow.remarks,
  });
  const [errors, setErrors]: any = useState({});

  const handleOnChange = (e: any) => {
    setPrintOrderData({ ...printOrderData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleDateChange = (value: any) => {
    setPrintOrderData({ ...printOrderData, date: value });
    setErrors({ ...errors, date: "" });
  };

  const handlePrint = useReactToPrint({ contentRef });

  const measurementStandardBlock = (item: any) => {
    return (
      <div className="value">
        <label htmlFor="">{item?.title}</label>
        <span className="label-box">{item?.value}</span>
      </div>
    );
  };

  const customMeasurementBlock = (title: any, value: any) => {
    if (value !== "") {
      return (
        <div className="item-card mb-2">
          <div className="item-header ">
            <span>{title}</span>
          </div>
          <div className="item-body">
            <div className="populate-values">
              <div className="value">
                <span className="label-box">{value}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div>
        <div ref={contentRef} className="print-page">
          <div className="pritable-area">
            <div className="row">
              <div className="col-md-2">
                <img src={Logo} alt="lock" width={180} />
              </div>
              <div className="col-md-10 text-center">
                <h3>{LABELS.PRINT_ORDER_TITLE_TEXT}</h3>
                <p>
                  {LABELS.PRINT_ORDER_HOUSE_ADDRESS_TEXT} <br />
                  {LABELS.PRINT_ORDER_ADDRESS_TEXT} <br />
                  {LABELS.PRINT_ORDER_PHONE_TEXT}
                </p>
              </div>
            </div>
            <div className="inner">
              <div className="row mb-3">
                <div className="col-12 col-md-4">
                  <div className="d-flex align-items-center">
                    <TextBox
                      name={ACTION_LABEL.SR_NO}
                      placeholder={LABELS.SR_NO_TEXT}
                      id={LABELS.SR_NO_TEXT}
                      label={LABELS.SR_NO_TEXT}
                      onChange={handleOnChange}
                      value={printOrderData.srNo}
                      errorMessage={errors.srNo ? errors.srNo : null}
                    />
                    {/* <label htmlFor="">Sr. No.</label>
                <input type="text" className="form-control" placeholder="38062" /> */}
                  </div>
                </div>
                <div className="col-12 col-md-4 ms-auto">
                  <div className="d-flex align-items-center">
                    <DatePickerComponent
                      label={LABELS.DATE_TEXT}
                      value={printOrderData.date}
                      onChange={handleDateChange}
                      placeholder={"DD/MM/YYYY"}
                      format="dd/MM/yyyy"
                      placement="bottom-start"
                      errorMessage={errors.date ? errors.date : null}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <TextBox
                      name={ACTION_LABEL.CUS}
                      placeholder={LABELS.CUS_TEXT}
                      id={LABELS.CUS_TEXT}
                      label={LABELS.CUS_TEXT}
                      onChange={handleOnChange}
                      value={printOrderData.cus}
                      errorMessage={errors.cus ? errors.cus : null}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <TextBox
                      name={ACTION_LABEL.D_NO}
                      placeholder={LABELS.D_NO_TEXT}
                      id={LABELS.D_NO_TEXT}
                      label={LABELS.D_NO_TEXT}
                      onChange={handleOnChange}
                      value={printOrderData.dNo}
                      errorMessage={errors.dNo ? errors.dNo : null}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {Array.isArray(data?.top) &&
                    data.top.map((data: any) => {
                      return (
                        <div className="item-card mb-2 page-break">
                          <div className="item-header">
                            <span>{data.type}</span>
                          </div>
                          <div className="item-body">
                            <div className="populate-values">
                              <div className="populate-values">
                                {Array.isArray(data?.data)
                                  ? data.data.map((el: any) => {
                                      return measurementStandardBlock(el);
                                    })
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
            
                  {Array.isArray(data?.bottom) &&
                    data.bottom.map((data: any) => {
                      return (
                        <div className="item-card mb-2">
                          <div className="item-header">
                            <span>{data.type}</span>
                          </div>
                          <div className="item-body">
                            <div className="populate-values">
                              <div className="populate-values">
                                {Array.isArray(data?.data)
                                  ? data.data.map((el: any) => {
                                      return measurementStandardBlock(el);
                                    })
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
            
                  {data?.custom &&
                    typeof data?.custom === "object" &&
                    Object.keys(data?.custom).map((ele: any, index: any) => {
                      return customMeasurementBlock(ele, data?.custom[ele]);
            
                      // return data?.custom[ele] !== "" ? (
                      //   <div className="item-card mb-2">
                      //     <div className="item-header ">
                      //       <span>{ele}</span>
                      //     </div>
                      //     <div className="item-body">
                      //       <div className="populate-values">
                      //         <div className="value">
                      //           <span className="label-box">
                      //             {data?.custom[ele]}
                      //           </span>
                      //         </div>
                      //       </div>
                      //     </div>
                      //   </div>
                      // ) : (
                      //   ""
                      // );
                    })}
                </div>
              </div>
              <div className="form-group">
                <TextArea
                  name={ACTION_LABEL.REMARK}
                  placeholder={LABELS.REMARKS_TEXT}
                  id={LABELS.REMARKS_TEXT}
                  label={LABELS.REMARKS_TEXT}
                  onChange={handleOnChange}
                  value={printOrderData.remark}
                  errorMessage={errors.remark ? errors.remark : null}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <SimpleButton
            className="btn theme-btn theme-btn-sm me-2"
            onClick={handlePrint}
            text={BUTTON_LABEL.PRINT}
          />
          {/* <button className="btn btn theme-btn me-3">
          <span>Print</span>
        </button> */}
          <SimpleButton
            className="btn theme-btn theme-btn-sm me-2"
            onClick={handleClosePrintOrder}
            text={BUTTON_LABEL.CLOSE}
          />
          {/* <button className="btn btn theme-btn btn-inverse">
          <span>Close</span>
        </button> */}
        </div>
      </div>
    </>
  );
};

export default PrintOrder;
