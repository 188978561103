import { useState } from "react";
import { icons } from "../../../../shared/constants";

const ReportDetail = (props: any) => {
  const { reportDetailData, getReportLoading } = props;

  const [showSubAssemblyItem, setShowSubAssemblyItem] = useState(true);
  const [showJobOrderDate, setShowJobOrderDate] = useState(false);

  const toggleSubAssemblyItem = () => {
    setShowSubAssemblyItem(!showSubAssemblyItem);
  };

  const toggleJobOrderDate = () => {
    setShowJobOrderDate(!showJobOrderDate);
  };

  return (
    <>
      {/* {!getReportLoading && reportDetailData !== undefined ? ( */}
      <div className="tracking-status">
        <div className="item">
          <div className="item-title">Shervani</div>
        </div>
        <div className="track-stepper">
          <ul className="list-unstyled mb-0">
            <li>
              <div className="d-flex w-max-content justify-content-center flex-wrap">
                <div className="track-status done">
                  <i className={icons.ICON_CHECK}></i>
                </div>
                <div className="w-100 text-center">Cutting</div>
              </div>
            </li>
            <li>
              <div className="d-flex w-max-content justify-content-center flex-wrap">
                <div className="track-status in-progress">
                  <i className={icons.ICON_REFRESH}></i>
                </div>
                <div className="w-100 text-center">Embrooidry</div>
              </div>
            </li>
            <li>
              <div className="d-flex w-max-content justify-content-center flex-wrap">
                <div className="track-status"></div>
                <div className="w-100 text-center">Stitching</div>
              </div>
            </li>
            <li>
              <div className="d-flex w-max-content justify-content-center flex-wrap">
                <div className="track-status"></div>
                <div className="w-100 text-center">Finishing</div>
              </div>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div
              className="slug-custom-dropdown mt-4 d-inline-block w-100"
              onClick={toggleSubAssemblyItem}
            >
              <div className="toggle d-flex align-items-center w-100 justify-content-between">
                <span>Sub Assembly item</span>
                <span className="ms-3">
                  <i
                    className={
                      showSubAssemblyItem
                        ? icons.ICON_CARET_UP
                        : icons.ICON_CARET_DOWN
                    }
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        {showSubAssemblyItem ? (
          <>
            <div className="row mt-3 align-items-center assembally-attr">
              <div className="col-md-3">
                <div
                  className="slug-custom-dropdown d-inline-block w-100"
                  onClick={toggleJobOrderDate}
                >
                  <div className="toggle d-flex align-items-center w-100 justify-content-between">
                    <span>Pent</span>
                    <span className="ms-3">
                      <i className={icons.ICON_CARET_DOWN}></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="track-stepper">
                  <ul className="list-unstyled my-0">
                    <li>
                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                        <div className="track-status done">
                          <i className={icons.ICON_CHECK}></i>
                        </div>
                        <div className="w-100 text-center">Cutting</div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                        <div className="track-status in-progress">
                          <i className={icons.ICON_REFRESH}></i>
                        </div>
                        <div className="w-100 text-center">Embrooidry</div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                        <div className="track-status">
                          <i className="icon"></i>
                        </div>
                        <div className="w-100 text-center">Stitching</div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                        <div className="track-status">
                          <i className="icon"></i>
                        </div>
                        <div className="w-100 text-center">Finishing</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {showJobOrderDate ? (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="row">
                      <div className="col-auto">
                        <div className="form-group">
                          <label htmlFor="">Job Oder Date</label>
                          <p className="text-muted mb-0">03-06-2024</p>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="form-group">
                          <label htmlFor="">Job Oder Date</label>
                          <p className="text-muted mb-0">03-06-2024</p>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="form-group">
                          <label htmlFor="">Job Oder Date</label>
                          <p className="text-muted mb-0">03-06-2024</p>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="form-group">
                          <label htmlFor="">Job Oder Date</label>
                          <p className="text-muted mb-0">03-06-2024</p>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="form-group">
                          <label htmlFor="">Job Oder Date</label>
                          <p className="text-muted mb-0">03-06-2024</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row mt-3 align-items-center assembally-attr">
              <div className="col-md-3">
                <div className="slug-custom-dropdown d-inline-block w-100">
                  <div className="toggle d-flex align-items-center w-100 justify-content-between">
                    <span>Duptta</span>
                    <span className="ms-3">
                      <i className={icons.ICON_CARET_DOWN}></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="track-stepper">
                  <ul className="list-unstyled my-0">
                    <li>
                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                        <div className="track-status done">
                          <i className={icons.ICON_CHECK}></i>
                        </div>
                        <div className="w-100 text-center">Cutting</div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                        <div className="track-status in-progress">
                          <i className={icons.ICON_REFRESH}></i>
                        </div>
                        <div className="w-100 text-center">Embrooidry</div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                        <div className="track-status">
                          <i className="icon"></i>
                        </div>
                        <div className="w-100 text-center">Stitching</div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                        <div className="track-status">
                          <i className="icon"></i>
                        </div>
                        <div className="w-100 text-center">Finishing</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      {/* ) : (
        <Loader />
      )} */}
    </>
  );
};

export default ReportDetail;
