const Select = (props: any) => {
  const {
    name,
    value,
    onChange,
    data,
    label,
    errorMessage,
    disabled,
    className,
    required,
    id,
    selectText,
  } = props;

  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      {required && <span className="text-error">*</span>}
      <select
        name={name}
        id={id}
        value={value !== undefined ? value : ""}
        onChange={onChange}
        className={className ? className : "form-control mr-2 select-box"}
        disabled={disabled}
      >
        <option value="">{selectText || "Select option"}</option>
        {data &&
          data?.map((d: any, index: any) => {
            return (
              <>
                <option value={d._id || d.itemCode} key={index}>
                  {d.name || d.itemCode}
                </option>
              </>
            );
          })}
      </select>
      <span className="text-error">{errorMessage}</span>
    </>
  );
};

export default Select;
