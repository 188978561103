import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useRolesCreateMutation,
  useRolesGetQuery,
  useRolesListQuery,
  useRolesUpdateMutation,
} from "../../../../Features/role/role-slice";
import SimpleButton from "../../../../shared/components/Button/Button";
import ButtonLoader from "../../../../shared/components/ButtonLoader";
import CheckBox from "../../../../shared/components/CheckBox/CheckBox";
import Loader from "../../../../shared/components/Loader";
import TextBox from "../../../../shared/components/TextBox";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  LABELS,
  toastError,
  toastSuccess,
  URLS,
} from "../../../../shared/constants";
import {
  PERMISSIONS,
  SEARCH_DATA,
} from "../../../../shared/constants/resource";
import { roleValidation } from "../../../../shared/validation/role-validation";
import Navbar from "../../../Navbar/NavbarComponent/Navbar";

const AddRole = (props: any) => {
  const {} = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [roleId, setRoleId]: any = useState();
  const [editMode, setEditMode] = useState(false);
  const [allPermission, setAllPermission]: any = useState(PERMISSIONS);
  const [page, setPage] = useState([]);
  const [rolesData, setRolesData] = useState({
    name: "",
    code: "",
    isActive: true,
    permissions: [],
  });
  const [permissionCopy, setPermissionCopy]: any = useState(
    rolesData.permissions
  );
  const [sort, setSort] = useState(SEARCH_DATA);
  const [selectAll, setSelectAll] = useState(false);
  const [errors, setErrors]: any = useState({});

  const {
    data,
    isSuccess,
    error,
    refetch: rolesRefetch,
  } = useRolesListQuery(sort);

  const [
    userCreate,
    { isLoading: userCreateLoading, isSuccess: userCreateSuccess },
  ] = useRolesCreateMutation();

  const [
    userUpdate,
    { isLoading: userUpdateLoading, isSuccess: userUpdateSuccess },
  ] = useRolesUpdateMutation();

  const {
    data: getRoleData,
    isLoading: getRoleLoading,
    isSuccess: getRoleSuccess,
    refetch: getRoleRefetch,
  } = useRolesGetQuery(roleId ? roleId : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const receivedId = location?.state?._id;
    if (receivedId !== undefined) {
      setRoleId(receivedId);
    }
  }, [location.state]);

  useEffect(() => {
    if (
      roleId !== undefined &&
      getRoleData !== undefined &&
      getRoleData.status
    ) {
      setEditMode(true);
      setRolesData(getRoleData.item);
      setPermissionCopy(getRoleData.item.permissions);
    }
  }, [roleId, getRoleData, getRoleRefetch]);

  useEffect(() => {
    let pageArray: any = [];
    for (let i of allPermission) {
      let per = i.split(".")[0];
      if (!pageArray.includes(per)) {
        pageArray.push(per);
      }
    }
    setPage(pageArray);
  }, [allPermission]);

  useEffect(() => {
    const areAllPermissionsSelected = allPermission.every((permission: any) =>
      permissionCopy.includes(permission)
    );

    if (areAllPermissionsSelected !== selectAll) {
      setSelectAll(areAllPermissionsSelected);
    }
  }, [allPermission, permissionCopy]);

  useEffect(() => {
    if (selectAll) {
      handleCheck("full", "");
    } else {
      handleCheck("empty", "");
    }
  }, [selectAll]);

  const roleAddFlow = async (data: any) => {
    toastSuccess(data.message);
    navigate(URLS.ROLES);
    rolesRefetch();
  };

  const roleUpdateFlow = async (data: any) => {
    toastSuccess(data.message);
    navigate(URLS.ROLES);
    rolesRefetch();
  };

  const handleSave = async () => {
    const { formErrors, formValid } = roleValidation(rolesData);
    if (formValid) {
      if (editMode) {
        const userUpdateResponse = await userUpdate({
          id: roleId,
          data: rolesData,
        })
          .unwrap()
          .catch((err: any) => {
            toastError(err.data?.errors[0]?.msg);
          });
        if (userUpdateResponse?.status === true) {
          roleUpdateFlow(userUpdateResponse);
        }
      } else {
        const userAddResponse = await userCreate(rolesData)
          .unwrap()
          .catch((err: any) => {
            toastError(err.data?.errors[0]?.msg);
          });
        if (userAddResponse && userAddResponse?.status === true) {
          roleAddFlow(userAddResponse);
        }
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleCancel = () => {
    navigate(URLS.ROLES);
  };

  const handleCheck = (type: any, value: any) => {
    let temp: any = [...permissionCopy];
    if (type === "full") {
      if (value === "") {
        const results = allPermission;
        let tempp = [...temp, ...results];
        temp = [...new Set(tempp)];
      } else {
        const results = allPermission.filter(
          (item: any) => item === value || item.startsWith(value + ".")
        );
        let tempp = [...temp, ...results];
        temp = [...new Set(tempp)];
      }
    } else if (type === "empty") {
      if (value === "") {
        temp = [];
      } else {
        temp = temp.filter(
          (permission: any) =>
            !(permission === value || permission.startsWith(value + "."))
        );
      }
    } else {
      if (temp.includes(value)) {
        temp.splice(temp.indexOf(value), 1);
      } else {
        temp.push(value);
      }
    }
    setPermissionCopy(temp);
    setRolesData({ ...rolesData, permissions: temp });
  };

  const handleSelectAllToggle = () => {
    setSelectAll(!selectAll);
  };

  const handleOnChange = (e: any) => {
    setRolesData({ ...rolesData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setRolesData({
      ...rolesData,
      [e.target.name]: trimmedValue,
    });
  };

  const checkIsAllSelected = (name: any) => {
    if (
      permissionCopy.includes(`${name}.edit`) &&
      // permissionCopy.includes(`${name}.create`) &&
      // permissionCopy.includes(`${name}.update`) &&
      permissionCopy.includes(`${name}.view`)
    ) {
      return true;
    } else if (permissionCopy.includes(`${name}`)) {
      return true;
    } else return false;
  };

  const fieldArr = [
    { key: "edit", label: "Manage" },
    // { key: "create", label: "Create" },
    // { key: "update", label: "Update" },
    // { key: "delete", label: "Delete" },
    { key: "view", label: "View" },
  ];

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.ROLE_MANAGEMENT} />
        <main className="main-content">
          <div className="pt-3">
            {!getRoleLoading ? (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group value-field">
                      <TextBox
                        name={ACTION_LABEL.NAME}
                        placeholder={LABELS.NAME_TEXT}
                        id={LABELS.NAME_TEXT}
                        label={LABELS.NAME_TEXT}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        value={rolesData.name}
                        errorMessage={errors.name ? errors.name : null}
                        maxLength={30}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group value-field">
                      <TextBox
                        name={ACTION_LABEL.CODE}
                        placeholder={LABELS.CODE_TEXT}
                        id={LABELS.CODE_TEXT}
                        label={LABELS.CODE_TEXT}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        value={rolesData.code}
                        errorMessage={errors.code ? errors.code : null}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="card custom-card card-full mt-3">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive role-table">
                          <table className="table table-header-shadow mb-0">
                            <thead>
                              <tr>
                                <th>
                                  <CheckBox
                                    onChange={handleSelectAllToggle}
                                    value={selectAll}
                                    label={""}
                                    name={"selectAll"}
                                    id={"selectAll"}
                                  />
                                </th>
                                <th>Module List</th>
                                {fieldArr.map((item, index) => (
                                  <th key={index}>
                                    <div>{item.label}</div>
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {page.map((per, index) => (
                                <tr key={index}>
                                  <td>
                                    <CheckBox
                                      onChange={() =>
                                        handleCheck(
                                          checkIsAllSelected(per)
                                            ? "empty"
                                            : "full",
                                          per
                                        )
                                      }
                                      value={checkIsAllSelected(per)}
                                      label={""}
                                      name={per}
                                      id={per}
                                    />
                                  </td>
                                  <td className="text-capitalize">{per}</td>
                                  {fieldArr.map((item, index) => (
                                    <td key={index}>
                                      <div className="d-flex justify-content-center">
                                        {allPermission.includes(
                                          `${per}.${item.key}`
                                        ) && (
                                          <CheckBox
                                            value={permissionCopy?.includes(
                                              `${per}.${item.key}`
                                            )}
                                            onChange={() =>
                                              handleCheck(
                                                "single",
                                                `${per}.${item.key}`
                                              )
                                            }
                                            label={""}
                                            name={item.key}
                                            id={`${per}-${item.key}`}
                                          />
                                        )}
                                      </div>
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 justify-content-center align-items-center">
                      <div className="col-12 text-center">
                        <SimpleButton
                          className="btn theme-btn theme-btn-sm me-2"
                          onClick={handleSave}
                          text={
                            (
                              editMode ? userUpdateLoading : userCreateLoading
                            ) ? (
                              <ButtonLoader />
                            ) : (
                              BUTTON_LABEL.SAVE
                            )
                          }
                        />

                        <SimpleButton
                          className="btn theme-btn theme-btn-sm btn-inverse"
                          onClick={handleCancel}
                          text={BUTTON_LABEL.CANCEL}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default AddRole;
