import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./App/store";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/App/assets/css/main.css";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
// import { NavigationProvider } from "./context/NavigationContext";

const originalWebSocket = WebSocket;

class MockWebSocket {
  onopen: any;
  onerror: any;
  onclose: any;
  send: any;
  close: any;

  static CLOSED: number = 3;
  static CLOSING: number = 2;
  static CONNECTING: number = 0;
  static OPEN: number = 1;

  constructor() {
    this.onopen = () => {};
    this.onerror = () => {};
    this.onclose = () => {};
    this.send = () => {};
    this.close = () => {};
  }
}

global.WebSocket = function (url: string | URL, protocols?: string | string[]) {
  if (url === "wss://kora.rydotinfotech.com:9000/ws") {
    return new MockWebSocket();
  }

  return new originalWebSocket(url, protocols);
} as unknown as {
  new (url: string | URL, protocols?: string | string[] | undefined): WebSocket;
  prototype: WebSocket;
  readonly CLOSED: number;
  readonly CLOSING: number;
  readonly CONNECTING: number;
  readonly OPEN: number;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        {/* <NavigationProvider> */}
        <App />
        <ToastContainer limit={1} />
        {/* </NavigationProvider> */}
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
